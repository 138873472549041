.wrapper {
    display: flex;
    height: 100vh;
    width: 100vw;

    justify-content: center;
    align-items: center;

    .card {
        position: relative;

        width: 450px;
        background-color: white;
        padding: 30px;
        border-radius: 10px;
        box-shadow: 0px 0px 10px rgba($color: #000000, $alpha: 0.5);

        .loader {
            position: absolute;
            
            display: flex;
            justify-content: center;
            align-items: center;

            top: 0;
            left: 0;

            height: 100%;
            width: 100%;

            background-color: rgba($color: #000000, $alpha: 0.5);

            border-radius: 10px;

            .content {
                display: flex;
                flex-direction: column;

                justify-content: center;
                align-items: center;

                color: white;
            }
        }
    }
}