import React, { useEffect, useState } from 'react'
import Input from '../../components/form/input'
import { IconLock } from '../../components/icons/lock';
import { IconMessage } from '../../components/icons/message';
import * as styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { generateUserAvatar, getRoles, login } from '../../../application/features/authentication';
import { useNavigate } from 'react-router-dom';
import logo from '$assets/images/mlipa-logo-icon.png';

export const LoginPage = () => {
    // props

    // router
    const navigate = useNavigate();

    // state
    const [loginPayload, setLoginPayload] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    // redux
    const dispatch = useDispatch();
    const token = useSelector(state => state.authentication.token);
    const loginState = useSelector(state => state.authentication.login);

    // effects
    useEffect(() => {
        if (!token) return;
        // dispatch(generateUserAvatar());
        dispatch(getRoles());
        setTimeout(() => {
            navigate('/dashboard');
        }, 3000);
    }, [token]);

    useEffect(() => {
        if (loginState.error) {
            setErrorMessage(loginState.error);
            setTimeout(() => {
                setErrorMessage("");
            }, 10000);
        }
    }, [loginState]);
    
    // handlers
    const handleChange = (name, value) => {
        setLoginPayload(prevState => {
            return {
                ...prevState, 
                [name]: value
            }
        });
    }

    const handleLogin = () => {
        dispatch(login(loginPayload));
    }

    const handleRegister = () => {
        navigate('/register');
    }

    const handleKeyDown = (event) => {
        console.log("Password field key down", event.key);
        if (event.key === 'Enter') {
          handleLogin();
        }
    }
    

    if (token) {
        return (
            <div className={styles.loader}>
                <div className=''>
                    <div className={styles.loaderWrapper}>
                        <div className={styles.customLoader}></div>
                        <img src={logo} alt="" height="45px" width="45px" />
                    </div>
                    <p className='text-center mt-2'>Please Wait...</p>
                    <p className='text-center'>Getting Things Ready</p>
                </div>
            </div>
        );
    }

    return (
        <div className={styles.login}>
            <div className={`card ${styles.card}`}>
                <div className="card-body">
                    
                    <h3 className="card-title mb-5">Login</h3>
                    {errorMessage && <div class="alert alert-danger">{errorMessage}</div>}
                    <Input type="email" label="Email" name="email" placeholder="Email Address" leadingIcon={IconMessage} onChange={handleChange} />
                    <div className="my-2"></div>
                    <Input type="password" label="Password" name="password" placeholder="Password" leadingIcon={IconLock} onChange={handleChange} onKeyDown={handleKeyDown} />
                    <div className="mb-4"></div>
                    <div className="d-flex justify-content-end">
                        <a href="" className="btn btn-link">Forgot your password ?</a>
                        <div className="flex-grow-1"></div>
                        {/* <button className="btn btn-outline-primary btn-lg" onClick={handleRegister}>Register</button> */}
                        <div className="mx-1"></div>
                        <button className="btn btn-primary btn-lg" onClick={handleLogin}>Login {loginState.isLoading && <span className='spinner-border spinner-border-sm mx-2'></span>}</button>
                    </div>
                </div>
            </div>
        </div>
    );
}
