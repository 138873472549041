import React from 'react'

export const IconReport = (props) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M19.6441 20.2812H10.6191"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.6441 15.0469H10.6191"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0629 9.82422H10.6191"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M19.885 3.4375C19.885 3.4375 10.2887 3.4425 10.2737 3.4425C6.82375 3.46375 4.6875 5.73375 4.6875 9.19625V20.6913C4.6875 24.1713 6.84 26.45 10.32 26.45C10.32 26.45 19.915 26.4462 19.9312 26.4462C23.3812 26.425 25.5188 24.1538 25.5188 20.6913V9.19625C25.5188 5.71625 23.365 3.4375 19.885 3.4375Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
