class MlipaApi {
    static domain = 'https://mobile.mlipa.co.tz';
    static base_url = 'https://mobile.mlipa.co.tz/api';
    // static base_url = 'http://sandbox-api.mlipa.co.tz:446/api';
    // static base_url = 'http://localhost:8002/api'
    // static base_url = 'http://localhost:8022/api'
    // static base_url = 'http://bizmanager.local/api'

    // Authentications
    static login = `${this.base_url}/login`;
    static passwordConfirm = `${this.base_url}/verify-password`;

    // Authorization
    static users = `${this.base_url}/merchant/users`;
    static user = (id) => `${this.base_url}/merchant/users/${id}`;
    static roles = `${this.base_url}/merchant/roles`;
    static assignRole = (user_id) => `${this.base_url}/merchant/users/${user_id}/assign-role`;
    static revokeRole = (user_id) => `${this.base_url}/merchant/users/${user_id}/revoke-role`;

    // Dashbaord
    static dashboard = `${this.base_url}/dashboard/mobile`;
    static dashboardBalance = `${this.base_url}/balance`;
    static dashboardStatisticsCollection = `${this.base_url}/graph/collection/count`;
    static dashboardStatisticsCollectionAmount = `${this.base_url}/graph/collection/amount`;
    static dashboardStatisticsDisbursement = `${this.base_url}/graph/disbursement/count`;
    static dashboardStatisticsDisbursementAmount = `${this.base_url}/graph/disbursement/amount`;

    // Customers
    static customers = `${this.base_url}/customer`;
    static customer = (id) => `${this.base_url}/customer/view/${id}`;
    static customerCreate = `${this.base_url}/customer/add`;
    static customerUpdate = (id) => `${this.base_url}/customer/update/${id}`;
    static customerDelete = (id) => `${this.base_url}/customer/delete/${id}`;
    static exportCustomers = `${this.base_url}/export/customers`;

    // Bank
    static bankGetBanks = `${this.base_url}/bankaccount`;
    static bankCreateBank = `${this.base_url}/bankaccount/add`;
    static bankDeleteBank = (id) => `${this.base_url}/bankaccount/delete/${id}`;

    // disbursement
    static disbursementStatusUpdate = (id) => `${this.base_url}/disbursement/batch/update/${id}`;
    static disbursementFinalize = `${this.base_url}/disbursement/finalise`;
    static disbursementReject = `${this.base_url}/disbursement/reject`;
    static disbursementCreateBatch = `${this.base_url}/disbursement/batch/add`;
    static disbursementFavourites = `${this.base_url}/disbursement-favorites`;
    static disbursementGetBatches = `${this.base_url}/disbursement/batch`;
    static disbursementViewBatch = (batch_id) => `${this.base_url}/disbursement/batch/view/${batch_id}`;
    static disbursementFavourite = (id) => `${this.base_url}/disbursement-favorites/${id}`;
    static disbursementDeleteTransaction = (id) => `${this.base_url}/disbursement/delete/${id}`;
    static exportBatches = `${this.base_url}/export/batches`;

    // transaction
    static transactions = `${this.base_url}/transaction`;
    static exportTransactions = `${this.base_url}/export/transactions`;

    // documents
    static documentGetTypes = `${this.base_url}/document/types`;
    static documentGetDocuments = `${this.base_url}/document`;
    static documentAddDocument = `${this.base_url}/document/add`;
    static documentDeleteDocument = `${this.base_url}/document/delete`;

    //settlements
    static settlements = `${this.base_url}/settlement`
    static settlementTypes = `${this.base_url}/settlement/types`;
    static settlementCreate = `${this.base_url}/settlement/create`;
    static settlementCalculateCharge = `${this.base_url}/settlement/calculate-charges`;
    static exportSettlements = `${this.base_url}/export/settlements`;

    // bank
    static bankGetBanks = `${this.base_url}/bankaccount`;
    static bankAddBank = `${this.base_url}/bankaccount/add`;

    // notifications
    static notifications = `${this.base_url}/notifications`;
    static notification = (id) => `${this.base_url}/notifications/${id}`;
}

export default MlipaApi;