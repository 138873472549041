
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customFetch } from '../../core/request';
import MlipaApi from '../../api/mlipa/endpoints';


// ACTION TYPES
export const DOCUMENT_GET_TYPES = 'document/GET_TYPES';
export const DOCUMENT_GET_DOCUMENTS = 'document/GET_DOCUMENTS';
export const DOCUMENT_ADD_DOCUMENT = 'document/ADD_DOCUMENT';

// ACTIONS
export const documentGetTypes = createAsyncThunk(
    DOCUMENT_GET_TYPES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', MlipaApi.documentGetTypes, null, token, store);
    }
);

export const documentGetDocuments = createAsyncThunk(
    DOCUMENT_GET_DOCUMENTS,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', MlipaApi.documentGetDocuments, null, token, store);
    }
);

export const documentAddDocument = createAsyncThunk(
    DOCUMENT_ADD_DOCUMENT,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', MlipaApi.documentAddDocument, data.payload, token, store);
    }
)


// INITIAL STATE
const initialState = {
    documents: [],
    types: [],
    getTypes: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    getDocuments: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    addDocument: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    deleteDocument: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
}

// SLICE
const document = createSlice({
    name: 'document',
    initialState,
    extraReducers: {
        [documentGetTypes.pending]: (state, action) => {
            state.getTypes.isLoading = true;
            state.getTypes.isSuccessfull = false;
            state.getTypes.error = null;
            state.getTypes.data = {};
        },
        [documentGetTypes.fulfilled]: (state, action) => {
            state.getTypes.isLoading = false;
            state.getTypes.isSuccessfull = true;
            state.getTypes.error = null;
            state.getTypes.data = action.payload;
            state.types = action.payload.data;
        },
        [documentGetTypes.rejected]: (state, action) => {
            state.getTypes.isLoading = false;
            state.getTypes.isSuccessfull = false;
            state.getTypes.error = action.payload;
            // state.getTypes.data = action.payload;
        },
        [documentGetDocuments.pending]: (state, action) => {
            state.getDocuments.isLoading = true;
            state.getDocuments.isSuccessfull = false;
            state.getDocuments.error = null;
            state.getDocuments.data = {};
        },
        [documentGetDocuments.fulfilled]: (state, action) => {
            state.getDocuments.isLoading = false;
            state.getDocuments.isSuccessfull = true;
            state.getDocuments.error = null;
            state.getDocuments.data = action.payload;
            state.documents = action.payload.data;
        },
        [documentGetDocuments.rejected]: (state, action) => {
            state.getDocuments.isLoading = false;
            state.getDocuments.isSuccessfull = false;
            state.getDocuments.error = action.payload;
            // state.getDocuments.data = action.payload;
        },
        [documentAddDocument.pending]: (state, action) => {
            state.addDocument.isLoading = true;
            state.addDocument.isSuccessfull = false;
            state.addDocument.error = null;
            state.addDocument.data = {};
        },
        [documentAddDocument.fulfilled]: (state, action) => {
            state.addDocument.isLoading = false;
            state.addDocument.isSuccessfull = true;
            state.addDocument.error = null;
            state.addDocument.data = action.payload;
        },
        [documentAddDocument.rejected]: (state, action) => {
            state.addDocument.isLoading = false;
            state.addDocument.isSuccessfull = false;
            state.addDocument.error = action.payload;
            // state.addDocument.data = action.payload;
        },
    },
});

export default document.reducer;