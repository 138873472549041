import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import Input from '../../form/input';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../application/features/modal';


export const SendMoneyBatchBulkUploadModal = (props) => {
    // router
    const navigate = useNavigate();

    // state
    const [batchName, setBatchName] = useState();
    const [excel, setExcel] = useState();
    const [errors, setErrors] = useState({});

    // redux
    const dispatch = useDispatch();

    // effects
    useEffect(() => {
        if (!batchName) return;
        if (batchName && errors.batchName) {
            setErrors({...errors, batchName: ''});
        }
    }, [batchName]);

    useEffect(() => {
        if (!excel) return;
        if (excel && errors.excel) {
            setErrors({...errors, excel: ''});
        }
    }, [excel]);

    const handleFileChanged = (name, files) => {
        if (files) {
            setExcel(files[0]);
        }
    }

    const upload = () => {
        if (!validate()) return;
        // if (!excel) return;
        dispatch(hideModal());
        navigate('/send/batch', {state: { excel: excel, batchName: batchName }});
    }

    const validate = () => {
        let pass = true;
        let _errors = {};
        if (!excel) {
            _errors.excel = 'Required';
            pass = false;
        }
        if (!batchName) {
            _errors.batchName = 'Required';
            pass = false;
        }
        setErrors(_errors);
        return pass;
    }

    return (
        <div>
            <h3>Upload Excel</h3>
            <Input name="batch_name" label="Batch Name" onChange={(name, value) => setBatchName(value)} value={batchName} error={errors.batchName}  />
            <div className="py-1"></div>
            <Input type='file' name='excel' label="Choose File" onFileChange={handleFileChanged} error={errors.excel} />
            <a href={require('$assets/files/mlipa.xlsx')} target="blank">Download Template</a> <br />
            <button onClick={upload} className="btn btn-primary mt-3">Upload</button>
        </div>
    );
}
