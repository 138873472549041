import React from 'react'

export const IconMessage = (props) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" stroke='currentColor' xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15.8297 8.30664L11.9197 11.4547C11.1798 12.0349 10.1425 12.0349 9.40254 11.4547L5.45898 8.30664" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M6.31453 3.20898H14.9568C16.2029 3.22296 17.3889 3.74975 18.2387 4.66667C19.0884 5.58359 19.5276 6.81059 19.4541 8.06193V14.0458C19.5276 15.2971 19.0884 16.5242 18.2387 17.4411C17.3889 18.358 16.2029 18.8848 14.9568 18.8988H6.31453C3.63795 18.8988 1.83398 16.7213 1.83398 14.0458V8.06193C1.83398 5.38648 3.63795 3.20898 6.31453 3.20898Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
