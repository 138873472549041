import React from 'react'
import InputMoney from '../../../form/money';
import * as styles from './index.module.scss';
import visa from '$assets/images/visa.png';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../../application/features/modal';
import { MODAL_CONFIRM_PASSWORD } from '../../../layout/modal/index';

export const ReceivePaymentCardComponent = () => {
    // redux
    const dispatch = useDispatch();

    // handlers
    const handleSendMoney = () => {
        dispatch(showModal(MODAL_CONFIRM_PASSWORD));
    }
    
    return (
        <div className={styles.wrapper}>
            <InputMoney />
            <div className="d-grid mt-3">
                <button onClick={handleSendMoney} className="btn btn-primary btn-lg">Send Money</button>
            </div>
            <p className="text-center">
                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since.
            </p>
            <div className="d-flex justify-content-center">
                <img src={visa} />
            </div>
        </div>
    );
}
