import React from 'react'

export const IconSettings = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M27.7419 10.1664L26.912 8.72632C26.2099 7.50776 24.654 7.08739 23.4337 7.78658V7.78658C22.8529 8.12875 22.1598 8.22583 21.5073 8.05641C20.8548 7.88699 20.2965 7.46498 19.9555 6.88346C19.7361 6.51383 19.6182 6.09282 19.6137 5.66301V5.66301C19.6335 4.97392 19.3736 4.30616 18.893 3.81185C18.4125 3.31754 17.7524 3.03878 17.063 3.03906H15.391C14.7156 3.03905 14.0681 3.30818 13.5917 3.78689C13.1153 4.2656 12.8492 4.91442 12.8525 5.58979V5.58979C12.8325 6.98419 11.6963 8.10404 10.3018 8.1039C9.87196 8.09943 9.45095 7.98155 9.08132 7.76217V7.76217C7.86109 7.06298 6.30516 7.48335 5.60306 8.70192L4.71214 10.1664C4.01089 11.3835 4.42554 12.9384 5.63967 13.6447V13.6447C6.42888 14.1003 6.91505 14.9424 6.91505 15.8537C6.91505 16.765 6.42888 17.6071 5.63967 18.0627V18.0627C4.42708 18.7642 4.01198 20.3154 4.71214 21.5288V21.5288L5.55424 22.9811C5.8832 23.5747 6.43515 24.0127 7.08793 24.1982C7.74072 24.3837 8.44053 24.3015 9.0325 23.9696V23.9696C9.61445 23.6301 10.3079 23.537 10.9588 23.7112C11.6097 23.8854 12.164 24.3124 12.4986 24.8972C12.7179 25.2668 12.8358 25.6878 12.8403 26.1176V26.1176C12.8403 27.5264 13.9823 28.6684 15.391 28.6684H17.063C18.467 28.6684 19.607 27.5338 19.6137 26.1298V26.1298C19.6105 25.4523 19.8782 24.8017 20.3572 24.3226C20.8363 23.8435 21.487 23.5758 22.1645 23.5791C22.5932 23.5906 23.0125 23.708 23.3849 23.9208V23.9208C24.6019 24.6221 26.1569 24.2074 26.8632 22.9933V22.9933L27.7419 21.5288C28.082 20.945 28.1754 20.2496 28.0013 19.5968C27.8272 18.9439 27.4 18.3874 26.8143 18.0505V18.0505C26.2287 17.7136 25.8015 17.1571 25.6274 16.5042C25.4533 15.8514 25.5467 15.1561 25.8868 14.5722C26.108 14.1861 26.4282 13.8659 26.8143 13.6447V13.6447C28.0212 12.9388 28.4349 11.393 27.7419 10.1787V10.1787V10.1664Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <circle cx="16.2336" cy="15.8547" r="3.51487" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
