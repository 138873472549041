.details {
    margin-top: 30px;

    h4 {
        font-size: 14px;
        font-weight: 500;
        color: #303030;
    }

    .detail {
        display: flex;
        font-size: 16px;
        font-weight: 500;

        p {
            flex: 1;
            color: #636778;
        }

        span {
            color: #303030;
        }
    }

    hr {
        border-color: #D7DBE7;
    }
}

.wrapper {
    position: relative;

    .loader {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #fff, $alpha: 0.75);
        z-index: 10;

        display: flex;
        align-items: center;
        justify-content: center;
    }
}