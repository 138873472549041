import React, { useState } from 'react'
import * as styles from './index.module.scss';
import Tabs from './tabs/index';
import { ReceivePaymentQrCodeComponent } from './qrcode/index';
import { ReceivePaymentPhoneComponent } from './phone/index';
import { ReceivePaymentCardComponent } from './card/index';

export const ReceivePaymentModal = (props) => {
    // state
    const [activeTab, setActiveTab] = useState(null);

    // handlers
    const handleTabSelected = (tab) => {
        setActiveTab(tab);
    }

    const renderTabBody = () => {
        switch (activeTab) {
            case 'qr':
                return <ReceivePaymentQrCodeComponent />
            case 'phone':
                return <ReceivePaymentPhoneComponent />
            case 'card':
                return <ReceivePaymentCardComponent />
            default:
                break;
        }
    }


    return (
        <div>
            <div className="mt-3">
                <h3 className="text-center">Receive Payment</h3>
            </div>

            <div className='mt-4'>
                <Tabs onTabSelected={handleTabSelected} />
            </div>

            <div className="mt-3">
                {renderTabBody()}
            </div>
        </div>
    )
}
