.grid {
    margin-top: 10px;
    padding: 8px;

    display: grid;
    grid-template-columns: auto auto;
    column-gap: 50px;

    .cell {
        label {
            font-size: 12px;
            font-weight: 400;
            color: #636778;
        }

        p {
            font-size: 14px;
            font-weight: 500;
            color: #303030;
        }
    }
}

.footer {
    font-size: 12px;
    font-weight: 300;
    color: #636778;
    text-align: center;
    margin-top: 20px;

    strong {
        color: #303030;
        font-weight: 400;
    }
}