@import '$assets/scss/main.scss';

.item {
    //
    color: #8397AF;
    text-decoration: none;

    &.active {
        background-color: $primary;
        color: white;
    }

    height: 52px;
    width: 200px;

    padding: 0 16px;
    border-radius: 10px;

    display: flex;
    align-items: center;

    .content {
        display: flex;

        .icon {
            height: 22px;
            width: 22px;
            margin-right: 16px;
        }
    
        .title {
            font-weight: 600;
            font-size: 18px; 
        }
    }
}