import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { createRandomUser, getBank } from '../../../../api/faker/factories';
import { hideModal, showModal } from '../../../../application/features/modal';
import InputMoney from '../../form/money';
import { InputPaymentMethod } from '../../form/paymentmethod';
import UserSummaryComponent from '../../views/user-summary';
import * as styles from './index.module.scss';
import { MODAL_CONFIRM_PASSWORD, MODAL_SEND_MONEY_BANK_INFO, MODAL_SEND_MONEY_MOBILE_INFO } from '../../layout/modal/index';
import BankSummaryComponent from '../../views/bank-summary/index';
import { useEffect } from 'react';
import { setSettlement, withdrawCalculateCharge, withdrawCreateSettlement } from '../../../../application/features/withdraw';
import { numberWithCommas, nFormatter } from '../../../../core/helpers';
import { useState } from 'react';


export const SendMoneyBankInfoModal = ({ payload, bank }) => {
    // state
    const [mounted, setMounted] = useState(false);
    const [showLoader, setShowLoader] = useState(true);
    const [charge, setCharge] = useState(0);

    // redux
    const dispatch = useDispatch();
    const calculateCharge = useSelector(state => state.withdraw.calculateCharge);
    const types = useSelector(state => state.withdraw.types);
    const balanceData = useSelector(state => state.dashboard.balance.data);
    const createSettlement = useSelector(state => state.withdraw.create);

    // effects
    useEffect(() => setMounted(true), []);

    useEffect(() => {
        const data = {
            payload: payload
        }
        dispatch(withdrawCalculateCharge(data));
    }, [payload]);

    useEffect(() => {
        console.log(calculateCharge.data);
        if (calculateCharge.data && calculateCharge.data.data) {
            setCharge(calculateCharge.data.data.charge);
        }

        if (!calculateCharge.isLoading) {
            setTimeout(() => {
                setShowLoader(false);
            }, 1000);
        }
    }, [calculateCharge])
    
    // handlers
    const handleOnBackClicked = () => {
        dispatch(showModal(MODAL_SEND_MONEY_BANK_INFO, { payload, bank }));
    }

    const handleOnPasswordConfirmed = () => {
        dispatch(setSettlement({
            settlement_type: types.find(u => u.id == payload.settlement_type_id).name,
            bank: bank,
            payload: payload,
            charge: charge,
        }));
        dispatch(withdrawCreateSettlement({payload: payload}));
        dispatch(hideModal());
        // dispatch(showModal(MODAL_SEND_MONEY_BANK_INFO, { payload, bank }));
    }
    
    const handleSend = () => {
        setShowLoader(true);
        setTimeout(() => {
            setShowLoader(false);
            dispatch(showModal(MODAL_CONFIRM_PASSWORD, {onBackClicked: handleOnBackClicked, onConfirm: handleOnPasswordConfirmed}));
        }, 3000);
    }
  return (
    <div className={styles.wrapper}>
        {showLoader && (
            <div className={styles.loader}>
                <div className='text-center'>
                    <span className="spinner-border spinner-border-lg"></span>
                    <h5 className='text-center'>Please Wait...</h5>
                    <p className='text-center'>We are processing the request</p>
                </div>
            </div>
        )}
        <div className="mt-3"></div>

        <BankSummaryComponent bank={bank} />

        <div className="mt-5">
            <div className={styles.list}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Available Balance
                    </div>
                    <div className={styles.value}>
                        {numberWithCommas(balanceData.balance)} TZS
                    </div>
                </div>

                <div className={styles.item}>
                    <div className={styles.label}>
                        Withdraw Type
                    </div>
                    <div className={styles.value}>
                        {types.find(u => u.id == payload.settlement_type_id).name}
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Operator
                    </div>
                    <div className={styles.value}>
                        {bank.bank_name}
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        A/C No.
                    </div>
                    <div className={styles.value}>
                        {bank.account_no}
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Description
                    </div>
                    <div className={styles.value}>
                        {payload.description}
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Amount
                    </div>
                    <div className={styles.value}>
                        {showLoader ? '---' : numberWithCommas(payload.amount ?? 0)} TZS
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Fee
                    </div>
                    <div className={styles.value}>
                        {showLoader ? '---' : numberWithCommas(charge)} TZS
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Total
                    </div>
                    <div className={styles.value}>
                        {numberWithCommas((parseFloat(payload.amount) + parseFloat(charge)))} TZS
                    </div>
                </div>
            </div>
        </div>

        <div className="d-grid mt-3">
            <button className="btn btn-primary btn-lg" onClick={handleSend}>{calculateCharge.isLoading && <span className='spinner-border spinner-border-sm'></span>} Send</button>
        </div>
    </div>
  )
}
