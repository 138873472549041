import React from 'react'

export const IconBank = (props) => {
  return (
    <svg viewBox="0 0 36 36" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M31.1243 17.3617H4.87435C3.47435 17.3617 2.32227 16.2096 2.32227 14.8096V9.96793C2.32227 8.97626 3.00765 7.97002 3.9264 7.60543L17.0514 2.35547C17.6056 2.13672 18.3931 2.13672 18.9473 2.35547L32.0723 7.60543C32.991 7.97002 33.6764 8.99084 33.6764 9.96793V14.8096C33.6764 16.2096 32.5243 17.3617 31.1243 17.3617ZM17.9993 4.36798C17.941 4.36798 17.8827 4.3679 17.8536 4.38248L4.74306 9.63254C4.65556 9.67629 4.50977 9.86584 4.50977 9.96793V14.8096C4.50977 15.0138 4.67018 15.1742 4.87435 15.1742H31.1243C31.3285 15.1742 31.4889 15.0138 31.4889 14.8096V9.96793C31.4889 9.86584 31.3577 9.67629 31.2556 9.63254L18.1306 4.38248C18.1015 4.3679 18.0577 4.36798 17.9993 4.36798Z" />
        <path d="M32.5827 33.4017H3.41602C2.8181 33.4017 2.32227 32.9059 2.32227 32.3079V27.9329C2.32227 26.5329 3.47435 25.3809 4.87435 25.3809H31.1243C32.5243 25.3809 33.6764 26.5329 33.6764 27.9329V32.3079C33.6764 32.9059 33.1806 33.4017 32.5827 33.4017ZM4.50977 31.2142H31.4889V27.9329C31.4889 27.7288 31.3285 27.5684 31.1243 27.5684H4.87435C4.67018 27.5684 4.50977 27.7288 4.50977 27.9329V31.2142Z" />
        <path d="M6.33398 27.5677C5.73607 27.5677 5.24023 27.0719 5.24023 26.474V16.2656C5.24023 15.6677 5.73607 15.1719 6.33398 15.1719C6.9319 15.1719 7.42773 15.6677 7.42773 16.2656V26.474C7.42773 27.0719 6.9319 27.5677 6.33398 27.5677Z" />
        <path d="M12.168 27.5677C11.5701 27.5677 11.0742 27.0719 11.0742 26.474V16.2656C11.0742 15.6677 11.5701 15.1719 12.168 15.1719C12.7659 15.1719 13.2617 15.6677 13.2617 16.2656V26.474C13.2617 27.0719 12.7659 27.5677 12.168 27.5677Z" />
        <path d="M18.002 27.5677C17.404 27.5677 16.9082 27.0719 16.9082 26.474V16.2656C16.9082 15.6677 17.404 15.1719 18.002 15.1719C18.5999 15.1719 19.0957 15.6677 19.0957 16.2656V26.474C19.0957 27.0719 18.5999 27.5677 18.002 27.5677Z" />
        <path d="M23.834 27.5677C23.2361 27.5677 22.7402 27.0719 22.7402 26.474V16.2656C22.7402 15.6677 23.2361 15.1719 23.834 15.1719C24.4319 15.1719 24.9277 15.6677 24.9277 16.2656V26.474C24.9277 27.0719 24.4319 27.5677 23.834 27.5677Z" />
        <path d="M29.668 27.5677C29.0701 27.5677 28.5742 27.0719 28.5742 26.474V16.2656C28.5742 15.6677 29.0701 15.1719 29.668 15.1719C30.2659 15.1719 30.7617 15.6677 30.7617 16.2656V26.474C30.7617 27.0719 30.2659 27.5677 29.668 27.5677Z" />
        <path d="M34.0423 33.4004H1.95898C1.36107 33.4004 0.865234 32.9046 0.865234 32.3066C0.865234 31.7087 1.36107 31.2129 1.95898 31.2129H34.0423C34.6402 31.2129 35.1361 31.7087 35.1361 32.3066C35.1361 32.9046 34.6402 33.4004 34.0423 33.4004Z" />
        <path d="M18 13.7129C16.1917 13.7129 14.7188 12.24 14.7188 10.4316C14.7188 8.62331 16.1917 7.15039 18 7.15039C19.8083 7.15039 21.2813 8.62331 21.2813 10.4316C21.2813 12.24 19.8083 13.7129 18 13.7129ZM18 9.33789C17.4021 9.33789 16.9063 9.83372 16.9063 10.4316C16.9063 11.0296 17.4021 11.5254 18 11.5254C18.5979 11.5254 19.0938 11.0296 19.0938 10.4316C19.0938 9.83372 18.5979 9.33789 18 9.33789Z" />
     </svg>

  )
}
