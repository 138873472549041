import React from 'react'

export const IconSendMoney = (props) => {
  return (
    <svg viewBox="0 0 28 28" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15.2256 18.9596H13.0323C11.4807 18.9596 10.209 17.6529 10.209 16.0429C10.209 15.5646 10.6057 15.1679 11.084 15.1679C11.5623 15.1679 11.959 15.5646 11.959 16.0429C11.959 16.6846 12.4373 17.2096 13.0323 17.2096H15.2256C15.6806 17.2096 16.0423 16.8012 16.0423 16.2996C16.0423 15.6696 15.8673 15.5762 15.4707 15.4362L11.959 14.2112C11.2123 13.9429 10.209 13.4062 10.209 11.6912C10.209 10.2329 11.364 9.03125 12.7757 9.03125H14.969C16.5206 9.03125 17.7923 10.3379 17.7923 11.9479C17.7923 12.4262 17.3957 12.8229 16.9173 12.8229C16.439 12.8229 16.0423 12.4262 16.0423 11.9479C16.0423 11.3062 15.564 10.7812 14.969 10.7812H12.7757C12.3207 10.7812 11.959 11.1896 11.959 11.6912C11.959 12.3212 12.134 12.4146 12.5306 12.5546L16.0423 13.7796C16.789 14.0479 17.7923 14.5846 17.7923 16.2996C17.7923 17.7696 16.6373 18.9596 15.2256 18.9596Z" />
        <path d="M14 20.125C13.5217 20.125 13.125 19.7283 13.125 19.25V8.75C13.125 8.27167 13.5217 7.875 14 7.875C14.4783 7.875 14.875 8.27167 14.875 8.75V19.25C14.875 19.7283 14.4783 20.125 14 20.125Z" />
        <path d="M14.0007 26.5404C7.08232 26.5404 1.45898 20.917 1.45898 13.9987C1.45898 7.08036 7.08232 1.45703 14.0007 1.45703C14.479 1.45703 14.8757 1.8537 14.8757 2.33203C14.8757 2.81036 14.479 3.20703 14.0007 3.20703C8.05065 3.20703 3.20898 8.0487 3.20898 13.9987C3.20898 19.9487 8.05065 24.7904 14.0007 24.7904C19.9507 24.7904 24.7923 19.9487 24.7923 13.9987C24.7923 13.5204 25.189 13.1237 25.6673 13.1237C26.1457 13.1237 26.5423 13.5204 26.5423 13.9987C26.5423 20.917 20.919 26.5404 14.0007 26.5404Z" />
        <path d="M25.6667 7.8737C25.1883 7.8737 24.7917 7.47703 24.7917 6.9987V3.20703H21C20.5217 3.20703 20.125 2.81036 20.125 2.33203C20.125 1.8537 20.5217 1.45703 21 1.45703H25.6667C26.145 1.45703 26.5417 1.8537 26.5417 2.33203V6.9987C26.5417 7.47703 26.145 7.8737 25.6667 7.8737Z" />
        <path d="M19.833 9.04135C19.6114 9.04135 19.3897 8.95968 19.2147 8.78468C18.8764 8.44635 18.8764 7.88635 19.2147 7.54802L25.048 1.71469C25.3864 1.37635 25.9464 1.37635 26.2847 1.71469C26.623 2.05302 26.623 2.61302 26.2847 2.95135L20.4514 8.78468C20.2764 8.95968 20.0547 9.04135 19.833 9.04135Z" />
    </svg>
  )
}
