// import main.scss
@import '$assets/scss/main.scss';
@import './loader.module.scss';

.login {
    height: 100vh;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    .card {
        width: 500px;
    }
}

.loader {
    height: 100vh;
    width: 100vw;

    display: flex;
    align-items: center;
    justify-content: center;
}