.wrapper {
    position: relative;

    .loader {
        position: absolute;
        height: 100%;
        width: 100%;
        background-color: rgba($color: #fff, $alpha: 0.75);

        display: flex;
        align-items: center;
        justify-content: center;
    }
}