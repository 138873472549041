import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dashboardGetStatisticsDisbursement, dashboardGetStatisticsDisbursementAmount } from '../../../application/features/dashboard';
import Colors from '../../../assets/contants/colors';

const _dataset = {
    label: 'Disbursement',
    data: [],
    backgroundColor: Colors.PRIMARY_COLOR,
};

export const useDisbursementAmountData = () => {
    // state
    const [dataSet, setDataSet] = useState(_dataset);

    // redux
    const dispatch = useDispatch();
    const disbursementAmountGraph = useSelector(state => state.dashboard.disbursementAmountGraph);

    // effects
    useEffect(() => {
        dispatch(dashboardGetStatisticsDisbursementAmount());
    }, []);

    useEffect(() => {
        setDataSet(prevState => {
            return {
                ...prevState,
                data: Object.keys(disbursementAmountGraph.data).map(key => disbursementAmountGraph.data[key]),
            };
        });
    }, [disbursementAmountGraph.data]);

    return {
        dataset: dataSet,
        isLoading: disbursementAmountGraph.isFetching,
    };
}