@import '$assets/scss/main.scss';

.input {
    label {
        font-weight: 500;
        font-size: 16px;
        color: #303030;
    }

    input {
        all: unset;
        appearance: textfield;

        &::-webkit-outer-spin-button {
            appearance: none;
        }

        &::placeholder {
            font-size: 16px;
            font-weight: 500;
            color: #8B8E97;
        }

        &:focus {
           all: unset;
        }
    }

    .group {
        display: flex;
        border: solid 1px #CCD0E1;
        border-radius: 10px;
        padding: 12px;
        gap: 8px;

        &.isInvalid {
            border-color: $danger;
        }
    }

    p.isInvalid {
        margin-top: 10px;
        color: $danger;
    }
}

.pin {
    display: flex;
    column-gap: 10px;

    input {
        all: unset;
        border: solid 1px #A2B1C3;
        border-radius: 8px;
        height: 90px;
        width: 60px;
    }
}