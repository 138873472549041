import React from 'react'
import { AppLayout } from '../../components/layout/app';
import ListHeaderComponent from '../../components/list/header/index';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { IconView } from '../../components/icons/view';
import { useEffect } from 'react';
import { getUsers, getRoles, assignRole, revokeRole, deleteUser } from '../../../application/features/authentication';
import { showModal } from '../../../application/features/modal';
import { MODAL_USER_FORM } from '../../components/layout/modal';
import { MODAL_USER_FORM } from '../../components/layout/modal/index';
import { IconDelete } from '../../components/icons/delete';
import { IconEdit } from '../../components/icons/edit';
import { useCan } from '../../hooks/can';
import { PERMISSION_ACCOUNT_MANAGE } from '../../../core/permissions';

export const UserManagementPage = () => {

    // authorization
    const canManageUsers = useCan(PERMISSION_ACCOUNT_MANAGE);

    // redux
    const dispatch = useDispatch();
    const users = useSelector(state => state.authentication.users);
    const roles = useSelector(state => state.authentication.roles);
    const authenticatedUser = useSelector(state => state.authentication.user);
    const assignRoleState = useSelector(state => state.authentication.assignRole);
    const revokeRoleState = useSelector(state => state.authentication.revokeRole);

    // effects
    useEffect(() => {
        dispatch(getUsers());
        dispatch(getRoles());
    }, []);

    // check if user has a role
    const hasRole = (user, role) => {
        return user.roles.find(r => r.id == role.id);
    }

    // handle toggle role
    const handleToggleRole = (user, role) => {
        const data = {
            id: user.id,
            reqID: user.id.toString() + role.id.toString(),
            payload: {
                "role_id": role.id,
            }
        }
        if (hasRole(user, role)) {
            // remove role
            dispatch(revokeRole(data));
        } else {
            // add role
            dispatch(assignRole(data));
        }
    }

    const isLoading = (user, role) => {
        return (assignRoleState.isLoading.some(id => id == (user.id.toString() + role.id.toString())) || revokeRoleState.isLoading.some(id => id == (user.id.toString() + role.id.toString())));
    }

    const handleCreateUser = () => {
        dispatch(showModal(MODAL_USER_FORM));
    }

    const handleUpdateUser = (user) => {
        dispatch(showModal(MODAL_USER_FORM, { user }));
    }

    const handleDeleteUser = (user) => {
        dispatch(deleteUser({id: user.id}));
    }
    
    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent title="Users Management" actionOnClick={handleCreateUser} actionTitle="Add User" />

                <div className="mt-5">
                    <div className="card">
                        <div className="card-body table-responsive">
                            <table className='table'>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Email</th>
                                        {roles.map(r => <th>{r.name}</th>)}
                                        <th></th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {users.map(user => (
                                        <tr key={user.id}>
                                            <td>{user.firstname} {user.lastname}</td>
                                            <td>{user.email}</td>
                                            {roles.map(r => !isLoading(user, r) ? <td><input onChange={() => handleToggleRole(user, r)} type="checkbox" checked={hasRole(user, r)}></input></td> : <td><span className='spinner-border spinner-border-sm'></span></td>)}
                                            <td>
                                                <div className="btn-group">
                                                    <button className="btn btn-outline-primary btn-sm" onClick={() => handleUpdateUser(user)} title="View"><IconEdit height="15px" width="20px" /></button>
                                                    <button className="btn btn-outline-danger btn-sm" onClick={() => handleDeleteUser(user)} title="Reuse"><IconDelete height="15px" width="20px" /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}

                                    {users.length == 0 ? (
                                        <tr key={authenticatedUser.id}>
                                            <td>{authenticatedUser.firstname} {authenticatedUser.lastname}</td>
                                            <td>{authenticatedUser.email}</td>
                                            {roles.map(r => !isLoading(authenticatedUser, r) ? <td><input type="checkbox" onChange={() => handleToggleRole(authenticatedUser, r)} checked={hasRole(authenticatedUser, r)} ></input></td> : <td><span className='spinner-border spinner-border-sm'></span></td>)}
                                            <td>
                                                <div className="btn-group">
                                                    <button className="btn btn-outline-primary btn-sm" onClick={() => handleUpdateUser(authenticatedUser)} title="View"><IconEdit height="15px" width="20px" /></button>
                                                    <button className="btn btn-outline-danger btn-sm" disabled={true} onClick={() => handleDeleteUser(authenticatedUser)} title="Reuse"><IconDelete height="15px" width="20px" /></button>
                                                </div>
                                            </td>
                                        </tr>
                                    ) : null}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
