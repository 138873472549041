import React from 'react'

export const IconHome = (props) => {
  return (
    <svg viewBox="0 0 22 22" xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" {...props}>
      <path d="M8.26898 2.60364L3.32815 6.45364C2.50315 7.09531 1.83398 8.46114 1.83398 9.49698V16.2895C1.83398 18.4161 3.56648 20.1578 5.69315 20.1578H16.3082C18.4348 20.1578 20.1673 18.4161 20.1673 16.2986V9.62531C20.1673 8.51614 19.4248 7.09531 18.5173 6.46281L12.8523 2.49365C11.569 1.59531 9.50648 1.64115 8.26898 2.60364Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11 16.4902V13.7402" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );
}
