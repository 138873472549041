import React from 'react'

export const IconReceiveMoney = (props) => {
  return (
    <svg viewBox="0 0 17 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M9.20064 10.8335H7.94733C7.06066 10.8335 6.33398 10.0868 6.33398 9.16682C6.33398 8.89349 6.56065 8.66682 6.83398 8.66682C7.10732 8.66682 7.33398 8.89349 7.33398 9.16682C7.33398 9.53349 7.60733 9.83349 7.94733 9.83349H9.20064C9.46064 9.83349 9.66732 9.60015 9.66732 9.31349C9.66732 8.95349 9.56732 8.90015 9.34066 8.82015L7.33398 8.12015C6.90732 7.97348 6.33398 7.66016 6.33398 6.68016C6.33398 5.84682 6.99399 5.16016 7.80066 5.16016H9.05398C9.94064 5.16016 10.6673 5.90682 10.6673 6.82682C10.6673 7.10016 10.4407 7.32682 10.1673 7.32682C9.89398 7.32682 9.66732 7.10016 9.66732 6.82682C9.66732 6.46016 9.39398 6.16016 9.05398 6.16016H7.80066C7.54066 6.16016 7.33398 6.39349 7.33398 6.68016C7.33398 7.04016 7.43398 7.09348 7.66064 7.17348L9.66732 7.87348C10.094 8.02015 10.6673 8.33349 10.6673 9.31349C10.6673 10.1535 10.0073 10.8335 9.20064 10.8335Z" />
        <path d="M8.5 11.5C8.22667 11.5 8 11.2733 8 11V5C8 4.72667 8.22667 4.5 8.5 4.5C8.77333 4.5 9 4.72667 9 5V11C9 11.2733 8.77333 11.5 8.5 11.5Z" />
        <path d="M8.50065 15.1654C4.54732 15.1654 1.33398 11.952 1.33398 7.9987C1.33398 4.04536 4.54732 0.832031 8.50065 0.832031C8.77398 0.832031 9.00065 1.0587 9.00065 1.33203C9.00065 1.60536 8.77398 1.83203 8.50065 1.83203C5.10065 1.83203 2.33398 4.5987 2.33398 7.9987C2.33398 11.3987 5.10065 14.1654 8.50065 14.1654C11.9007 14.1654 14.6673 11.3987 14.6673 7.9987C14.6673 7.72536 14.894 7.4987 15.1673 7.4987C15.4407 7.4987 15.6673 7.72536 15.6673 7.9987C15.6673 11.952 12.454 15.1654 8.50065 15.1654Z" />
        <path d="M14.5007 5.16667H11.834C11.5607 5.16667 11.334 4.94 11.334 4.66667V2C11.334 1.72667 11.5607 1.5 11.834 1.5C12.1073 1.5 12.334 1.72667 12.334 2V4.16667H14.5007C14.774 4.16667 15.0007 4.39333 15.0007 4.66667C15.0007 4.94 14.774 5.16667 14.5007 5.16667Z" />
        <path d="M11.8343 5.1676C11.7076 5.1676 11.5809 5.12094 11.4809 5.02094C11.2876 4.8276 11.2876 4.5076 11.4809 4.31427L14.8143 0.980938C15.0076 0.787604 15.3276 0.787604 15.5209 0.980938C15.7143 1.17427 15.7143 1.49427 15.5209 1.6876L12.1876 5.02094C12.0876 5.12094 11.9609 5.1676 11.8343 5.1676Z" />
    </svg>
  )
}
