import React from 'react'
import { createRandomUser } from '../../../../api/faker/factories';
import { IconUser } from '../../icons/user';
import * as styles from './index.module.scss';
import { IconMessage } from '../../icons/message';
import { IconPhone } from '../../icons/phone';
import { IconEdit } from '../../icons/edit';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_CUSTOMER_ADD } from '../../layout/modal';
import { getRandomBackgroundColor } from '../../../../core/helpers';

const rand = getRandomBackgroundColor();

const CustomerProfileModal = (props) => {
    // props
    const { customer } = props;

    // store
    const dispatch = useDispatch();

    // handlers
    const handleEdit = () => {
        dispatch(showModal(MODAL_CUSTOMER_ADD, {customer: customer}));
    }

    return (
        <div>
            <div className={`d-flex justify-content-center ${styles.profile}`}>
                <div className={styles.avatar} alt="" style={{ backgroundColor: rand.background, color: rand.text }}>
                    {customer.first_name ? customer.first_name.slice(0,1) : ''}{customer.last_name ? customer.last_name.slice(0,1) : ''}
                </div>
                <div className={styles.summary}>
                    <span className={styles.name}>{customer.first_name} {customer.last_name}</span>
                    <span>{customer.phone_no}</span>
                </div>
            </div>

            <div className={styles.details}>
                <div className={styles.detail}>
                    <label htmlFor="">Name</label>
                    <div className={styles.info}>
                        <IconUser height="24px" width="24px" />
                        <p>{customer.first_name} {customer.last_name}</p>
                    </div>
                </div>
                <div className={styles.detail}>
                    <label htmlFor="">Email</label>
                    <div className={styles.info}>
                        <IconMessage height="24px" width="24px" />
                        <p>{customer.email} </p>
                    </div>
                </div>
                <div className={styles.detail}>
                    <label htmlFor="">Phone</label>
                    <div className={styles.info}>
                        <IconPhone height="24px" width="24px" />
                        <p>{customer.phone_no}</p>
                    </div>
                </div>
            </div>

            <div className="d-grid mt-2">
                <button className="btn btn-primary" onClick={handleEdit}>
                    <IconEdit height="18px" width="18px" />
                    &nbsp;
                    Edit Now
                </button>
            </div>
        </div>
    );
}

export default CustomerProfileModal;