
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customFetch } from '../../core/request';
import MlipaApi from '../../api/mlipa/endpoints';


// ACTION CONSTANTS
const TRANSACTION_FETCH_TRANSACTIONS = 'TRANSACTION_FETCH_TRANSACTIONS';
const TRANSACTION_EXPORT_EXCEL_TRANSACTIONS = 'transaction/EXPORT_EXCEL_TRANSACTION';
const TRANSACTION_EXPORT_EXCEL = 'transactions/EXPORT_EXCEL';
const TRANSACTION_EXPORT_PDF = 'transactions/EXPORT_PDF';

// ACTIONS
export const transactionFetchTransactions = createAsyncThunk(
    TRANSACTION_FETCH_TRANSACTIONS,
    async (data = {}, store) => {
        // console.log("filters", data);
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.transactions, data.payload, token, store);
    }
);

// export const transactionExportExcel = createAsyncThunk(
//     TRANSACTION_EXPORT_EXCEL_TRANSACTIONS,
//     async (data = {}, store) => {
//         const { token } = store.getState().authentication;
//         const response = customFetch('POST', MlipaApi.transactions, data.payload, token, store);
//     }
// );

export const transactionsExportExcel = createAsyncThunk(
    TRANSACTION_EXPORT_EXCEL,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', MlipaApi.transactionsExcel, data.params, token, store, {'Accept': '*/*'});
    }
)

export const transactionsExportPdf = createAsyncThunk(
    TRANSACTION_EXPORT_EXCEL,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', MlipaApi.transactionsPdf, data.params, token, store, {'Content-Type': 'application/pdf', 'Accept': 'application/pdf'});
    }
)

// INITIAL STATE
const initialState = {
    transactions: [],
    filters: {
        date1: null,
        date2: null,
        type: null,
        operator: null,
    },
    fetch: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    excel: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    pdf: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    }
};

// SLICE
const transactionSlice = createSlice({
    name: 'transaction',
    initialState,
    extraReducers: {
        [transactionFetchTransactions.pending]: (state, action) => {
            state.fetch.isLoading = true;
            state.fetch.isSuccessfull = false;
            state.fetch.error = null;
            // state.fetch.data = {};
        },
        [transactionFetchTransactions.fulfilled]: (state, action) => {
            state.fetch.isLoading = false;
            state.fetch.isSuccessfull = true;
            state.fetch.error = null;
            state.fetch.data = action.payload;
            state.transactions = action.payload.data;
        },
        [transactionFetchTransactions.rejected]: (state, action) => {
            state.fetch.isLoading = false;
            state.fetch.isSuccessfull = false;
            state.fetch.error = action.payload;
            state.fetch.data = {};
        },
        [transactionsExportExcel.pending]: (state, action) => {
            state.excel.isLoading = true;
            state.excel.isSuccessfull = false;
            state.excel.error = null;
            // state.excel.data = {};
        },
        [transactionsExportExcel.fulfilled]: (state, action) => {
            state.excel.isLoading = false;
            state.excel.isSuccessfull = true;
            state.excel.error = null;
            state.excel.data = action.payload;
            state.transactions = action.payload.data;
        },
        [transactionsExportExcel.rejected]: (state, action) => {
            state.excel.isLoading = false;
            state.excel.isSuccessfull = false;
            state.excel.error = action.payload;
            state.excel.data = {};
        },
        [transactionsExportPdf.pending]: (state, action) => {
            state.pdf.isLoading = true;
            state.pdf.isSuccessfull = false;
            state.pdf.error = null;
            // state.pdf.data = {};
        },
        [transactionsExportPdf.fulfilled]: (state, action) => {
            state.pdf.isLoading = false;
            state.pdf.isSuccessfull = true;
            state.pdf.error = null;
            state.pdf.data = action.payload;
            state.transactions = action.payload.data;
        },
        [transactionsExportPdf.rejected]: (state, action) => {
            state.pdf.isLoading = false;
            state.pdf.isSuccessfull = false;
            state.pdf.error = action.payload;
            state.pdf.data = {};
        },
    }
});

export default transactionSlice.reducer;