.transaction {
    display: flex;
    margin-bottom: 10px;
    border-bottom: solid 1px #E0E5EB;
    padding-bottom: 5px;

    .avatar {
        img {
            height: 44px;
            width: 44px;
            object-fit: cover;
            object-position: center;
            border-radius: 15px;
        }
    }

    .content {
        flex: 1;

        display: flex;
        flex-direction: column;
        padding: 0 8px;

        .phone {
            font-size: 14px;
            font-weight: 500;
            color: #303030;
        }

        .type {
            font-size: 12px;
            font-weight: 400;
            color: #636778;
        }
    }

    .trail {
        display: flex;
        flex-direction: column;
        align-items: flex-end;

        .amount {
            font-size: 14;
            font-weight: 700;
            color: #72BE46;
        }

        p {
            font-size: 12px;
            font-weight: 400;
            color: #636778;
            margin: 0;
            padding: 0;
        }
    }
}