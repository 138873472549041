import React, { useEffect, useRef, useState } from 'react'
import ListHeaderComponent from '../../components/list/header';
import Tabs from '../../components/modals/receivePayment/tabs';
import { AppLayout } from '../../components/layout/app/index';
import { createRandomTransaction } from '../../../api/faker/factories';
import { formatDate, numberWithCommas, startDownload, CONTENT_TYPES } from '../../../core/helpers';
import { useSelector, useDispatch } from 'react-redux';
import Input from '../../components/form/input';
import Select from '../../components/form/select';
import { transactionFetchTransactions, transactionsExportExcel, transactionsExportPdf } from '../../../application/features/transaction';
import { operators } from '../../../assets/contants/operators';
import ListPaginationComponent from '../../components/list/pagination/index';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { dashboardGetAccountBalance } from '../../../application/features/dashboard';
import MlipaApi from '../../../api/mlipa/endpoints';
import { IconExcel } from '../../components/icons/excel';
import { IconPdf } from '../../components/icons/pdf';
import ExportExcelButton from '../../components/widgets/export/excel';
import ExportPdfButton from '../../components/widgets/export/pdf';

const tabs = [
    { label: 'All', value: 'all' },
    { label: 'Top Up', value: 'TOPUP'},
    { label: 'Pay In', value: 'COLLECTION'},
    { label: 'Withdrawals', value: 'SETTLEMENT'},
    { label: 'Pay Out', value: 'DISBURSEMENT'},
]

export const TransactionsPage = () => {
    // ref
    const tableRef = useRef();
    const maxDate = new Date();
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);
    const maxDateString = `${maxDate.getFullYear()}-${maxDate.getMonth() + 1}-${maxDate.getDate()}`;
    const minDateString = `${minDate.getFullYear()}-${minDate.getMonth() + 1}-${minDate.getDate()}`;

    // state
    const [filters, setFilters] = useState({});
    const [search, setSearch] = useState('');

    // hooks
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'mlipa-transactions',
        sheet: 'Transactions'
    });

    // redux
    const dispatch = useDispatch();
    const token = useSelector(state => state.authentication.token);
    const isLoading = useSelector(state => state.transaction.fetch.isLoading);
    const transactions = useSelector(state => state.transaction.transactions);
    const transactionResponse = useSelector(state => state.transaction.fetch.data);
    const balanceData = useSelector(state => state.dashboard.balance.data);
    const user = useSelector(state => state.authentication.user);
    const links = useSelector(state => state.transaction.fetch.data.links);

    // effects
    useEffect(() => {
        if (!balanceData.hasOwnProperty('balance')) {
            dispatch(dashboardGetAccountBalance());
        }
    }, []);

    useEffect(() => {
        console.log("filters", filters);
        const data = {
            payload: filters,
        }
        dispatch(transactionFetchTransactions(data));
    }, [filters]);

    useEffect(() => {
        setTimeout(() => {
            if (search) {
                setFilters({...filters, 'search': search});
            } else {
                const __filters = {...filters}
                delete __filters['search'];
                setFilters(__filters);
            }
        }, 500);  
    }, [search])

    // handlers
    const handleDownloadExcel = () => {
        setIsExportingExcel(true);
        onDownload()
        setTimeout(() => {
            setIsExportingExcel(false);
        }, 1000);
    }
    const handleTabChanged = (tab) => {
        if (tab == 'all') {
            const __filters = {...filters}
            delete __filters['type'];
            setFilters(__filters);
        } else {
            setFilters({...filters, type: tab});
        }
    }

    const handleChange = (name, value) => {
        // console.log(name, value, typeof value);
        if (value) {
            // console.log("apllying the filter")
            setFilters({...filters, [name]: value});
        } else {
            // console.log("deleting a key inside filters");
            const __filters = {...filters}
            delete __filters[name];
            setFilters(__filters);
        }  
    }

    const handleSearchChange = (value) => {
        setSearch(value);
    }

    const formatType = (type) => {
        if (type == 'DISBURSEMENT') {
            return 'PAY OUT';
        }
        if (type == 'COLLECTION') {
            return 'PAY IN';
        }
        return type;
    }

    return (
        <AppLayout>
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <ListHeaderComponent title="Transactions" isLoading={isLoading} searchOnChange={handleSearchChange} />

                        <div className="my-3 d-flex align-items-end">
                            <div className="">
                                <Tabs initialValue='all' tabs={tabs} justifyContent="start" onTabSelected={handleTabChanged} />
                            </div>
                            <div className="flex-grow-1"></div>
                            <div className="d-flex">
                                <Input type="date" name="date1" label="From" placeholder="From" min={minDateString} max={maxDateString} onChange={handleChange} />
                                <div className="px-2"></div>
                                <Input type="date" name="date2" label="To" placeholder="To" min={minDateString} max={maxDateString} onChange={handleChange} />
                                <div className="px-2"></div>
                                <Select name="operator" label="Operator" onChange={handleChange}>
                                    {operators.map((o, i) => <option value={i ? i : ""}>{o}</option>)}
                                </Select>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-12">
                        <div className="card mb-3">
                            <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1"></div>
                                <ExportExcelButton endpoint={MlipaApi.exportTransactions} filters={filters} />
                                <div className="px-1"></div>
                                <ExportPdfButton endpoint={MlipaApi.exportTransactions} filters={filters} />

                            </div>
                            <table className="table" ref={tableRef}>
                            <thead>
                                <tr>
                                    <th>Date</th>
                                    {/* <th>Account</th> */}
                                    <th>Type</th>
                                    <th>Mobile/A/C</th>
                                    <th>Receipt</th>
                                    <th>Operator</th>
                                    <th className='text-right'>
                                        <div className="d-flex">
                                            <div className="flex-grow-1"></div>
                                            <span className='text-right'>Amount (TZS)</span>
                                        </div>
                                    </th>
                                    <th className='text-right'>
                                        <div className="d-flex">
                                            <div className="flex-grow-1"></div>
                                            <span className='text-right'>Charge (TZS)</span>
                                        </div>
                                    </th>
                                </tr>
                            </thead>

                            <tbody>
                                {transactions.map(trans => {
                                    return (
                                        <tr>
                                            <td class="nowrap">{trans.createdon}</td>
                                            {/* <td>{trans.account}</td> */}
                                            <td>{formatType(trans.type)}</td>
                                            <td>{trans.msisdn ? trans.msisdn : 'N/A'}</td>
                                            <td>{trans.reference}</td>
                                            <td>{trans.operator_id ? operators[trans.operator_id] : 'BANK'}</td>
                                            <td>
                                                <div className="d-flex">
                                                    <span className='flex-grow-1'></span>
                                                    <span className=' text-right'>{numberWithCommas(trans.credit ? trans.credit : trans.debit)}</span>
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <span className='flex-grow-1'></span>
                                                    <span className=' text-right'>{numberWithCommas(trans.total_charge)}</span>
                                                </div>
                                                
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                            </div>
                        </div>

                        <ListPaginationComponent currentPage={transactionResponse.current_page} lastPage={transactionResponse.last_page} onClick={(page) => handleChange('page', page)} />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
