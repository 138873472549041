@import '$assets/scss/main.scss';

.sendIcon {
    height: 54px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    color: #EB7B96;
    background-color: #FFF4EC;
}

.receiveIcon {
    height: 54px;
    width: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 15px;

    color: #43C98E;
    background-color: #ECFAF4;
}

.badge {
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 6px;
    font-weight: 500;
    font-size: 16px;

    &.send {
        color: #EB7B96;
        background-color: #FFF4EC;
    }

    &.receive {
        color: #43C98E;
        background-color: #ECFAF4;
    }
}

td {
    color: #636778;
}

.description {
    display: flex;
    align-items: center;

    p {
        all: unset;
    }
}

.blue {
    color: $primary-color;
    font-weight: 500;
}

.green {
    color: #43C98E;
    font-weight: 500;
}