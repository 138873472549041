import React from 'react'
import * as styles from './index.module.scss';
import target from '$assets/images/target.png';
import qrcode from '$assets/images/qrcode.png';
import Input from '../../../form/input';

export const ReceivePaymentQrCodeComponent = () => {
  return (
    <div className={styles.qrcode}>
        <div className={styles.qr}>
            <img src={target} alt="" className={styles.target} />
            <img src={qrcode} alt="" className={styles.qrcode} />
        </div>

        <div className={styles.till}>
            [Till No: 100000128]
        </div>

        <div className="my-3">
            <Input type="number" placeholder="Set Amount" />
        </div>

        {/* <div className="d-flex justify-content-center">
            <Input type="pin" />
        </div> */}
    </div>
  )
}
