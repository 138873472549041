import React from 'react'

export const IconCalenderFilled = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M4.66667 1.33398C4.29848 1.33398 4 1.63246 4 2.00065V2.66732H3.33333C2.59695 2.66732 2 3.26427 2 4.00065V5.33398H14V4.00065C14 3.26427 13.403 2.66732 12.6667 2.66732H12V2.00065C12 1.63246 11.7015 1.33398 11.3333 1.33398H10.6667C10.2985 1.33398 10 1.63246 10 2.00065V2.66732H6V2.00065C6 1.63246 5.70152 1.33398 5.33333 1.33398H4.66667Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M14 6.66732H2V12.6673C2 13.4037 2.59695 14.0007 3.33333 14.0007H12.6667C13.403 14.0007 14 13.4037 14 12.6673V6.66732ZM4 8.66732C4 8.29913 4.29848 8.00065 4.66667 8.00065H6C6.36819 8.00065 6.66667 8.29913 6.66667 8.66732V9.33398C6.66667 9.70217 6.36819 10.0007 6 10.0007H4.66667C4.29848 10.0007 4 9.70217 4 9.33398V8.66732Z" />
    </svg>
  )
}
