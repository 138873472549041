.tabs {
    display: flex;

    border-bottom: solid 1px #E0E5EB;

    div {
        padding: 10px 20px;
        cursor: pointer;

        &.active {
            border-bottom: solid 1.5px #04619E;
            font-weight: 600;
            color: #04619E;
        }
    }


}