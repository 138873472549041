import React, { useEffect, useState } from 'react'
import Input from '../../form/input';
import { IconUser } from '../../icons/user';
import { IconMessage } from '../../icons/message';
import { IconPhone } from '../../icons/phone';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_ALERT } from '../../layout/modal';
import { customerCreateCustomer, customerUpdateCustomer } from '../../../../application/features/customer';
import * as Yup from "yup";
import { createUser, updateUser } from '../../../../application/features/authentication';

const UserFormModal = (props) => {
  // props
  const { user } = props;

  // state
  const [mounted, setMounted] = useState(false);
  const [payload, setPayload] = useState({name: null, phone_no: null, email: null});
  const [errors, setErrors] = useState({name: null, phone_no: null, email: null});

  // redux
  const dispatch = useDispatch();
  const isCreating = useSelector(state => state.authentication.createUser.isLoading);
  const isUpdating = useSelector(state => state.authentication.updateUser.isLoading);
  
  const isCreated = useSelector(state => state.authentication.createUser.isSuccessfull);
  const isUpdated = useSelector(state => state.authentication.updateUser.isSuccessfull);

  const creatingError = useSelector(state => state.authentication.createUser.error);
  const updatingError = useSelector(state => state.authentication.updateUser.error);

  // effects
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!user) return;
    setPayload({
      name: `${user.first_name} ${user.last_name}`,
      phone_no: user.phone_no,
      email: user.email,
    });
  }, [user]);

  useEffect(() => {
    if (!mounted) return;
    if (!isCreated) return;
    dispatch(showModal(MODAL_ALERT, {title: 'Success', message: `You have successfully added ${payload.name}`}));
  }, [isCreated]);

  useEffect(() => {
    if (!mounted) return;
    if (!isUpdated) return;
    dispatch(showModal(MODAL_ALERT, {title: 'Success', message:  `You have successfully updated ${payload.name}`}));
  }, [isUpdated]);

  useEffect(() => {
    if (!creatingError) return;
    if (!creatingError.errors) return;
    Object.keys(payload).map(key => {
      if (creatingError.errors[key]) {
        setErrors(prevState => {
          return {
            ...prevState,
            [key]: creatingError.errors[key][0],
          }
        });
      }
    });
  }, [creatingError]);

  useEffect(() => {
    if (!updatingError) return;
    // if (!updatingError.errors) return;
    Object.keys(payload).map(key => {
      if (updatingError[key]) {
        setErrors(prevState => {
          return {
            ...prevState,
            [key]: updatingError[key][0],
          }
        });
      }
    });
  }, [updatingError]);

  // handlers
  const handleSave = () => {
    if (!validate()) return;
    if (!user) {
      dispatch(createUser({payload}));
    } else {
      let splitted = payload.name.split(' ');
      const data = {
        id: customer.id,
        payload: {
          ...payload,
          first_name: splitted[0],
          last_name: splitted[1],
        },
      }
      dispatch(updateUser(data));
    }
  }

  const handleChange = (name, value) => {
    setPayload({...payload, [name]: value});

    if (errors[name]) {
      setErrors(prevState => {
        return {...prevState, [name]: null};
      });
    }
  }

  // functions
  const validate = () => {
    let pass = true;
    Object.keys(payload).map(key => {
      if (!payload[key]) {
        setErrors(prevState => {
          return {...prevState, [key]: 'Required'}
        });
        pass = false;
      }
    });

    if (payload.name) {
      let splitted = payload.name.split(' ');
      if (splitted.length != 2) {
        setErrors(prevState => {
          return {...prevState, name: 'Invalid Format: eg: Magori Kihore'}
        });
        pass = false;
      }
    }

    if (payload.email) {
      var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!payload.email.match(validRegex)) {
        setErrors(prevState => {
          return {...prevState, email: 'Invalid Email'}
        });
        pass = false;
      }
    }


    return pass;
  }

  return (
    <div>
        <h3 className='text-center my-3'>{user ? 'Update' : 'Create'} User</h3>
        <Input name="name" label="Name" placeholder="Full Name"  leadingIcon={IconUser} onChange={handleChange} value={payload.name} error={errors.name} />
        <div className="my-2"></div>
        <Input name="email" label="Email" placeholder="example@datavision.co.tz" leadingIcon={IconMessage} onChange={handleChange} value={payload.email} error={errors.email} />
        <div className="my-2"></div>
        <Input type="phone" name="phone_no" label="Phone" placeholder="+255XXXXXXXXX" leadingIcon={IconPhone} onChange={handleChange} value={payload.phone_no} error={errors.phone_no} />
        <div className="my-2"></div>

        <div className="d-grid my-4">
            <button className="btn btn-primary" onClick={handleSave} disabled={isCreating || isUpdating}>Save {(isCreating || isUpdating) && <span className='spinner-border spinner-border-sm ml-2'></span>}</button>
        </div>
    </div>
  )
}

export default UserFormModal;