import React, { useEffect } from 'react'
import { Header } from '../header'
import { RootModal } from '../modal';
import { SideMenu } from '../sidemenu'
import * as styles from './index.module.scss';
import { useSelector, useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { selectorAppNetworkIsUnauthorized } from '../../../../application/features/app';
import { logout } from '../../../../application/features/authentication';
import { hideModal, showModal } from '../../../../application/features/modal';

export const AppLayout = (props) => {
  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const token = useSelector(state => state.authentication.token);
  const isUnAuthorized = useSelector(state => selectorAppNetworkIsUnauthorized(state));
  const offline = useSelector(state => state.app.network.offline);
  const loader = useSelector(state => state.app.loader);

  // effect
  useEffect(() => {
    if (token) return;
    navigate("/");
  }, [token])

  // effect
  useEffect(() => {
    if (!isUnAuthorized) return;
    dispatch(logout());
  }, [isUnAuthorized]);

  // logout the user when is idle for 15 minutes
  useEffect(() => {
    if (!token) return;

    let timeoutId;
    let warningTimeoutId;
    const TIMEOUT = 6 * 60 * 1000; // 15 minutes
    const WARNING = 5 * 60 * 1000; // 5 minutes

    const resetTimeout = () => {

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (warningTimeoutId) {
        clearTimeout(warningTimeoutId);
      }

      warningTimeoutId = setTimeout(() => {
        // warn the user
        dispatch(showModal('ALERT_MODAL', {type: 'warning', title: 'Warning', message: "You will be logged out in 5 minutes due to inactivity."}));
      }, WARNING);

      timeoutId = setTimeout(() => {
        dispatch(hideModal());
        dispatch(logout());
      }, TIMEOUT);
    }

    document.addEventListener("mousemove", resetTimeout);
    document.addEventListener("mousedown", resetTimeout);
    document.addEventListener("keypress", resetTimeout);
    document.addEventListener("touchmove", resetTimeout);
    
    return () => {
      document.removeEventListener("mousemove", resetTimeout);
      document.removeEventListener("mousedown", resetTimeout);
      document.removeEventListener("keypress", resetTimeout);
      document.removeEventListener("touchmove", resetTimeout);

      if (timeoutId) {
        clearTimeout(timeoutId);
      }

      if (warningTimeoutId) {
        clearTimeout(warningTimeoutId);
      }
    }
  }, [token]);
  

  if (!token) {
    return <p>Unauthorized</p>
  }

  return (
    <div className={styles.layout}>
        <div className={styles.sidemenu}>
            <SideMenu />
        </div>
        <div className={styles.content}>
            <div className={styles.header}>
                <Header />
                {offline && (
                  <div className={styles.offline}>
                    <div className="container">
                      <div className="col">
                        <p className='py-1'>Ooops! No internet connection.</p>
                      </div>
                    </div>
                  </div>
                )}
            </div>

            <div className={styles.body}>
              <div className={styles.loader} style={{ display: loader.isLoading ? 'flex' : 'none' }}>
                      <span className="spinner-border spinner-border-lg"></span>
                      <p className='text-center mt-3'>{loader.message}</p>
              </div>
              {props.children}
              
            </div>
            <div className="d-flex justify-content-between align-items-center text-black-50 small px-3 py-3" style={{ backgroundColor: '#EAEAEA' }}>
                <div>M-Lipa v2.0</div>
                <div>Copyright 2023 &copy; Software Galaxy</div>
              </div>
        </div>
    </div>
  )
}
