.modal {
    position: fixed;
    top: 0;
    left: 0;

    height: 100vh;
    width: 100vw;

    background-color: rgba($color: #000000, $alpha: 0.45);
    z-index: 20;

    display: flex;
    justify-content: center;
    align-items: center;


    .dialog {
        display: block;
        width: 450px;
        background-color: white;
        padding: 40px;
        border-radius: 23px;

        &.large {
            width: 900px;
        }

        .header {
            display: flex;

            .closeBtn {
                height: 48px;
                width: 48px;
                background-color: #E0E5EB;
                border: none;
                border-radius: 50px;
                margin-left: auto;

                span {
                    color: #647D9B;
                }
            }

            .backBtn {
                height: 48px;
                width: 48px;
                background-color: #E0E5EB;
                border: none;
                border-radius: 50px;
                color: #647D9B;
            }
        }
    }

    h3 {
        font-size: 28px;
        font-weight: 700;
    }
}