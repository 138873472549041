import React from 'react'
import { AppLayout } from '../../components/layout/app/index';
import ListHeaderComponent from '../../components/list/header';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { getRoles } from '../../../application/features/authentication';
import { IconView } from '../../components/icons/view';

export const RolesPage = () => {

    // redux
    const dispatch = useDispatch();
    const roles = useSelector(state => state.authentication.roles);

    // effects
    useEffect(() => {
        dispatch(getRoles());
    }, []);

  return (
    <AppLayout>
        <div className="container">
            <ListHeaderComponent title="Roles" hideSearch={true} />

            <div className="row mt-5">
                <div className="card">
                    <div className="card-body">
                        <table className='table'>
                            <thead>
                                <tr>
                                    <th>Role</th>
                                    <th>Permissions</th>
                                </tr>
                            </thead>

                            <tbody>
                                {roles.map(role => (
                                    <tr key={role.id}>
                                        <th width="300px" style={{ whiteSpace: 'nowrap' }}>{role.name}</th>
                                        <td>{role.permissions.map(p => p.name).join(', ')}</td>
                                        {/* <td>
                                            <button className="btn btn-sm btn-outline-success"><IconView height="15px" width="15px" /></button>
                                        </td> */}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    </AppLayout>
  )
}
