import React, { useEffect, useState } from "react";
import { batch, useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AppLayout } from "../../../components/layout/app";
import { operators } from "../../../../assets/contants/operators";
import { hideModal, showModal } from "../../../../application/features/modal";
import {
  MODAL_ALERT,
  MODAL_CONFIRM_PASSWORD,
} from "../../../components/layout/modal/index";
import {
  disbursementFinalize,
  disbursementGetBatches,
  disbursementUpdateStatus,
  disbursementReject,
  disbursementDeleteTransaction,
} from "../../../../application/features/disbursement";
import ListHeaderComponent from "../../../components/list/header";
import { numberWithCommas } from "../../../../core/helpers";
import { updateLoader } from "../../../../application/features/app";
import { IconDelete } from '../../../components/icons/delete';
import { dashboardGetAccountBalance } from '../../../../application/features/dashboard';
import { IconCheck } from '../../../components/icons/check';
import { IconCross } from '../../../components/icons/cross';
import ExportExcelButton from "../../../components/widgets/export/excel";
import ExportPdfButton from "../../../components/widgets/export/pdf";
import { useCan } from '../../../hooks/can';
import { PERMISSION_APPROVE_DISBURSEMENT } from '../../../../core/authorization';

export const ViewBatchPage = () => {
  // router
  const { batch_id } = useParams();

  // state
  const [mounted, setMounted] = useState(false);
  const [batch, setBatch] = useState(null);
  const [selected, setSelected] = useState([]);
  const [currentDeleting, setCurrentDeleting] = useState(null);

  // redux
  const dispatch = useDispatch();
  const permissions = useSelector((state) => state.authentication.permissions);
  const batches = useSelector((state) => state.disbursement.batches);
  const getBatches = useSelector((state) => state.disbursement.getBatches);
  const updateStatus = useSelector((state) => state.disbursement.updateStatus);
  const finalize = useSelector((state) => state.disbursement.finalize);
  const reject = useSelector((state) => state.disbursement.reject);
  const deleteTransaction = useSelector((state) => state.disbursement.deleteTransaction);
  const disburseBalance = useSelector(
    (state) => state.dashboard.balance.data.disburse
  );

  // hooks
  const canApproveDisbursement = useCan(PERMISSION_APPROVE_DISBURSEMENT);

  // effects
  useEffect(() => {
    setMounted(true);
    if (!disburseBalance) {
      dispatch(dashboardGetAccountBalance());
    }
  }, []);

  useEffect(() => {
    let _batch = batches.find((b) => b.batch_id == batch_id);
    if (_batch) {
      setBatch(_batch);
    } else {
      dispatch(disbursementGetBatches());
    }
  }, [batch_id]);

  useEffect(() => {
    if (!mounted) return;
    if (getBatches.isSuccessfull) {
      let _batch = batches.find((b) => b.batch_id == batch_id);
      setBatch(_batch);
    }
  }, [getBatches]);

  useEffect(() => {
    if (!mounted) return;
    let _batch = batches.find((b) => b.batch_id == batch_id);
    setBatch(_batch);
  }, [batches]);

  useEffect(() => {
    if (!mounted) return;
    if (finalize.isSuccessfull) {
      dispatch(disbursementGetBatches());
      setTimeout(() => {
        dispatch(
          showModal(MODAL_ALERT, {
            title: "Success",
            message: "Transactions Processed and Sent to the selected users",
            hideAfterMs: 3000,
          })
        );
      }, 1800);
      setSelected([]);
    }
  }, [finalize]);

  useEffect(() => {
    if (!mounted) return;
    if (reject.isSuccessfull) {
      dispatch(disbursementGetBatches());
      setTimeout(() => {
        dispatch(
          showModal(MODAL_ALERT, {
            title: "Success",
            message: "Transactions rejected successfully",
            hideAfterMs: 3000
          })
        );
      }, 1800);
      setSelected([]);
    }
  }, [reject]);

  useEffect(() => {
    if (updateStatus.isLoading) {
      dispatch(
        updateLoader({
          isLoading: true,
          message: "Please wait, Approval is on progress...",
        })
      );
    } else {
      setTimeout(() => {
        dispatch(
          updateLoader({
            isLoading: false,
          })
        );
      }, 1800);
    }
  }, [updateStatus.isLoading]);

  useEffect(() => {
    if (finalize.isLoading) {
      dispatch(
        updateLoader({
          isLoading: true,
          message: "Please wait, Transactions on progress...",
        })
      );
    } else {
      setTimeout(() => {
        dispatch(
          updateLoader({
            isLoading: false,
          })
        );
      }, 1800);
    }
  }, [finalize.isLoading]);

  useEffect(() => {
    if (reject.isLoading) {
      dispatch(
        updateLoader({
          isLoading: true,
          message: "Rejection is on progress...",
        })
      );
    } else {
      setTimeout(() => {
        dispatch(
          updateLoader({
            isLoading: false,
          })
        );
      }, 1800);
    }
  }, [reject.isLoading]);

  useEffect(() => {
    if (!mounted) return;
    if (deleteTransaction.isSuccessfull) {
      dispatch(
        showModal(MODAL_ALERT, {
          title: "Success",
          message: "Transaction Removed",
          hideAfterMs: 3000,
        })
      );
    }
    if (deleteTransaction.error) {
      dispatch(
        showModal(MODAL_ALERT, {
          type: 'danger',
          title: "Failed",
          message: "We could not remove the selected transaction",
          hideAfterMs: 3000
        })
      );
    }
  }, [deleteTransaction]);

  // handlers
  const handleApprove = () => {
    dispatch(
      showModal(MODAL_CONFIRM_PASSWORD, {
        description: "Enter your password so as you can approve the batch",
        action: "Approve Batch",
        onConfirm: approveBatch,
      })
    );
  };

  const approveBatch = () => {
    dispatch(hideModal());
    dispatch(
      disbursementUpdateStatus({
        id: batch.id,
        payload: {
          is_submitted: 1,
          approved: 1,
        },
      })
    );
  };

  const handleSelect = (item) => {
    let _selected = [...selected];
    if (_selected.includes(item)) {
      _selected = selected.filter((i) => i != item);
    } else {
      _selected.push(item);
    }
    setSelected(_selected);
  };

  const handleFinalize = () => {
    const data = {
      payload: {
        disburse_list: selected.map((item) => item.id),
        batch_no: batch.batch_id,
      },
    };
    dispatch(disbursementFinalize(data));
  };

  const handleReject = () => {
    const data = {
      payload: {
        disburse_list: selected.map((item) => item.id),
        batch_no: batch.batch_id,
      },
    };
    dispatch(disbursementReject(data));
  };

  const handleDeleteTransaction = (id) => {
    const data = {id: id, payload: { batch_id: batch_id }};
    setCurrentDeleting(id);
    dispatch(disbursementDeleteTransaction(data));
  }

  if (!batch) {
    return (
      <AppLayout>
        <div className="container">
          <div className="row">
            <div className="col">
              <h1>The Batch with ID {batch_id} was not found.</h1>
              {getBatches.isLoading ? (
                <p>Wait, We are searching the archives...</p>
              ) : null}
            </div>
          </div>
        </div>
      </AppLayout>
    );
  }
  return (
    <AppLayout>
      <div className="container">
        <ListHeaderComponent title={`Batch Details`} hideSearch={true} />
        <div className="row mt-3">
          <div className="col">

            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-3">
                    <h6 style={{ fontSize: 15, fontWeight: 900, fontFamily: 'Gilroy' }}>Batch Name</h6>
                    <p style={{ fontSize: 18 }}>{batch.name}</p>
                  </div>
                  <div className="col-lg-3">
                    <h6 style={{ fontSize: 15, fontWeight: 900, fontFamily: 'Gilroy' }}>Batch No</h6>
                    <p style={{ fontSize: 18 }}>{batch.batch_id}</p>
                  </div>
                  {/* <div className="col-lg-3">
                                        <h6 style={{ fontSize: 15, fontWeight: 900, fontFamily: 'Gilroy' }}>Account</h6>
                                        <p style={{ fontSize: 18 }}>{batch.account}</p>
                                    </div> */}
                  <div className="col-lg-3">
                    <h6 style={{ fontSize: 15, fontWeight: 900, fontFamily: 'Gilroy' }}>Date</h6>
                    <p style={{ fontSize: 18 }}>{batch.createdon}</p>
                  </div>
                </div>

                {/* <div className="row mt-3"> */}
                {/* <div className="col-lg-3">
                                        <h6 style={{ fontSize: 12 }}>Disbursement Balance</h6>
                                        <p style={{ fontSize: 18 }}>{numberWithCommas(disburseBalance ?? 0)} TZS</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <h6 style={{ fontSize: 12 }}>Total Amount + Charges</h6>
                                        <p style={{ fontSize: 18 }}>{numberWithCommas(batch.transactions.reduce((carry, obj) => carry + obj.amount + obj.charge, 0))} TZS</p>
                                    </div>
                                    <div className="col-lg-3">
                                        <h6 style={{ fontSize: 12 }}>Paid Amount + Charges</h6>
                                        <p style={{ fontSize: 18 }}>{numberWithCommas(batch.transactions.filter(t => t.status == 'PAID').reduce((carry, obj) => carry + obj.amount + obj.charge, 0))} TZS</p>
                                    </div> */}
                {/* <div className="col-lg-3">
                                        <h6 style={{ fontSize: 12 }}></h6>
                                        <p style={{ fontSize: 18 }}>{batch.createdon}</p>
                                    </div> */}
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-3">
          <div className="col">
            {!batch.approved ? (
              <div className="d-flex justify-content-end mb-2">
                <button className="btn btn-primary">Import New Transaction</button>
                <div className="px-1"></div>
                <button className="btn btn-primary">Add New Transaction</button>
              </div>
            ): null}
            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-end mb-2">
                    <ExportExcelButton />
                    <div className="px-1"></div>
                    <ExportPdfButton />
                </div>
                <table className="table">
                  <thead>
                    <tr>
                      <th>#</th>
                      <th>Name</th>
                      <th>Operator</th>
                      <th>Mobile</th>
                      <th>Status</th>
                      <th>Initiator</th>
                      <th>
                        <div className="d-flex">
                          <div className="flex-grow-1"></div>
                          <span className="text-right">Amount (TZS)</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex">
                          <div className="flex-grow-1"></div>
                          <span className="text-right">Charges (TZS)</span>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </thead>

                  <tbody>
                    {batch.transactions.map((t, i) => {
                      return (
                        <tr>
                          <td>{i + 1}</td>
                          <td>{t.name}</td>
                          <td>{operators[t.mobile_operator]}</td>
                          <td>{t.mobile}</td>
                          <td>{t.status}</td>
                          <td>Magori Kihore</td>
                          <td>
                            <div className="d-flex">
                              <div className="flex-grow-1"></div>
                              <span className="text-right">
                                {" "}
                                {numberWithCommas(t.amount)}
                              </span>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex">
                              <div className="flex-grow-1"></div>
                              <span className="text-right">
                                {numberWithCommas(t.charge)}
                              </span>
                            </div>
                          </td>
                          <td>
                            {batch.approved && t.status == "PENDING" ? (
                              <input
                                onChange={() => handleSelect(t)}
                                type="checkbox"
                              />
                            ) : null}
                            {!batch.approved ? (
                              <button class="btn btn-outline-danger btn-sm" onClick={() => handleDeleteTransaction(t.id)} disabled={deleteTransaction.isLoading && (t.id == currentDeleting)}>{deleteTransaction.isLoading && (t.id == currentDeleting) ? <span className="spinner-border spinner-border-sm"></span>: <IconDelete height="10px" width="10px" />}</button>
                            ) : null}
                            {t.status == "PAID" ? (
                              <span className="text-success"><IconCheck height="15px" width="15px" /></span>
                            ) : null}
                            {t.status == "REJECTED" ? (
                              <span className="text-danger"><IconCross height="15px" width="15px" /></span>
                            ) : null}
                          </td>
                        </tr>
                      );
                    })}
                  </tbody>

                  <tfoot>
                    <tr>
                      <th colSpan="5"></th>
                      <th>
                        <div className="d-flex">
                          <div className="flex-grow-1"></div>
                          <span>TOTAL</span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex">
                          <div className="flex-grow-1"></div>
                          <span>
                            {numberWithCommas(
                              batch.transactions.reduce(
                                (carry, obj) => carry + obj.amount,
                                0
                              )
                            )}
                          </span>
                        </div>
                      </th>
                      <th>
                        <div className="d-flex">
                          <div className="flex-grow-1"></div>
                          <span>
                            {numberWithCommas(
                              batch.transactions.reduce(
                                (carry, obj) => carry + obj.charge,
                                0
                              )
                            )}
                          </span>
                        </div>
                      </th>
                      <th></th>
                    </tr>
                  </tfoot>
                </table>

                {batch.approved ? (
                  <div className="d-flex">
                    <div className="flex-grow-1"></div>
                      <div>
                          <table className="table">
                              <thead>
                                  <th valign="middle" className="text-end">Total Amount + Charges Selected</th>
                                  <th valign="middle" width="200px">
                                      <div className="d-flex">
                                          <div className="flex-grow-1"></div>
                                          <span>{numberWithCommas(selected.reduce((carry, obj) => carry + obj.amount + obj.charge, 0))}</span>
                                      </div>
                                  </th>
                                  <td></td>
                              </thead>
                              <thead>
                                  <th valign="middle" className="text-end">Current Disburse Balance</th>
                                  <th valign="middle" width="200px">
                                      <div className="d-flex">
                                          <div className="flex-grow-1"></div>
                                          <span>{numberWithCommas(disburseBalance ?? 0.00)}</span>
                                      </div>
                                  </th>
                                  <td></td>
                              </thead>
                          </table>
                      </div>
                  </div>
                ) : null}
              </div>
            </div>

            <div className="d-flex mt-3">
              <div className="flex-grow-1"></div>
              {batch.approved ? (
                batch.transactions.some((t) => t.status == "PENDING") ? (
                  <div className="d-flex">
                    <button
                      onClick={handleFinalize}
                      disabled={selected.length == 0}
                      className="btn btn-primary"
                    >
                      Finalize{" "}
                      {finalize.isLoading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : null}
                    </button>
                    <div className="px-1"></div>
                    <button
                      onClick={handleReject}
                      disabled={selected.length == 0}
                      className="btn btn-danger"
                    >
                      Reject{" "}
                      {reject.isLoading ? (
                        <span className="spinner-border spinner-border-sm"></span>
                      ) : null}
                    </button>
                  </div>
                ) : null
              ) : canApproveDisbursement ? (
                <button onClick={handleApprove} className="btn btn-success">
                  Approve and Submit{" "}
                  {updateStatus.isLoading ? (
                    <span className="spinner-border spinner-border-sm"></span>
                  ) : null}
                </button>
              ) : (
                <div className="text-black-50">This batch is waiting for Approval</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLayout>
  );
};
