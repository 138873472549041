.logo {
    height: 60px;
    width: 60px;
    border: solid 1px #E0E5EB;
    border-radius: 15px;
    
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        height: 30px;
        width: 30px;
        object-fit: cover;
        object-position: center;
    }
}