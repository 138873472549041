
import React, { useState } from 'react'
import * as styles from './index.module.scss';
import { IconMoreVertical } from '../icons/more-vertical';
import { PropTypes } from 'prop-types';

const DropdownButton = (props) => {
    // props
    const { options, onOptionSelected, show } = props;

    // handlers
    const handleDropdownClicked = (option) => {
        if (!onOptionSelected) return;
        onOptionSelected(option);
    }

    return (
        <div className={styles.dropdown}>
            <IconMoreVertical height="22px" />
            <ul className={styles.menu} style={{ display: show ? 'block' : 'none' }}>
                {options.map((option, i) => {
                    return <li onClick={() => handleDropdownClicked(option)}>{option.label}</li>;
                })}
            </ul>
        </div>
    );
}

DropdownButton.defaultProps = {
    show: false,
    options: [],
}

DropdownButton.propTypes = {
    show: PropTypes.bool,
    options: PropTypes.array,
    onOptionSelected: PropTypes.func,
}

export default DropdownButton;