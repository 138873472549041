
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customFetch } from '../../core/request';
import MlipaApi from '../../api/mlipa/endpoints';
import moment from 'moment/moment';

// ACTION CONSTATANTS
const DASHBOARD_GET_DATA = 'dashboard/GET_DATA';
const DASHBOARD_GET_ACCOUNT_BALANCE = 'dashboard/GET_ACCOUNT_BALANCE';
const DASHBOARD_GET_STATISTICS_COLLECTION = 'dashboard/GET_STATISTICS_COLLECTION';
const DASHBOARD_GET_STATISTICS_DISBURSMENT = 'dashboard/GET_STATISTIS_DISBURSMENT';
const DASHBOARD_GET_STATISTICS_COLLECTION_AMOUNT = 'dashboard/GET_STATISTICS_COLLECTION_AMOUNT';
const DASHBOARD_GET_STATISTICS_DISBURSMENT_AMOUNT = 'dashboard/GET_STATISTIS_DISBURSMENT_AMOUNT';

// ACTIONS
export const dashboardGetAccountBalance = createAsyncThunk(
    DASHBOARD_GET_ACCOUNT_BALANCE,
    async (params, store ) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.dashboardBalance, null, token);
    }
);

export const dashboardGetStatisticsCollection = createAsyncThunk(
    DASHBOARD_GET_STATISTICS_COLLECTION,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.dashboardStatisticsCollection, null, token);
    }
);

export const dashboardGetStatisticsDisbursement = createAsyncThunk(
    DASHBOARD_GET_STATISTICS_DISBURSMENT,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.dashboardStatisticsDisbursement, null, token);
    }
)

export const dashboardGetStatisticsCollectionAmount = createAsyncThunk(
    DASHBOARD_GET_STATISTICS_COLLECTION_AMOUNT,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.dashboardStatisticsCollectionAmount, null, token);
    }
);

export const dashboardGetStatisticsDisbursementAmount = createAsyncThunk(
    DASHBOARD_GET_STATISTICS_DISBURSMENT_AMOUNT,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.dashboardStatisticsDisbursementAmount, null, token);
    }
)

export const dashboardGetData = createAsyncThunk(
    DASHBOARD_GET_DATA,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.dashboard, null, token);
    }
)

// INITIAL STATE
const initialState = {
    balance: {
        isFetching: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    collectionGraph: {
        isFetching: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    disbursementGraph: {
        isFetching: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    collectionAmountGraph: {
        isFetching: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    disbursementAmountGraph: {
        isFetching: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    details: {
        isFetching: false,
        isSuccessfull: false,
        error: null,
        data: {
            balance: {},
            monthly_summary: {},
            recent_transaction: [],
        },
    }
}

const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    extraReducers: {
        [dashboardGetAccountBalance.pending]: (state, action) => {
            state.balance.isFetching = true;
            state.balance.isSuccessfull = false;
            state.balance.error = null;
        },
        [dashboardGetAccountBalance.fulfilled]: (state, action) => {
            state.balance.isFetching = false;
            state.balance.isSuccessfull = true;
            state.balance.data = action.payload;
        },
        [dashboardGetAccountBalance.rejected]: (state, action) => {
            state.balance.isFetching = false;
            state.balance.isSuccessfull = false;
            state.balance.error = action.error;
        },
        [dashboardGetStatisticsCollection.pending]: (state, action) => {
            state.collectionGraph.isFetching = true;
            state.collectionGraph.isSuccessfull = false;
            state.collectionGraph.error = null;
        },
        [dashboardGetStatisticsCollection.fulfilled]: (state, action) => {
            state.collectionGraph.isFetching = false;
            state.collectionGraph.isSuccessfull = true;
            state.collectionGraph.data = action.payload;
        },
        [dashboardGetStatisticsCollection.rejected]: (state, action) => {
            state.collectionGraph.isFetching = false;
            state.collectionGraph.isSuccessfull = false;
            state.collectionGraph.error = action.error;
        },
        [dashboardGetStatisticsCollectionAmount.pending]: (state, action) => {
            state.collectionAmountGraph.isFetching = true;
            state.collectionAmountGraph.isSuccessfull = false;
            state.collectionAmountGraph.error = null;
        },
        [dashboardGetStatisticsCollectionAmount.fulfilled]: (state, action) => {
            state.collectionAmountGraph.isFetching = false;
            state.collectionAmountGraph.isSuccessfull = true;
            state.collectionAmountGraph.data = action.payload;
        },
        [dashboardGetStatisticsCollectionAmount.rejected]: (state, action) => {
            state.collectionAmountGraph.isFetching = false;
            state.collectionAmountGraph.isSuccessfull = false;
            state.collectionAmountGraph.error = action.error;
        },
        [dashboardGetStatisticsDisbursement.pending]: (state, action) => {
            state.disbursementGraph.isFetching = true;
            state.disbursementGraph.isSuccessfull = false;
            state.disbursementGraph.error = null;
        },
        [dashboardGetStatisticsDisbursement.fulfilled]: (state, action) => {
            state.disbursementGraph.isFetching = false;
            state.disbursementGraph.isSuccessfull = true;
            state.disbursementGraph.data = action.payload;
        },
        [dashboardGetStatisticsDisbursement.rejected]: (state, action) => {
            state.disbursementGraph.isFetching = false;
            state.disbursementGraph.isSuccessfull = false;
            state.disbursementGraph.error = action.error;
        },
        [dashboardGetStatisticsDisbursementAmount.pending]: (state, action) => {
            state.disbursementAmountGraph.isFetching = true;
            state.disbursementAmountGraph.isSuccessfull = false;
            state.disbursementAmountGraph.error = null;
        },
        [dashboardGetStatisticsDisbursementAmount.fulfilled]: (state, action) => {
            state.disbursementAmountGraph.isFetching = false;
            state.disbursementAmountGraph.isSuccessfull = true;
            state.disbursementAmountGraph.data = action.payload;
        },
        [dashboardGetStatisticsDisbursementAmount.rejected]: (state, action) => {
            state.disbursementAmountGraph.isFetching = false;
            state.disbursementAmountGraph.isSuccessfull = false;
            state.disbursementAmountGraph.error = action.error;
        },
        [dashboardGetData.pending]: (state, action) => {
            state.details.isFetching = true;
            state.details.isSuccessfull = false;
            state.details.error = null;
        },
        [dashboardGetData.fulfilled]: (state, action) => {
            state.details.isFetching = false;
            state.details.isSuccessfull = true;
            
            state.details.data = {
                ...action.payload, 
                recent_transaction: action.payload.recent_transaction.map(t => {
                    return {
                        ...t,
                        date: new Date(t.trans_date),
                    }
                })};
        },
        [dashboardGetData.rejected]: (state, action) => {
            state.details.isFetching = false;
            state.details.isSuccessfull = false;
            state.details.error = action.error;
        },
    },
});

// selectors
export const selectorDashboardBalance = (state) => state.dashboard.balance.data.balance ?? 'N/A';
export const selectorDashboardBalanceLoading = (state) => state.dashboard.balance.isFetching;

export const selectorDashboardStatisticsCollection = (state) => state.dashboard.collectionGraph.data;
export const selectorDashboardStatisticsDisbursement = (state) => state.dashboard.disbursementGraph.data;

export const selectorDashboardMonthlyContribution = (state) => state.dashboard.details.data.monthly_summary.collection;
export const selectorDashboardMonthlyDisbursement = (state) => state.dashboard.details.data.monthly_summary.disbursement;
export const selectorDashboardRecentTransactions = (state) => state.dashboard.details.data.recent_transaction;
export default dashboardSlice.reducer;