.dropdown {
    position: relative;
    display: flex;
    justify-content: flex-end;

    .menu {
        all: unset;

        position: absolute;
        z-index: 1;
        right: 0;
        top: 30px;

        background-color: white;
        border: solid 1px #E0E5EB;

        li {
            all: unset;

            display: block;
            white-space: nowrap;
            cursor: pointer;

            padding: 10px 15px;
            border-bottom: solid 1px #C1CBD7;
        
            &:hover {
                background-color: rgba($color: #000000, $alpha: 0.05);
            }
        }
    }
}