export const PERMISSION_SYSTEM_MANAGE = 'Manage System';
export const PERMISSION_DISBURSEMENT_CREATE = 'Create Disbursment';
export const PERMISSION_DISBURSEMENT_UPDATE = 'Update Disbursment';
export const PERMISSION_DISBURSEMENT_DELETE = 'Delete Disbursment';
export const PERMISSION_DISBURSEMENT_APPROVE = 'Approve Disbursment';
export const PERMISSION_SETTLEMENT_APPROVE = 'Approve Settlement';
export const PERMISSION_SETTLEMENT_CREATE = 'Create Settlement';
export const PERMISSION_SETTLEMENT_UPDATE = 'Update Settlement';
export const PERMISSION_SETTLEMENT_DELETE = 'Delete Settlement';
export const PERMISSION_ACCOUNT_MANAGE = 'Manage Account'

export const legacyPermissionsMap = {
    [PERMISSION_DISBURSEMENT_CREATE]: 'create_disbursement',
    [PERMISSION_DISBURSEMENT_APPROVE]: 'approve_disbursement',
    [PERMISSION_SETTLEMENT_CREATE]: 'settlement_create',
};