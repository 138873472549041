import React from 'react'
import Input from '../../components/form/input'
import * as styles from './index.module.scss';

export const RecieveMoneyPage = () => {
  return (
    <div className={styles.wrapper}>

                <div className={styles.card}>
                    <div className={styles.loader}>
                        <div class={styles.content}>
                            <div className="spinner-border text-light"></div>
                            <p className='mt-3'>Please Wait...</p>
                        </div>
                    </div>
                    <h3>Payment</h3>

                    <Input label="Phone" placeholder="Phone Number" />
                    <div className="py-2"></div>
                    <Input label="Amount" placeholder="Amount" />
                    <div className="py-2"></div>
                    <button className="btn btn-primary">Pay</button>
                </div>
    </div>
  )
}
