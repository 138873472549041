import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { AppLayout } from '../../components/layout/app'
import ListHeaderComponent from '../../components/list/header'
import { disbursementGetBatches } from '../../../application/features/disbursement';
import { IconMoneySend } from '../../components/icons/money-send';
import { generatePath, useNavigate } from 'react-router-dom';
import { IconMoreVertical } from '../../components/icons/more-vertical';
import ListPaginationComponent from '../../components/list/pagination';
import * as styles from './index.module.scss';
import DropdownButton from '../../components/dropdown/index';
import { showModal } from '../../../application/features/modal';
import { MODAL_SEND_MONEY_UPLOAD_EXCEL } from '../../components/layout/modal';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { IconEdit } from '../../components/icons/edit';
import { IconReuse } from '../../components/icons/reuse';
import { IconView } from '../../components/icons/view';
import { IconExcel } from '../../components/icons/excel';
import { IconPdf } from '../../components/icons/pdf';
import MlipaApi from '../../../api/mlipa/endpoints';
import ExportExcelButton from '../../components/widgets/export/excel';
import ExportPdfButton from '../../components/widgets/export/pdf';
import { useCan } from '../../hooks/can';
import { PERMISSION_SETTLEMENT_CREATE } from '../../../core/permissions';

export const SendPage = () => {
    // router
    const navigate = useNavigate();

    // state
    const [activeDropdown, setActiveDropdown] = useState(null);

    // refs
    const tableRef = useRef();

    // hooks
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'mlipa-disbursements',
        sheet: 'Disbursements'
    });

    // authorization
    const canCreateDisbursement = useCan(PERMISSION_SETTLEMENT_CREATE);

    // redux
    const dispatch = useDispatch();
    const batches = useSelector(state => state.disbursement.batches);
    const getBatchesResponse = useSelector(state => state.disbursement.getBatches.data);
    const isLoading = useSelector(state => state.disbursement.getBatches.isLoading);

    // effects
    useEffect(() => {
        dispatch(disbursementGetBatches());
    }, []);

    // handlers
    const handleHeaderAction = () => {
        navigate('/send/batch');
    }

    const handleDropdownOptionSelected = (option) => {
        setActiveDropdown(null);
        switch (option.label) {
            case 'Re Use':
                navigate('/send/batch', {state: { batch: option.batch }});
                break;
            case 'View':
                navigate(`/send/batch/${option.batch.batch_id}`);
                break;
            default:
                break;
        }
    };

    const handleBulkUpload = () => {
        dispatch(showModal(MODAL_SEND_MONEY_UPLOAD_EXCEL));
    }

    const handlePaginate = (page) => {
        const data = {
            payload: {
                page: page,
            }
        }
        dispatch(disbursementGetBatches(data));
    }
    
    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent hideSearch={true} title="Send Money" actionOnClick={canCreateDisbursement ? handleHeaderAction : null} actionTitle="Send Money" actionIcon={IconMoneySend} isLoading={isLoading} extraButton={canCreateDisbursement ? { title: 'Upload Excel', onClick: handleBulkUpload } : null}  />

                <div className="row mt-4">
                    <div className="col-lg-12">
                        <div className="card mb-3">
                            <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1"></div>
                                <ExportExcelButton endpoint={MlipaApi.exportBatches} />
                                <div className="px-1"></div>
                                <ExportPdfButton endpoint={MlipaApi.exportBatches} />
                            </div>
                            <table className="table" ref={tableRef}>
                            <thead>
                               <tr>
                                    <th>Date</th>
                                    <th>Batch Name</th>
                                    <th>Batch ID</th>
                                    <th>Submitted</th>
                                    <th>Approved</th>
                                    <th>Transactions</th>
                                    <th>Paid</th>
                                    <th>Rejected</th>
                                    <th></th>
                               </tr>
                            </thead>

                            <tbody>
                                {batches.map(batch => {
                                    return (
                                        <tr>
                                            <td class="nowrap">{batch.createdon}</td>
                                            <td>{batch.name}</td>
                                            <td>{batch.batch_id}</td>
                                            <td>{batch.is_submitted ? 'YES' : 'NO'}</td>
                                            <td>{batch.approved ? 'YES' : 'NO'}</td>
                                            <td>{batch.transactions.length}</td>
                                            <td>{batch.transactions.filter(t => t.status == 'PAID').length}</td>
                                            <td>{batch.transactions.filter(t => t.status == 'REJECTED').length}</td>
                                            <td onClick={() => activeDropdown == batch ? setActiveDropdown(null) : setActiveDropdown(batch)}>
                                                <div className="btn-group">
                                                    <button className="btn btn-outline-success btn-sm" onClick={() => handleDropdownOptionSelected({label: 'View', batch: batch})} title="View"><IconView height="15px" width="20px" /></button>
                                                    {canCreateDisbursement && <button className="btn btn-outline-primary btn-sm" onClick={() => handleDropdownOptionSelected({label: 'Re Use', batch: batch})} title="Reuse"><IconReuse height="15px" width="20px" /></button>}
                                                </div>
                                                {/* <DropdownButton show={activeDropdown == batch} onOptionSelected={handleDropdownOptionSelected} options={[{label: 'View', batch: batch}, {label: 'Re Use', batch: batch}]} /> */}
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        </table>
                            </div>
                        </div>
                        
                        <ListPaginationComponent currentPage={getBatchesResponse.current_page} lastPage={getBatchesResponse.last_page} onClick={handlePaginate} />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
