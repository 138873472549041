import React from 'react'

export const IconMoreVertical = (props) => {
  return (
    <svg viewBox="0 0 34 34" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16.9987 18.418C17.7811 18.418 18.4154 17.7837 18.4154 17.0013C18.4154 16.2189 17.7811 15.5846 16.9987 15.5846C16.2163 15.5846 15.582 16.2189 15.582 17.0013C15.582 17.7837 16.2163 18.418 16.9987 18.418Z"  strokeWidth="2.83333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.9987 8.5013C17.7811 8.5013 18.4154 7.86704 18.4154 7.08464C18.4154 6.30223 17.7811 5.66797 16.9987 5.66797C16.2163 5.66797 15.582 6.30223 15.582 7.08464C15.582 7.86704 16.2163 8.5013 16.9987 8.5013Z"  strokeWidth="2.83333" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.9987 28.3346C17.7811 28.3346 18.4154 27.7004 18.4154 26.918C18.4154 26.1356 17.7811 25.5013 16.9987 25.5013C16.2163 25.5013 15.582 26.1356 15.582 26.918C15.582 27.7004 16.2163 28.3346 16.9987 28.3346Z"  strokeWidth="2.83333" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
