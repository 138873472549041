
import { createSlice, createAsyncThunk, current } from '@reduxjs/toolkit';
import MlipaApi from '../../api/mlipa/endpoints';
import { customFetch } from '../../core/request';

// ACTION CONSTANTS 
const CUSTOMER_GET_CUSTOMERS = 'customer/GET_CUSTOMERS';
const CUSTOMER_CREATE_CUSTOMER = 'customer/CREATE_CUSTOMER';
const CUSTOMER_UPDATE_CUSTOMER = 'customer/UPDATE_CUSTOMER';

// ACTIONS
export const customerGetCustomers = createAsyncThunk(
    CUSTOMER_GET_CUSTOMERS,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.customers, data.payload, token);
    }
);

export const customerCreateCustomer = createAsyncThunk(
    CUSTOMER_CREATE_CUSTOMER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.customerCreate, data.payload, token, store);
    }
);

export const customerUpdateCustomer = createAsyncThunk(
    CUSTOMER_UPDATE_CUSTOMER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.customerUpdate(data.id), data.payload, token, store);
    }
);


// INITIAL STATE
const initialState = {
    customers: [],
    get: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    view: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    create: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    update: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    }
}

// SLICE
const customerSlice = createSlice({
    name: 'customer',
    initialState,
    extraReducers: {
        [customerGetCustomers.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessfull = false;
            state.get.error = null;
            state.get.data = {}
        },
        [customerGetCustomers.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessfull = true;
            // state.get.error = null;
            state.get.data = action.payload
            state.customers = action.payload.data;
        },
        [customerGetCustomers.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessfull = false;
            state.get.error = action.error;
            // state.get.data = {}
        },
        [customerCreateCustomer.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessfull = false;
            state.create.error = null;
            state.create.data = {};
        },
        [customerCreateCustomer.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessfull = true;
            // state.create.error = null;
            state.create.data = action.payload;
            state.customers.unshift(action.payload.data);
        },
        [customerCreateCustomer.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessfull = false;
            state.create.error = action.payload;
            // state.create.data = {};
        },
        [customerUpdateCustomer.pending]: (state, action) => {
            state.update.isLoading = true;
            state.update.isSuccessfull = false;
            state.update.error = null;
            state.update.data = {};
        },
        [customerUpdateCustomer.fulfilled]: (state, action) => {
            state.update.isLoading = false;
            state.update.isSuccessfull = true;
            // state.update.error = null;
            state.update.data = action.payload;
            const index = state.customers.findIndex(c => c.id == action.payload.id);
            if (index > -1) {
                state.customers[index] = action.payload;
            }
        },
        [customerUpdateCustomer.rejected]: (state, action) => {
            // console.debug(current(state));
            state.update.isLoading = false;
            state.update.isSuccessfull = false;
            state.update.error = action.payload;
            state.update.data = {};
        },
    },
});

export default customerSlice.reducer;
export const selectorCustomerCustomers = (state) => state.customer.customers;