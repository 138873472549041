import React from 'react'
import { PropTypes } from 'prop-types';
import { IconSettings } from '../../../components/icons/settings';
import * as styles from './index.module.scss';
import { IconChevronRight } from '../../../components/icons/chevron-right';

const SettingCardComponent = (props) => {
    const { icon: Icon, title, description, onClick } = props;

    return (
        <div className={styles.card} style={{ cursor: onClick ? 'pointer' : '' }} onClick={onClick}>
            <div className={styles.icon}>
                <Icon height="30px" width="30px" />
            </div>

            <div className={styles.details}>
                <h6>{title}</h6>
                <p>{description}</p>
            </div>

            {onClick && (
                <div className={styles.actions}>
                    <IconChevronRight height="20px" width="20px" />
                </div>
            )}
        </div>
    );
}

SettingCardComponent.defaultProps = {
    icon: IconSettings,
    title: "Setting",
    description: "Setting item description",
    onClick: () => console.log("Setting Item Clicked"),
}

SettingCardComponent.propTypes = {
    icon: PropTypes.elementType,
    title: PropTypes.string,
    description: PropTypes.description,
    onClick: PropTypes.func,
}

export default SettingCardComponent;