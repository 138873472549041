import React from 'react';
import { PageRoutes } from './ui/pages/routes';

export default class App extends React.Component{
    render() {
        return ( 
            <div>
                <PageRoutes />
            </div>
        )
    }
}
