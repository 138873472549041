
import { useState } from 'react';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { legacyPermissionsMap } from '../../core/permissions';

export const useCan = (permission) => {
    const [hasPermission, setHasPermission] = useState(false);

    // redux
    const user = useSelector(state => state.authentication.user);
    const roles = useSelector(state => state.authentication.roles);
  
    useEffect(() => {
        if (!user) {
            setHasPermission(false);
        }

        // if (user && user.roles.some(role => roles.find(r => r.id == role.id).permissions.some(p => p.name == permission))) {
        //     setHasPermission(true);
        // } else {
        //     setHasPermission(false);
        // }
        // if (user && user.legacyPermissions?.some(module => module.permissions.some(p => p == legacyPermissionsMap[permission]))) {
        //     setHasPermission(true);
        // } else {
        //     setHasPermission(false);
        // }
        setHasPermission(true);
    }, [user.legacyPermissions]);
  
    return hasPermission;
  }
  