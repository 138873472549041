import React from 'react'

export const IconWallet = (props) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M20.1673 10.9993V15.5827C20.1673 18.3327 18.334 20.166 15.584 20.166H6.41732C3.66732 20.166 1.83398 18.3327 1.83398 15.5827V10.9993C1.83398 8.50602 3.33732 6.76435 5.67482 6.47101C5.91315 6.43435 6.16065 6.41602 6.41732 6.41602H15.584C15.8223 6.41602 16.0515 6.42517 16.2715 6.46184C18.6365 6.73684 20.1673 8.48768 20.1673 10.9993Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M16.2724 6.46315C16.0524 6.42648 15.8233 6.41733 15.5849 6.41733H6.41828C6.16161 6.41733 5.91411 6.43566 5.67578 6.47233C5.80411 6.21566 5.98745 5.97733 6.20745 5.75733L9.18662 2.76898C10.4424 1.52232 12.4774 1.52232 13.7333 2.76898L15.3374 4.3915C15.9241 4.969 16.2358 5.70232 16.2724 6.46315Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.1673 11.459H17.4173C16.409 11.459 15.584 12.284 15.584 13.2923C15.584 14.3007 16.409 15.1257 17.4173 15.1257H20.1673" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
