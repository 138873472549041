import React from 'react'

export const IconSearch = (props) => {
  return (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <circle cx="8.80492" cy="8.80492" r="7.49047" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M14.0156 14.4043L16.9523 17.3334" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
