import React, { useState } from 'react'
import * as styles from './index.module.scss';
import { IconHome } from '../../../icons/home';
import { PropTypes } from 'prop-types';
import { NavLink, useLocation } from 'react-router-dom';

const SideMenuItem = (props) => {
  // props
  const { title, icon: Icon, href } = props;

  // router
  const location = useLocation();

  // state
  const [isActive, setIsActive] = useState();

  const setActiveLink = (isActive) => {
    setTimeout(() => {
      setIsActive(isActive.isActive);
    }, 10);
    return isActive.isActive ? `${styles.item} ${styles.active}` : styles.item;
  }

  return (
    <NavLink className={isActive => setActiveLink(isActive)} exact="true" to={href}>
        <div className={styles.content}>
          <div className={styles.icon}>
              <Icon color={isActive ? 'white' : '#8397AF'} />
          </div>

          <div className={styles.title}>
              {title}
          </div>
        </div>
    </NavLink>
  );
}

SideMenuItem.defaultProps = {
  title: "Title",
  icon: IconHome,
  href: "#"
}

SideMenuItem.propTypes = {
  title: PropTypes.string,
  icon: PropTypes.elementType,
  href: PropTypes.string,
}

export default SideMenuItem;