.insight {
    border-radius: 16px;
    padding: 16px;

    .content {
        display: flex;

        .info {
            flex: 1;

            display: flex;
            flex-direction: column;

            h6 {
                font-size: 18px;
                font-weight: 500;
                color: #303030;
                opacity: 0.8;
            }
            
            h1 {
                font-size: 48px;
                font-weight: 600;
            }
        }

        .icon {
            background-color: white;
            border-radius: 10px;

            padding: 24px 16px;

            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}