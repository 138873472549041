import React, { useEffect, useState } from 'react'
import Input from '../../form/input';
import { IconUser } from '../../icons/user';
import { IconMessage } from '../../icons/message';
import { IconPhone } from '../../icons/phone';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_ALERT } from '../../layout/modal';
import { customerCreateCustomer, customerUpdateCustomer } from '../../../../application/features/customer';
import * as Yup from "yup";

const CustomerFormModal = (props) => {
  // props
  const { customer } = props;

  // state
  const [mounted, setMounted] = useState(false);
  const [payload, setPayload] = useState({name: null, phone_no: null, email: null});
  const [errors, setErrors] = useState({name: null, phone_no: null, email: null});

  // redux
  const dispatch = useDispatch();
  const isCreating = useSelector(state => state.customer.create.isLoading);
  const isUpdating = useSelector(state => state.customer.update.isLoading);
  
  const isCreated = useSelector(state => state.customer.create.isSuccessfull);
  const isUpdated = useSelector(state => state.customer.update.isSuccessfull);

  const creatingError = useSelector(state => state.customer.create.error);
  const updatingError = useSelector(state => state.customer.update.error);

  // effects
  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!customer) return;
    setPayload({
      name: `${customer.first_name} ${customer.last_name}`,
      phone_no: customer.phone_no,
      email: customer.email,
    });
  }, [customer]);

  useEffect(() => {
    if (!mounted) return;
    if (!isCreated) return;
    dispatch(showModal(MODAL_ALERT, {title: 'Success', message: `You have successfully added ${payload.name} to your contacts`}));
  }, [isCreated]);

  useEffect(() => {
    if (!mounted) return;
    if (!isUpdated) return;
    dispatch(showModal(MODAL_ALERT, {title: 'Success', message:  `You have successfully updated ${payload.name} from your contacts`}));
  }, [isUpdated]);

  useEffect(() => {
    if (!creatingError) return;
    if (!creatingError.errors) return;
    Object.keys(payload).map(key => {
      if (creatingError.errors[key]) {
        setErrors(prevState => {
          return {
            ...prevState,
            [key]: creatingError.errors[key][0],
          }
        });
      }
    });
  }, [creatingError]);

  useEffect(() => {
    if (!updatingError) return;
    // if (!updatingError.errors) return;
    Object.keys(payload).map(key => {
      if (updatingError[key]) {
        setErrors(prevState => {
          return {
            ...prevState,
            [key]: updatingError[key][0],
          }
        });
      }
    });
  }, [updatingError]);

  // handlers
  const handleSave = () => {
    if (!validate()) return;
    if (!customer) {
      dispatch(customerCreateCustomer({payload}));
    } else {
      let splitted = payload.name.split(' ');
      const data = {
        id: customer.id,
        payload: {
          ...payload,
          first_name: splitted[0],
          last_name: splitted[1],
        },
      }
      dispatch(customerUpdateCustomer(data));
    }
  }

  const handleChange = (name, value) => {
    setPayload({...payload, [name]: value});

    if (errors[name]) {
      setErrors(prevState => {
        return {...prevState, [name]: null};
      });
    }
  }

  // functions
  const validate = () => {
    let pass = true;
    Object.keys(payload).map(key => {
      if (!payload[key]) {
        setErrors(prevState => {
          return {...prevState, [key]: 'Required'}
        });
        pass = false;
      }
    });

    if (payload.name) {
      let splitted = payload.name.split(' ');
      if (splitted.length != 2) {
        setErrors(prevState => {
          return {...prevState, name: 'Invalid Format: eg: Magori Kihore'}
        });
        pass = false;
      }
    }

    if (payload.email) {
      var validRegex = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;

      if (!payload.email.match(validRegex)) {
        setErrors(prevState => {
          return {...prevState, email: 'Invalid Email'}
        });
        pass = false;
      }
    }


    return pass;
  }

  return (
    <div>
        <h3 className='text-center my-3'>Customer Form</h3>
        <Input name="name" label="Name" placeholder="Full Name"  leadingIcon={IconUser} onChange={handleChange} value={payload.name} error={errors.name} />
        <div className="my-2"></div>
        <Input name="email" label="Email" placeholder="example@datavision.co.tz" leadingIcon={IconMessage} onChange={handleChange} value={payload.email} error={errors.email} />
        <div className="my-2"></div>
        <Input type="phone" name="phone_no" label="Phone" placeholder="+255XXXXXXXXX" leadingIcon={IconPhone} onChange={handleChange} value={payload.phone_no} error={errors.phone_no} />
        <div className="my-2"></div>

        <div className="d-grid my-4">
            <button className="btn btn-primary" onClick={handleSave} disabled={isCreating || isUpdating}>Save {(isCreating || isUpdating) && <span className='spinner-border spinner-border-sm ml-2'></span>}</button>
        </div>
    </div>
  )
}

export default CustomerFormModal;