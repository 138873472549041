import React from 'react'

export const IconBackArrow = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <g opacity="0.8">
        <path d="M20 12.2754C20 12.6551 19.7178 12.9689 19.3518 13.0185L19.25 13.0254L4.25 13.0254C3.83579 13.0254 3.5 12.6896 3.5 12.2754C3.5 11.8957 3.78215 11.5819 4.14823 11.5322L4.25 11.5254L19.25 11.5254C19.6642 11.5254 20 11.8612 20 12.2754Z" />
        <path d="M10.828 17.7675C11.1215 18.0598 11.1226 18.5347 10.8303 18.8282C10.5646 19.095 10.148 19.1201 9.85392 18.9029L9.76964 18.8305L3.71964 12.8065C3.452 12.54 3.42766 12.1219 3.64661 11.8278L3.7196 11.7436L9.7696 5.71857C10.0631 5.42629 10.538 5.42727 10.8303 5.72077C11.096 5.98759 11.1193 6.4043 10.9009 6.69746L10.8281 6.78143L5.31203 12.2753L10.828 17.7675Z" />
        </g>
    </svg>
  )
}
