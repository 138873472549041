import React, { useEffect, useState } from 'react'
import Input from '../../form/input';
import { IconLock } from '../../icons/lock';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_ALERT, MODAL_CONFIRM_PASSWORD, MODAL_RECEIPT, MODAL_SEND_MONEY_MOBILE_INFO } from '../../layout/modal/index';
import { authConfirmPassword } from '../../../../application/features/authentication';
import * as styles from './index.module.scss';

export const ConfirmPasswordModal = (props) => {
    // props
    const { title, description, action, onConfirm } = props;

    // state
    const [mounted, setMounted] = useState(false);
    const [password, setPassword] = useState('');
    const [errorMessage, setErrorMessage] = useState('');

    // redux
    const dispatch = useDispatch();
    const confirmPassword = useSelector(state => state.authentication.confirmPassword);

    // effects
    useEffect(() => setMounted(true), []);

    useEffect(() => {
        if (errorMessage) {
            setErrorMessage('');
        }
    }, [password]);

    useEffect(() => {
        if (!mounted) return;
        if (confirmPassword.isSuccessful) {
            if (onConfirm) {
                onConfirm();
            }
        }
        if (confirmPassword.error) {
            setErrorMessage('Incorrect Password');
        }
    }, [confirmPassword]);

    // handlers
    const handleOnBackClicked = () => {
        dispatch(showModal(MODAL_CONFIRM_PASSWORD));
    }

    const handleAlertAction = () => {
        dispatch(showModal(MODAL_RECEIPT));
    }

    const handleConfirm = () => {
        dispatch(authConfirmPassword({
            password: password,
        }));
        // dispatch(showModal(MODAL_ALERT, {
        //     title: 'Transaction Complete', 
        //     message: 'Your transaction is complete, You will receive sms  for confirmation',
        //     actionLabel: 'Download Receipt',
        //     actionCallback: handleAlertAction,
        // }));
    }
    return (
        <div className={`${styles.wrapper}`}>
            {confirmPassword.isLoading && (
                <div className={styles.loader}>
                    <div className='text-center'>
                        <span className="spinner-border spinner-border-lg"></span>
                        <h5 className='text-center'>Please Wait...</h5>
                        <p className='text-center'>We are confirming its you.</p>
                    </div>
                </div>
            )}
            <h3 className='text-center'>{title ?? 'Confirm Password'}</h3>
            <p className='text-center'>{description ?? 'Input your password for security reason so we can confirm this action.'}</p>

            <div className="mt-4"></div>
            <Input type="password" name="password" label="Password" leadingIcon={IconLock} placeholder="Password" error={errorMessage} onChange={(name, value) => setPassword(value)} />
            <div className="mt-3"></div>
            <div className="d-grid">
                <button className="btn btn-primary btn-lg" disabled={confirmPassword.isLoading} onClick={handleConfirm}>{action ?? 'Confirm Password'}</button>
            </div>
        </div>
    );
}
