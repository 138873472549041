.list {
    .item {
        display: flex;
        border-bottom: solid 1px #E0E5EB;

        font-size: 16;
        font-weight: 500;
        padding-bottom: 10px;
        margin-bottom: 10px;

        .label {
            color: #636778;
            flex: auto;
        }

        .value {
            color: #303030;
        }
    }
}