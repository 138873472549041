@import "$assets/scss/main.scss";

.balance {
    background-color: $primary-color;
    padding: 16px 24px;
    border-radius: 16px;
    color: white;

    .label {
        font-size: 14px;
        margin-bottom: 10px;
    }

    .actions {
        display: flex;
        justify-content: flex-end;

        margin-bottom: 36px;
    }

    .flex {
        display: flex;

        div:first-child {
            flex: 1;
        }

        div {
            h6 {
                font-size: 10px;
            }
        }
    }
}