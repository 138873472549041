@import '$assets/scss/main.scss';

.option {
    height: 230px;
    background-color: #F0F0F0;
    border-radius: 45px;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    cursor: pointer;

    &.active {
        background-color: $primary-color;

        
        h6 {
            color: white;
        }
        
    }

    .icon {
        background-color: white;
        border-radius: 81px;
        width: 63px;
        height: 63px;

        display: flex;
        align-items: center;
        justify-content: center;
    }

    h6 {
        margin-top: 15px;
        font-size: 24px;
        font-weight: 700;
        color: #303030;
    }
}