import React from 'react'

export const IconNotification = (props) => {
  return (
    <svg viewBox="0 0 32 32" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M15.9993 23.7982C23.5183 23.7982 26.9968 22.8336 27.3327 18.962C27.3327 15.093 24.9076 15.3418 24.9076 10.5948C24.9076 6.88682 21.393 2.66797 15.9993 2.66797C10.6057 2.66797 7.09115 6.88682 7.09115 10.5948C7.09115 15.3418 4.66602 15.093 4.66602 18.962C5.00329 22.8482 8.48171 23.7982 15.9993 23.7982Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.1838 27.8125C17.365 29.8321 14.5277 29.8561 12.6914 27.8125"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
