import React, { useEffect, useState } from 'react'
import Input from '../../form/input'
import { IconSearch } from '../../icons/search'
import { createRandomTransaction } from '../../../../api/faker/factories';
import * as styles from './index.module.scss';
import { numberWithCommas } from '../../../../core/helpers';
import { useSelector } from 'react-redux';
import { selectorDashboardRecentTransactions } from '../../../../application/features/dashboard';
import { faker } from '@faker-js/faker';

const months = ['Jan', 'Feb', "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

export const RecentTransactionsWidget = () => {
    // // state
    // const [transactions, setTransactoins] = useState([]);

    //state
    const [q, setQ] = useState("");

    // redux
    const transactions = useSelector(state => selectorDashboardRecentTransactions(state));

    // effect
    // useEffect(() => {
    //     let _transactions = [];
    //     for (var i = 0; i < 3; i++) {
    //         _transactions.push(createRandomTransaction());
    //     }
    //     setTransactoins(_transactions);
    // }, [])

    return (
        <div className="card">
            <div className="card-body">
                <h3 className="card-title">Recent Transactions</h3>

                <Input leadingIcon={IconSearch} placeholder="Search by phone number" onChange={(name, v) => setQ(v)} leadingIconProps={{ color: '#04619E', }} />

                <div className="mt-3">
                    {transactions.filter(t => q ? 
                        ((t.msisdn && t.msisdn.toLowerCase().includes(q)) || 
                        (t.reference && t.reference.toLowerCase().includes(q)) || 
                        (t.type && t.type.toLowerCase().includes(q)) || 
                        (t.credit && t.credit.toString().toLowerCase().includes(q)) || 
                        (t.debit && t.debit.toString().toLowerCase().includes(q))) : true).slice(0,5).map(transaction => (
                        <div className={styles.transaction} key={transaction.phone}>
                            {/* <div className={styles.avatar}>
                                <img src={faker.image.avatar()} alt="" />
                            </div> */}
                            <div className={styles.content}>
                                <span className={styles.phone}>{transaction.msisdn ? transaction.msisdn : transaction.reference}</span>
                                <span className={styles.type}>{transaction.type}</span>
                            </div>
                            <div className={styles.trail}>
                                <span className={styles.amount}>{numberWithCommas(transaction.credit ? transaction.credit : transaction.debit)}</span>
                                <p>{transaction.date.getDate()} {months[transaction.date.getMonth()]}, {transaction.date.getFullYear()}</p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}
