
import { createAction, createSlice } from '@reduxjs/toolkit';

// Action Constants
const SHOW_MODAL = 'modal/showModal';

// Initial State
const initialState = {
    type: null,
    modalProps: {},
}

// Slice
const modalSlice = createSlice({
    name: "modal",
    initialState,
    reducers: {
        showModal: (state, action) => {
            return action.payload;
        },
        hideModal: () => initialState,
    },
});

// Actions
export const { hideModal } = modalSlice.actions;

export const showModal = createAction(SHOW_MODAL, function prepare(type, modalProps) {
    return {
        payload: {
            type,
            modalProps: modalProps ?? {},
        }
    }
});

export default modalSlice.reducer;