import React from 'react'

export const IconView = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill='none' stroke='currentColor' {...props}>
        <g stroke-linecap="round" stroke-width="1.5" stroke-linejoin="round">
            <path d="M12 5.251c-4.032-.07-8.2 2.74-10.822 5.63H1.17c-.58.63-.58 1.59 0 2.22 2.564 2.824 6.722 5.7 10.822 5.63 4.1.06 8.258-2.814 10.82-5.638v-.001c.57-.64.57-1.6 0-2.23 -2.63-2.89-6.8-5.71-10.83-5.64Z"></path>
            <path d="M15.74 12v0c-.01 2.07-1.68 3.75-3.75 3.75 -2.08-.01-3.75-1.68-3.75-3.75 0-2.08 1.67-3.75 3.75-3.75 0 0 0 0 0 0v0c2.07 0 3.74 1.67 3.74 3.75Z"></path>
        </g>
    </svg>
  )
}
