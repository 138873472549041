import React from 'react'
import { AppLayout } from '../../components/layout/app'

export const NotFoundPage = () => {
  return (
    <AppLayout>
      <div className="container">
        <h1>Page Not Found</h1>
      </div>
    </AppLayout>
  )
}
