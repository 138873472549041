import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { login } from '../../../application/features/authentication';
import Input from '../../components/form/input';
import { IconLock } from '../../components/icons/lock';
import { IconMessage } from '../../components/icons/message';
import * as styles from './index.module.scss';

export const RegisterPage = () => {
// props

    // router
    const navigate = useNavigate();

    // state
    const [loginPayload, setLoginPayload] = useState({});
    const [errorMessage, setErrorMessage] = useState("");

    // redux
    const dispatch = useDispatch();
    const token = useSelector(state => state.authentication.token);
    const loginState = useSelector(state => state.authentication.login);

    // effects
    useEffect(() => {
        if (!token) return;
        navigate('/dashboard');
    }, [token]);

    useEffect(() => {
        if (loginState.error) {
            setErrorMessage(loginState.error);
            setTimeout(() => {
                setErrorMessage("");
            }, 10000);
        }
    }, [loginState]);
    
    // handlers
    const handleChange = (name, value) => {
        setLoginPayload(prevState => {
            return {
                ...prevState, 
                [name]: value
            }
        });
    }

    const handleLogin = () => {
        dispatch(login(loginPayload));
    }

    const handleRegister = () => {
        navigate('/register');
    }

    return (
        <div className={styles.register}>
            <div className={`card ${styles.card}`}>
                <div className="card-body">
                    
                    <h3 className="card-title mb-5">Register</h3>
                    {errorMessage && <div class="alert alert-danger">{errorMessage}</div>}
                    <Input type="email" label="Email" name="email" placeholder="Email Address" leadingIcon={IconMessage} onChange={handleChange} />
                    <div className="my-2"></div>
                    <Input type="password" label="Password" name="password" placeholder="Password" leadingIcon={IconLock} onChange={handleChange} />
                    <div className="mb-4"></div>
                    <button className="btn btn-primary btn-lg" onClick={handleLogin}>Register {loginState.isLoading && <span className='spinner-border spinner-border-sm mx-2'></span>}</button>
                </div>
            </div>
        </div>
    );
}
