.profile {
    margin-top: 2rem;

    .avatar {
        height: 60px;
        width: 60px;
        
        display: flex;
        justify-content: center;
        align-items: center;

        font-weight: 700;
        border-radius: 15px;
    }

    .summary {
        display: flex;
        flex-direction: column;
        margin-left: 15px;
    
        .avatar {
            border-radius: 15px;
            height: 60px;
            width: 60px;
            object-fit: cover;
            object-position: center;
        }
        
        .name {
            font-size: 24px;
            font-weight: 700;
        }
    }
}

.details {
    margin-top: 45px;

    .detail {
        label {
            color: #8397AF;
            font-weight: 500;
            font-size: 14px;

            margin-bottom: 15px;
        }

        .info {
            display: flex;

            p {
                margin-left: 15px;
                color: #28323E;
                font-size: 16px;
                font-weight: 500;
            }
        }

        margin-bottom: 20px;
    }
}

