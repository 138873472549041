.wrapper {
    img {
        height: 36px;
        width: auto;
        object-fit: contain;
        object-position: center;
    }

    p {
        font-size: 14px;
        font-weight: 400;
        color: #636778;
        padding: 16px 0;
    }
}