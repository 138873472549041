import React from 'react'
import { getRandomBackgroundColor } from '../../../../core/helpers';
import * as styles from './index.module.scss';

const color = getRandomBackgroundColor();

const BankSummaryComponent = (props) => {
    const { bank } = props
    return (
        <div className={`d-flex justify-content-center`}>
            {/* <img src={bank.logo} className={styles.avatar} alt="" /> */}
            <div className={styles.avatar} style={{ backgroundColor:  color.background, color: color.text }}></div>
            <div className={styles.summary}>
                <span className={styles.name}>{bank.bank_name}</span>
                <span>{bank.account_no}</span>
            </div>
        </div>
    );
}

export default BankSummaryComponent;