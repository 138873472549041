import { faker } from '@faker-js/faker';
import city from '$assets/images/city.png';
import hsb from '$assets/images/hsbbank.png';
import usa from '$assets/images/usabank.png';
// import { faker } from '@faker-js/faker/locale/de';

export function createRandomNotification() {
  return {
    type: faker.helpers.arrayElement([{label: 'Send Money', value: 'send'}, {label: 'Receive Money', value: 'receive'}]),
    username: faker.name.fullName(),
    paymentmethod: faker.helpers.arrayElement(['M Lipa', 'Bank of America', 'CRDB', 'NMB']),
    avatar: faker.image.avatar(),
    phone: faker.phone.number(),
    date: faker.date.past(),
    amount: faker.finance.amount(100, 10000, 2, '$', true),
  }
}

export function createRandomUser() {
  return {
    userId: faker.datatype.uuid(),
    firstname: faker.name.firstName(),
    lastname: faker.name.lastName(),
    username: faker.internet.userName(),
    email: faker.internet.email(),
    phone: faker.phone.number(),
    avatar: faker.image.avatar(),
    password: faker.internet.password(),
    birthdate: faker.date.birthdate(),
    registeredAt: faker.date.past(),
  };
}

export function createRandomTransaction() {
  return {
    ref: faker.datatype.number(1000000,9999999),
    username: faker.name.fullName,
    avatar: faker.image.avatar(),
    phone: faker.phone.number(),
    date: faker.date.past(),
    amount: faker.finance.amount(100, 10000, 2, '$', true),
    status: faker.helpers.arrayElement(['Disbursment', 'Collection', 'Transfer'])
  }
}

export function getBanks() {
  return [
    {
      name: "City Bank", 
      logo: city,
      cardno: "**** **** **** 6543"
    },
    {
      name: "HSB Bank", 
      logo: hsb,
      cardno: "**** **** **** 5456"
    },
    {
      name: "USA Bank", 
      logo: usa,
      cardno: "**** **** **** 2332"
    },
  ]
}

export function getBank() {
  return  {
    name: "City Bank", 
    logo: city,
    cardno: "**** **** **** 6543"
  };
}