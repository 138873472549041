import React from 'react'

export const IconCircleError = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="currentColor" {...props}>
        <g>
            <path d="M11.95 2C6.46 2 1.99 6.48 1.99 12c0 5.51 4.48 10 10 10 5.51 0 10-4.49 10-10 0-5.52-4.51-10-10.05-10Zm.04 18c-4.42 0-8-3.59-8-8 0-4.42 3.567-8 7.95-8 4.43 0 8.047 3.58 8.047 8 0 4.41-3.59 8-8 8Z"></path>
            <path d="M11 7h2v7h-2Zm0 8h2v2h-2Z"></path>
        </g>
    </svg>
  )
}
