import { updateNetworkOffline, updateNetworkUnauthorized } from "../features/app";


export const networkMiddleware = store => next => action => {
    const state = store.getState();

    if (action.error && action.error.message) {
        console.log(action);
        if(action.error.message === 'UNAUTHORIZED') {
            store.dispatch(updateNetworkUnauthorized(true));
        }
        // if(action.payload && action.payload.interrupt) {

        //     store.dispatch(handleInterrupt(action.payload));
        // }
    }

    // TODO centralize the translation of errors to more friendly texts
    // console.log(action);

    // CHECKING NETWORK CONNECTIVITY
    if (action.payload && action.payload.network_status) {
        if (action.payload.network_status == 'OK' && state.app.network.offline) {
            store.dispatch(updateNetworkOffline(false));
        }

        if (action.payload.network_status == 'NO_INTERNET' && !state.app.network.offline) {
            store.dispatch(updateNetworkOffline(true));
        }
    }
    next(action);
}