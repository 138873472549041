import React from 'react'

export const IconLock = (props) => {
  return (
    <svg viewBox="0 0 18 20" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M13.4237 7.44804V5.30104C13.4237 2.78804 11.3857 0.750045 8.87273 0.750045C6.35973 0.739045 4.31373 2.76704 4.30273 5.28104V5.30104V7.44804" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M12.683 19.25H5.042C2.948 19.25 1.25 17.553 1.25 15.458V11.169C1.25 9.07395 2.948 7.37695 5.042 7.37695H12.683C14.777 7.37695 16.475 9.07395 16.475 11.169V15.458C16.475 17.553 14.777 19.25 12.683 19.25Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M8.86328 12.2031V14.4241" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
