import React from 'react'

export const IconPhone = (props) => {
  return (
    <svg viewBox="0 0 24 24" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15 22.75H9C4.59 22.75 3.25 21.41 3.25 17V7C3.25 2.59 4.59 1.25 9 1.25H15C19.41 1.25 20.75 2.59 20.75 7V17C20.75 21.41 19.41 22.75 15 22.75ZM9 2.75C5.42 2.75 4.75 3.43 4.75 7V17C4.75 20.57 5.42 21.25 9 21.25H15C18.58 21.25 19.25 20.57 19.25 17V7C19.25 3.43 18.58 2.75 15 2.75H9Z" />
        <path d="M14 6.25H10C9.59 6.25 9.25 5.91 9.25 5.5C9.25 5.09 9.59 4.75 10 4.75H14C14.41 4.75 14.75 5.09 14.75 5.5C14.75 5.91 14.41 6.25 14 6.25Z" />
        <path d="M11.9992 19.8598C10.7292 19.8598 9.69922 18.8298 9.69922 17.5598C9.69922 16.2898 10.7292 15.2598 11.9992 15.2598C13.2692 15.2598 14.2992 16.2898 14.2992 17.5598C14.2992 18.8298 13.2692 19.8598 11.9992 19.8598ZM11.9992 16.7498C11.5592 16.7498 11.1992 17.1098 11.1992 17.5498C11.1992 17.9898 11.5592 18.3498 11.9992 18.3498C12.4392 18.3498 12.7992 17.9898 12.7992 17.5498C12.7992 17.1098 12.4392 16.7498 11.9992 16.7498Z" />
    </svg>

  )
}
