import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { startDownload, CONTENT_TYPES } from '../../../../core/helpers';
import { IconExcel } from '../../icons/excel';

const ExportExcelButton = ({ endpoint, filters = {}}) => {
    // state
    const [isExportingExcel, setIsExportingExcel] = useState(false);

    // redux
    const token = useSelector(state => state.authentication.token);

    const handleExportExcel = async () => {
        // onDownload();
        // dispatch(transactionsExportExcel({params: filters}));
        setIsExportingExcel(true);
        await startDownload(endpoint, {...filters, format: 'excel'}, token, CONTENT_TYPES.excel);
        setIsExportingExcel(false);
    }

    return (
        <button className="btn btn-sm btn-secondary" disabled={isExportingExcel} onClick={handleExportExcel} title="Export Excel">{isExportingExcel ? <span className='spinner-border spinner-border-sm'></span> : <IconExcel height="15px" width="15px" />}</button>
    );
}

export default ExportExcelButton;