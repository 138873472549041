import React from 'react'

export const IconCheckV2 = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32 32" {...props} fill="none" stroke="currentColor">
        <g fill="none" stroke-width="2" stroke-linecap="round" stroke-linejoin="round">
            <path d="M2 20 L12 28 30 4"></path>
        </g>
    </svg>
  )
}
