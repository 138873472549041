.qrcode {
    .qr {
        position: relative;
        height: 200px;

        .target {
            height: 200px;
            width: 200px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .qrcode {
            height: 150px;
            width: 150px;

            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }

    .till {
        text-align: center;
        margin: 20px 0;
        color: #636778;
        font-size: 20px;
        font-weight: 500;
    }
}