.avatar {
    height: 60px;
    width: 60px;
    object-fit: cover;
    object-position: center;
    border-radius: 15px;
}

.summary {
    display: flex;
    flex-direction: column;
    margin-left: 15px;

    .avatar {
        border-radius: 15px;
        height: 60px;
        width: 60px;
        object-fit: cover;
        object-position: center;
    }
    
    .name {
        font-size: 24px;
        font-weight: 700;
    }
}

.avatar {
    height: 60px;
    width: 60px;

    display: flex;
    justify-content: center;
    align-items: center;
}