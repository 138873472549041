import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dashboardGetStatisticsCollection } from '../../../application/features/dashboard';

const _dataset = {
    label: 'Collection',
    data: [],
    backgroundColor: "#6BCD4C",
};

export const useCollectionData = () => {
    // state
    const [dataSet, setDataSet] = useState(_dataset);

    // redux
    const dispatch = useDispatch();
    const collectionGraph = useSelector(state => state.dashboard.collectionGraph);

    // effects
    useEffect(() => {
        dispatch(dashboardGetStatisticsCollection());
    }, []);

    useEffect(() => {
        setDataSet(prevState => {
            return {
                ...prevState,
                data: Object.keys(collectionGraph.data).map(key => collectionGraph.data[key]),
            };
        });
    }, [collectionGraph.data]);

    return {
        dataset: dataSet,
        isLoading: collectionGraph.isFetching,
    };
}