import React from 'react'
import * as styles from './index.module.scss';

const UserSummaryComponent = (props) => {
    const { user } = props
  return (
    <div className={`d-flex justify-content-center`}>
        <img src={user.avatar} className={styles.avatar} alt="" />
        <div className={styles.summary}>
            <span className={styles.name}>{user.firstname} {user.lastname}</span>
            <span>{user.phone}</span>
        </div>
    </div>
  )
}

export default UserSummaryComponent;