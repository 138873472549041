import React, { useEffect } from 'react'
import { IconRefresh } from '../../components/icons/refresh';
import { AppLayout } from '../../components/layout/app/index';
import BalanceComponent from './balance';
import InsightCardComponent from './insight';
import { IconDisbursment } from '../../components/icons/disbursement';
import { IconReceiveMoney } from '../../components/icons/receive-money';
import { RecentTransactionsWidget } from '../../components/widgets/recent-transactions';
import { MonthlyRevenueWidget } from '../../components/widgets/monthly-revenue';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../application/features/modal';
import { MODAL_RECEIVE_PAYMENT } from '../../components/layout/modal/index';
import { getCurrentMonth, numberWithCommas } from '../../../core/helpers';
import { dashboardGetData, selectorDashboardMonthlyContribution, selectorDashboardMonthlyDisbursement } from '../../../application/features/dashboard';
import KycCompletion from '../../components/widgets/kyc';
import { useNavigate } from 'react-router-dom';

export const DashboardPage = () => {
  // router
  const navigate = useNavigate();

  // redux
  const dispatch = useDispatch();
  const contribution = useSelector(state => selectorDashboardMonthlyContribution(state));
  const disbursement = useSelector(state => selectorDashboardMonthlyDisbursement(state));

  // effects
  useEffect(() => {
    dispatch(dashboardGetData());
  }, [])

  // handlers
  const handleReceivePayment = () => {
    dispatch(showModal(MODAL_RECEIVE_PAYMENT));
  }
  return (
    <AppLayout>
        <div className="container">
          <div className="row">
            <div className="col-lg-12 mx-auto">
              <div className="row">
                <div className="col">
                  <KycCompletion />
                </div>
              </div>
              <div className="row">
                <div className="col-lg-6">
                  <BalanceComponent />

                  <div className="mt-3">
                    <div className="card">
                      <div className="card-body">
                        <div className="d-flex align-items-center mb-2">
                          <h3 className="card-title flex-grow-1">Summary For {getCurrentMonth()}</h3>
                          <IconRefresh height="22px" width="22px" />
                        </div>
                        <div className="mt-5"></div>
                        <InsightCardComponent title="Collection (Tsh)" value={numberWithCommas(contribution ?? 0)} />
                        <div className="my-2"></div>
                        <InsightCardComponent title="Disbursement (Tsh)" value={numberWithCommas(disbursement ??  0)} color="#DBF9C9" icon={IconDisbursment} />

                        <div className="d-grid my-4">
                          <button className="btn btn-outline-secondary" onClick={handleReceivePayment}>
                            <IconReceiveMoney height="22px" width="22px" color="#72BE46" />
                            &nbsp; Receive Payment
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-6">
                  <div className="">
                    <MonthlyRevenueWidget />
                  </div>

                  <div className="mt-3">
                    <RecentTransactionsWidget />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    </AppLayout>
  )
}
