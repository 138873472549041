
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { customFetch } from '../../core/request';
import MlipaApi from '../../api/mlipa/endpoints';

// ACTION CONSTANTS
const DISBURSEMENT_UPDATE_STATUS = 'disbursement/UDPATE_STATUS';
const DISBURSEMENT_FINALIZE = 'disbursement/FINALIZE';
const DISBURSEMENT_REJECT = 'disbursement/REJECT';
const DISBURSEMENT_ADD_BATCH = 'disbursement/ADD_BATCH';
const DISBURSEMENT_GET_BATCHES = 'disbursement/GET_BATCHES';
const DISBURSEMENT_VIEW_BATCH = 'disbursement/VIEW_BATCH';
const DISBURSEMENT_GET_FAVOURITES = 'disbursement/GET_FAVOURITES';
const DISBURSEMENT_CREATE_FAVOURITES = 'disbursement/CREATE_FAVOURITES';
const DISBURSEMENT_UPDATE_FAVOURITES = 'disbursement/UPDATE_FAVOURITES';
const DISBURSEMENT_DELETE_FAVOURITES = 'disbursement/DELETE_FAVOURITES';
const DISBURSEMENT_DELETE_TRANSACTION = 'disbursement/DELETE_TRANSACTION';

// ACTIONS
export const disbursementUpdateStatus = createAsyncThunk(
    DISBURSEMENT_UPDATE_STATUS,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.disbursementStatusUpdate(data.id), data.payload, token, store);
    }
);

export const disbursementFinalize = createAsyncThunk(
    DISBURSEMENT_FINALIZE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.disbursementFinalize, data.payload, token, store);
    }
);

export const disbursementReject = createAsyncThunk(
    DISBURSEMENT_REJECT,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.disbursementReject, data.payload, token, store);
    }
);

export const disbursementAddBatch = createAsyncThunk(
    DISBURSEMENT_ADD_BATCH,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.disbursementCreateBatch, data.payload, token, store);
    }
);

export const disbursementGetBatches = createAsyncThunk(
    DISBURSEMENT_GET_BATCHES,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.disbursementGetBatches, data.payload, token, store);
    }
);

export const disbursementViewBatch = createAsyncThunk(
    DISBURSEMENT_VIEW_BATCH,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('GET', MlipaApi.disbursementViewBatch(data.batch_id), data.payload, token, store);
    }
);

export const disbursementDeleteTransaction = createAsyncThunk(
    DISBURSEMENT_DELETE_TRANSACTION,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.disbursementDeleteTransaction(data.id), null, token, store);
    }
);

// INITIAL STATE
const initialState = {
    batches: [],
    updateStatus: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    finalize: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    reject: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    addBatch: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    getBatches: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    viewBatch: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    deleteTransaction: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
};



const disbursementSlice = createSlice({
    name: "disbursement",
    initialState,
    extraReducers: {
        [disbursementUpdateStatus.pending]: (state, action) => {
            state.updateStatus.isLoading = true;
            state.updateStatus.isSuccessfull = false;
            state.updateStatus.error = null;
            state.updateStatus.data = {};
        },
        [disbursementUpdateStatus.fulfilled]: (state, action) => {
            state.updateStatus.isLoading = false;
            state.updateStatus.isSuccessfull = true;
            state.updateStatus.error = null;
            state.updateStatus.data = action.payload;

            let _index = state.batches.findIndex(item => item.batch_id == action.payload.batch_id);
            if (_index > -1) {
                state.batches[_index] = {
                    ...state.batches[0],
                    is_submitted: action.payload.is_submitted,
                    approved: action.payload.approved,
                }
            }
        },
        [disbursementUpdateStatus.rejected]: (state, action) => {
            state.updateStatus.isLoading = false;
            state.updateStatus.isSuccessfull = false;
            state.updateStatus.error = action.payload;
            state.updateStatus.data = {};
        },
        [disbursementFinalize.pending]: (state, action) => {
            state.finalize.isLoading = true;
            state.finalize.isSuccessfull = false;
            state.finalize.error = null;
            state.finalize.data = {};
        },
        [disbursementFinalize.fulfilled]: (state, action) => {
            state.finalize.isLoading = false;
            state.finalize.isSuccessfull = true;
            state.finalize.error = null;
            state.finalize.data = action.payload;
        },
        [disbursementFinalize.rejected]: (state, action) => {
            state.finalize.isLoading = false;
            state.finalize.isSuccessfull = false;
            state.finalize.error = action.payload;
            state.finalize.data = {};
        },
        [disbursementReject.pending]: (state, action) => {
            state.reject.isLoading = true;
            state.reject.isSuccessfull = false;
            state.reject.error = null;
            state.reject.data = {};
        },
        [disbursementReject.fulfilled]: (state, action) => {
            state.reject.isLoading = false;
            state.reject.isSuccessfull = true;
            state.reject.error = null;
            state.reject.data = action.payload;
        },
        [disbursementReject.rejected]: (state, action) => {
            state.reject.isLoading = false;
            state.reject.isSuccessfull = false;
            state.reject.error = action.payload;
            state.reject.data = {};
        },
        [disbursementAddBatch.pending]: (state, action) => {
            state.addBatch.isLoading = true;
            state.addBatch.isSuccessfull = false;
            state.addBatch.error = null;
            state.addBatch.data = {};
        },
        [disbursementAddBatch.fulfilled]: (state, action) => {
            state.addBatch.isLoading = false;
            state.addBatch.isSuccessfull = true;
            state.addBatch.error = null;
            state.addBatch.data = action.payload;
        },
        [disbursementAddBatch.rejected]: (state, action) => {
            state.addBatch.isLoading = false;
            state.addBatch.isSuccessfull = false;
            state.addBatch.error = action.payload;
            state.addBatch.data = {};
        },
        [disbursementGetBatches.pending]: (state, action) => {
            state.getBatches.isLoading = true;
            state.getBatches.isSuccessfull = false;
            state.getBatches.error = null;
            // state.getBatches.data = {};
        },
        [disbursementGetBatches.fulfilled]: (state, action) => {
            state.getBatches.isLoading = false;
            state.getBatches.isSuccessfull = true;
            state.getBatches.error = null;
            state.getBatches.data = action.payload;
            state.batches = action.payload.data;
        },
        [disbursementGetBatches.rejected]: (state, action) => {
            state.getBatches.isLoading = false;
            state.getBatches.isSuccessfull = false;
            state.getBatches.error = action.payload;
            state.getBatches.data = {};
        },
        [disbursementViewBatch.pending]: (state, action) => {
            state.viewBatch.isLoading = true;
            state.viewBatch.isSuccessfull = false;
            state.viewBatch.error = null;
            state.viewBatch.data = {};
        },
        [disbursementViewBatch.fulfilled]: (state, action) => {
            state.viewBatch.isLoading = false;
            state.viewBatch.isSuccessfull = true;
            state.viewBatch.error = null;
            state.viewBatch.data = action.payload;
            // state.batches = action.payload.data;
        },
        [disbursementViewBatch.rejected]: (state, action) => {
            state.viewBatch.isLoading = false;
            state.viewBatch.isSuccessfull = false;
            state.viewBatch.error = action.payload;
            state.viewBatch.data = {};
        },
        [disbursementDeleteTransaction.pending]: (state, action) => {
            state.deleteTransaction.isLoading = true;
            state.deleteTransaction.isSuccessfull = false;
            state.deleteTransaction.error = null;
            state.deleteTransaction.data = {};
        },
        [disbursementDeleteTransaction.fulfilled]: (state, action) => {
            state.deleteTransaction.isLoading = false;
            state.deleteTransaction.isSuccessfull = true;
            state.deleteTransaction.error = null;
            state.deleteTransaction.data = action.payload;
            // state.batches = action.payload.data;
            let _index = state.batches.findIndex(item => item.batch_id == action.meta.arg.payload.batch_id);
            if (_index > -1) {
                let batch = state.batches[_index];
                state.batches[_index] = {
                    ...state.batches[0],
                    transactions: batch.transactions.map((t, i) => {
                        if (action.meta.arg.id != t.id) {
                            return t;
                        }
                    }).filter(ts => ts),
                };
            }
        },
        [disbursementDeleteTransaction.rejected]: (state, action) => {
            state.deleteTransaction.isLoading = false;
            state.deleteTransaction.isSuccessfull = false;
            state.deleteTransaction.error = action.payload;
            state.deleteTransaction.data = {};
        },
    },
});

export default disbursementSlice.reducer;