import React, { useEffect, useState } from 'react'
import * as styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { dashboardGetAccountBalance, selectorDashboardBalance, selectorDashboardBalanceLoading } from '../../../../application/features/dashboard';
import { Switch } from '@mui/material';
import { numberWithCommas, nFormatter } from '../../../../core/helpers';

const BalanceComponent = (props) => {
  // state
  const [show, setShow] = useState(false)
  // redux
  const dispatch = useDispatch();
  const balanceData = useSelector(state => state.dashboard.balance.data);
  const balance = useSelector(state => selectorDashboardBalance(state));
  const isLoading = useSelector(state => selectorDashboardBalanceLoading(state));

  // effects
  useEffect(() => {
    if (!show) return;
    dispatch(dashboardGetAccountBalance());
  }, [show]);

  return (
    <div className={styles.balance}>
        <div className={styles.actions}>
            <Switch checked={show} color="secondary" onChange={(e, checked) => setShow(checked)} />
        </div>
        <span className={styles.label}>View Balance</span>
        <div className={styles.flex}>
          <div>
            {show && !isLoading && <h6>Collection</h6>}
            <h3>{isLoading ? <span className='spinner-border text-light'></span> : (show ? `${nFormatter(balance, 1)}` : '*********')}</h3>
          </div>
          {show && !isLoading && (
            <div>
              <h6>Disbursement</h6>
              <h3>{balanceData.disburse ? nFormatter(balanceData.disburse, 1) : 'N/A'}</h3>
            </div>
          )}
        </div>
    </div>
  );
}

export default BalanceComponent;