// import { BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import React, { useEffect, useRef } from 'react'
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, BarElement, CategoryScale, Legend, LinearScale, Title, Tooltip } from 'chart.js';
import { useState } from 'react';
import styles from './index.module.scss';
import { useCollectionAmountData } from '../../../hooks/charts/collection-amount';
import { useDisbursementAmountData } from '../../../hooks/charts/disbursement-amount';

ChartJS.register(
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
);

export const options = {
    responsive: true,
    plugins: {
        legend: {
            position: 'top',
        },
    }
}

const labels = ['Jan', 'Feb', 'March', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

export const _data = () => {
    return {
        labels,
        datasets: [],
    };
};

export const RevenueAmountChart = () => {
    // state
    const [data, setData] = useState(_data);
    const [loading, setLoading] = useState(false);

    // redux
    const collectionGraphHook = useCollectionAmountData();
    const disbursementGraphHook = useDisbursementAmountData();

    useEffect(() => {
        if (collectionGraphHook.isLoading || disbursementGraphHook.isLoading) {
            setLoading(true);
        } else {
            setTimeout(() => {
                setLoading(false);
            }, 1000);
        }
    }, [collectionGraphHook.isLoading, disbursementGraphHook.isLoading]);

    useEffect(() => {
        setData(prevState => {
            return {
                labels,
                datasets: [
                    collectionGraphHook.dataset,
                    disbursementGraphHook.dataset,
                ]
            };
        });
    }, [collectionGraphHook.dataset, disbursementGraphHook.dataset]);

    return (
        <div className={styles.container}>
            {loading ? <div className={styles.loader}><span className="spinner-border"></span></div> : null}
            <div className='d-flex'>
                <span className='flex-grow-1'>Monthly Transactions Amount Chart</span>
            </div>

            <div>
                <Bar redraw={true} options={options} data={data} />
            </div>
        </div>
    );
}
