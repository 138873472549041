.expanded {
    position: fixed;
    z-index: 5;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: rgba($color: #fff, $alpha: 1.0);
}

.container {
    position: relative;

    .loader {
        position: absolute;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #fff, $alpha: 0.618);
        // color: white;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}