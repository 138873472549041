import React from 'react'
import { PropTypes } from 'prop-types';
import { IconAddUser } from '../../icons/add-user';
import * as styles from './index.module.scss';
import Input from '../../form/input/index';
import { IconSearch } from '../../icons/search';
import Colors from '../../../../assets/contants/colors';
import BarLoader from 'react-bar-loader';

const ListHeaderComponent = (props) => {
    const { title, searchPlaceholder, actionOnClick, actionTitle, actionIcon: ActionIcon, isLoading, searchOnChange, extraButton, hideSearch } = props;

    
    return (
        <div className={`${styles.header}`}>
            <div className={`row`}>
                <div className="col-lg flex-grow-1">
                    <h2>{title}</h2>
                </div>
                <div className="col-lg-6">
                    <div className="d-flex">
                    <div className="flex-grow-1">
                        {hideSearch ? null : <Input name="search" placeholder={searchPlaceholder} leadingIcon={IconSearch} leadingIconProps={{ color: Colors.PRIMARY_COLOR }} onChange={(name, value) => searchOnChange(value)} />}
                    </div>
                    {extraButton && <div className="px-2"></div>}
                    {extraButton && (
                        <button className="btn btn-primary mr-2" onClick={extraButton.onClick}>
                            {/* <extraButton.icon height="22px" width="22px" />
                            &nbsp;  */}
                            {extraButton.title}
                        </button>
                    )}
                    {actionOnClick && <div className="px-2"></div>}
                    {actionOnClick && (
                        <button className="btn btn-primary" onClick={actionOnClick}>
                            <ActionIcon height="22px" width="22px" />
                            &nbsp; 
                            {actionTitle}
                        </button>
                    )}
                    </div>
                </div>
            </div>
            <div className={styles.line}></div>
            {isLoading && <BarLoader height="1" color={Colors.PRIMARY_COLOR} />}
        </div>
    );
}

ListHeaderComponent.defaultProps = {
    title: "Resource List",
    searchPlaceholder: "Search",
    searchOnChange: (q) => console.log("List Header Search: ", q), 
    actionOnClick: null,
    actionTitle: "Add",
    actionIcon: IconAddUser,
    isLoading: false,
    extraButton: null,
    hideSearch: false,
}

ListHeaderComponent.propTypes = {
    title: PropTypes.string,
    searchPlaceholder: PropTypes.string,
    searchOnChange: PropTypes.func,
    actionOnClick: PropTypes.func,
    actionTitle: PropTypes.string,
    actionIcon: PropTypes.elementType,
    isLoading: PropTypes.bool,
    extraButton: PropTypes.shape({
        title: PropTypes.string,
        icon: PropTypes.elementType,
        onClick: PropTypes.func,
    }),
    hideSearch: PropTypes.bool,
}

export default ListHeaderComponent;