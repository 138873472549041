import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../../components/layout/app'
import ListHeaderComponent from '../../../components/list/header'
import { useDispatch } from 'react-redux';
import { createRandomUser } from '../../../../api/faker/factories';
import { IconMoreVertical } from '../../../components/icons/more-vertical';
import { showModal } from '../../../../application/features/modal';
import { MODAL_SEND_MONEY_MOBILE } from '../../../components/layout/modal/index';

export const SendMoneyMobilePage = () => {
    // state
  const [users, setUsers] = useState([]);

  // redux
  const dispatch = useDispatch();

  // effects
  useEffect(() => {
    let _users = [];
    for (var i = 0; i < 5; i++) {
      _users.push(createRandomUser());
    }
    setUsers(_users);
  }, []);

  // handlers
  const handleSelectUser = () => {
    dispatch(showModal(MODAL_SEND_MONEY_MOBILE));
  }

  return (
    <AppLayout>
        <div className="container">
            <ListHeaderComponent title="Mobile Send Money" searchPlaceholder="Search by name or number." />

            <div className="row mt-5">
                <div className="col-lg-10 mx-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Phone</th>
                                <th width="10"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {users.map(user => {
                                return (
                                    <tr>
                                        <td className='px-4'><img src={user.avatar} className="avatar" alt="" /> &nbsp; {user.firstname} {user.lastname}</td>
                                        <td style={{ verticalAlign: 'middle' }}>{user.phone}</td>
                                        <td style={{ verticalAlign: 'middle' }} onClick={handleSelectUser}>
                                            <IconMoreVertical height="26px" width="26px" />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </AppLayout>
  )
}
