import React from 'react'
import * as styles from './index.module.scss';

export const ReceivePaymentPhoneComponent = () => {
  return (
    <div>
        <div>
            <p>1. Go To Pay Bill <strong></strong></p>
            <p>2. Enter Company Number <strong>400700</strong></p>
            <p>3. Enter Reference Number <strong>100000128</strong></p>
            <p>4. Enter Amount and Complete Transaction</p>
        </div>
    </div>
  )
}
