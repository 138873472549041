
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MlipaApi from '../../api/mlipa/endpoints';
import { customFetch } from '../../core/request';

// action constants
const BANK_CREATE_BANK = 'bank/CREATE_BANK';
const BANK_GET_BANKS = 'bank/GET_BANKS';
const BANK_DELETE_BANK = 'bank/DELETE_BANK';

// actions
export const bankCreateBank = createAsyncThunk(
    BANK_CREATE_BANK,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.bankCreateBank, data.payload, token, store);
    }
);

export const bankGetBanks = createAsyncThunk(
    BANK_GET_BANKS,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.bankGetBanks, null, token, store);
    }
);

export const bankDeleteBank = createAsyncThunk(
    BANK_DELETE_BANK,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.bankDeleteBank(data.id), null, token, store);
    }
);

// initial state
const initialState = {
    banks: [],
    create: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    get: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    delete: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
};

// slice
const bankSlice = createSlice({
    name: "bank",
    initialState,
    extraReducers: {
        [bankCreateBank.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessfull = false;
            state.create.error = null;
            state.create.data = action.payload;
        },
        [bankCreateBank.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessfull = true;
            state.create.error = null;
            state.create.data = action.payload;
        },
        [bankCreateBank.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessfull = false;
            state.create.error = action.payload;
            state.create.data = {};
        },
        [bankGetBanks.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessfull = false;
            state.get.error = null;
            state.get.data = action.payload;
        },
        [bankGetBanks.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessfull = true;
            state.get.error = null;
            state.get.data = action.payload;
            state.banks = action.payload.data;
        },
        [bankGetBanks.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessfull = false;
            state.get.error = action.payload;
            state.get.data = {};
        },
        [bankDeleteBank.pending]: (state, action) => {
            state.delete.isLoading = true;
            state.delete.isSuccessfull = false;
            state.delete.error = null;
            state.delete.data = action.payload;
        },
        [bankDeleteBank.fulfilled]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessfull = true;
            state.delete.error = null;
            state.delete.data = action.payload;
            state.banks = state.banks.filter(bank => bank.id != action.meta.arg.id);
        },
        [bankDeleteBank.rejected]: (state, action) => {
            state.delete.isLoading = false;
            state.delete.isSuccessfull = false;
            state.delete.error = action.payload;
            state.delete.data = {};
        },
    },
});

export default bankSlice.reducer;