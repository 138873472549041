import React, { useEffect } from 'react'
import { IconCircledChecked } from '../../icons/circled-check';
import { PropTypes } from 'prop-types';
import * as styles from './index.module.scss';
import { IconCircleError } from '../../icons/circle-error';
import { useDispatch } from 'react-redux';
import { hideModal } from '../../../../application/features/modal';

const AlertModal = (props) => {
    const { type, title, message, actionLabel, actionCallback, hideAfterMs } = props;

    // redux
    const dispatch = useDispatch()

    useEffect(() => {
        if (!hideAfterMs) return
        setTimeout(() => {
            dispatch(hideModal());
        }, hideAfterMs);
    }, [hideAfterMs]);

    return (
        <div className={styles.alert}>
            <div className="d-flex justify-content-center">
                {type == 'success' ? <IconCircledChecked height="80px" width="80px" color="#72BE46" /> : <IconCircleError height="80px" width="80px" color="#BE1100" />}
            </div>

            <h3 className={styles.title}>{title}</h3>
            {message && <p className='text-center'>{message}</p>}

            {actionLabel && (
                <div className='d-grid mt-3'>
                    <button className="btn btn-primary btn-lg" onClick={actionCallback}>{actionLabel}</button>
                </div>
            )}
        </div>
    );
}

AlertModal.defaultProps = {
    type: "success",
    title: "Success",
    message: null,
    actionLabel: "",
    hideAfterMs: null,
    actionCallback: () => console.log("ALERT MODAL Action callback triggered"),
}

AlertModal.propTypes = {
    type: PropTypes.string,
    title: PropTypes.string,
    message: PropTypes.string,
    actionLabel: PropTypes.string,
    actionCallback: PropTypes.func,
    hideAfterMs: PropTypes.number,
}

export default AlertModal;