import React, { useEffect, useState } from 'react'
import Select from '../../components/form/select';
import { AppLayout } from '../../components/layout/app'
import ListHeaderComponent from '../../components/list/header/index';
import Input from '../../components/form/input/index';
import * as styles from './index.module.scss';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../application/features/modal';
import { MODAL_ALERT, MODAL_RECEIPT, MODAL_SEND_MONEY_BANK_INFO } from '../../components/layout/modal';
import { bankGetBanks } from '../../../application/features/bank';
import { withdrawCreateSettlement, withdrawGetSettlementTypes } from '../../../application/features/withdraw';
import { numberWithCommas, nFormatter } from '../../../core/helpers';
import { dashboardGetAccountBalance } from '../../../application/features/dashboard';
import { RECEIPT_TYPE_SETTLEMENT } from '../../components/modals/receipt/index';
import { useNavigate } from 'react-router-dom';
import { updateLoader } from '../../../application/features/app';
import KycCompletion from '../../components/widgets/kyc/index';
import { useCan } from '../../hooks/can';
import { PERMISSION_SETTLEMENT_CREATE } from '../../../core/permissions';

export const WithdrawPage = () => {
    // state
    const [mounted, setMounted] = useState(false);
    const [payload, setPayload] = useState({});
    const [errors, setErrors] = useState({});
    const [kycCompletion, setKycCompletion] = useState(null);

    // router
    const navigate = useNavigate();

    // authorization
    const canCreateSettlement = useCan(PERMISSION_SETTLEMENT_CREATE);

    // redux
    const dispatch = useDispatch();
    const banks = useSelector(state => state.bank.banks);
    const types = useSelector(state => state.withdraw.types);
    const createSettlement = useSelector(state => state.withdraw.create);
    const balanceData = useSelector(state => state.dashboard.balance.data);
    const isAppLoading = useSelector(state => state.app.loader.isLoading);

    // effects
    useEffect(() => {
        setMounted(true);
        dispatch(bankGetBanks());
        dispatch(withdrawGetSettlementTypes());
        dispatch(dashboardGetAccountBalance());
    }, [])

    useEffect(() => {
        if (!mounted) return;
        if (isAppLoading) return;

        setTimeout(() => {
            if (createSettlement.error) {
                let _error = createSettlement.error;
                if (_error.message) {
                    dispatch(showModal(MODAL_ALERT, {type: 'danger', title: 'Failed', message: _error.data}))
                }
            }
        }, 1000);

        if (createSettlement.isSuccessfull) {
            // dispatch(showModal(MODAL_RECEIPT, {type: RECEIPT_TYPE_SETTLEMENT, data: createSettlement.data}));
            navigate('receipt');
        }
    }, [createSettlement, isAppLoading])

    // handlers
    const handleChange = (name, value) => {
        setPayload({...payload, [name]: value});

        if (errors[name]) {
            setErrors(prevState => {
                return {...prevState, [name]: null};
            });
        }
    }

    const handleSubmit = () => {
        if (!validate()) return;
        dispatch(showModal(MODAL_SEND_MONEY_BANK_INFO, { payload: payload, bank: banks.find(b => b.id == payload.bank_account_id) }));
        // dispatch(withdrawCreateSettlement({payload}));
    }

    const handleKycCompletionChanged = (kycCompletion) => {
        setKycCompletion(kycCompletion);
    }

    const validate = () => {
        let pass = true;
        let errors = {};

        if (!payload.settlement_type_id) {
            errors['settlement_type_id'] = 'Withdrawal Type is Required';
            pass = false;
        }

        if (!payload.bank_account_id) {
            errors['bank_account_id'] = 'Bank is Required';
            pass = false;
        }

        if (!payload.amount) {
            errors['amount'] = 'Amount is Required';
            pass = false;
        }

        if (payload.amount < 1000) {
            errors['amount'] = 'The minimum amount for withdrawal is 1,000 TZS';
            pass = false;
        }

        if (payload.amount > balanceData.balance) {
            errors['amount'] = `The maximum amount for withdrawal is ${numberWithCommas(balanceData.balance)} TZS`;
            pass = false;
        }

        if (!payload.description) {
            errors['description'] = 'Description is Required';
            pass = false;
        }

        setErrors(errors);

        return pass;
    }

    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent title="Withdraw Money" hideSearch={true} />
                {/* {Object.keys(payload).reduce((carry, key) => carry + key + ':' + payload[key] + ', ', '')} */}
                <div className="mt-3"></div>
                <div className="row">
                    <div className="col">
                        <KycCompletion onChange={handleKycCompletionChanged} />

                        {(kycCompletion && !kycCompletion.complete) && (
                            <div className='mt-2 alert alert-danger'>
                                <p>You won't be able to withdraw without completing your KYC</p>
                            </div>
                        )}
                    </div>
                </div>

                <div className="card mb-5">
                    <div className={`card-body ${styles.wrapper}`}>
                        {createSettlement.isLoading ? (
                            <div className={styles.loader}>
                                <div className='text-center'>
                                    <span className="spinner-border spinner-border-lg"></span>
                                    <h5 className='text-center'>Please Wait...</h5>
                                    <p className='text-center'>We are processing the settlement</p>
                                </div>
                            </div>
                        ) : null}
                        <div className="row">
                            <div className="d-flex justify-content-end mb-2">
                                <div className="border px-3 py-2">Available Balance: {!isNaN(balanceData.balance) ? nFormatter(balanceData.balance) : 'N/A'}</div>
                            </div>
                            <div className="col-lg-5">
                                <Select label="Withdrawal Type" name="settlement_type_id" onChange={handleChange} error={errors.settlement_type_id}>
                                    <option value="">Select</option>
                                    {types.map(type => {
                                        if (type.name.toLowerCase() == 'local bank') {
                                            return <option value={type.id}>BANK</option>;
                                        }
                                        if (type.name.toLowerCase() == 'international bank') {
                                            return;
                                        }
                                        return <option value={type.id}>{type.name}</option>;
                                    })}
                                </Select>
                            </div>
                            <div className="col-lg-4">
                                <Select label="Withdrawal Destination" name="bank_account_id"  onChange={handleChange} error={errors.bank_account_id}>
                                    <option value="">Select</option>
                                    {banks.map(bank => <option value={bank.id}>{bank.bank_name} - {bank.account_no}</option>)}
                                </Select>
                            </div>
                            <div className="col-lg-3">
                                <Input type="currency" label="Amount" name="amount" placeholder="Amount" onChange={handleChange} error={errors.amount} />
                            </div>
                        </div>

                        <div className="row my-2">
                            <div className="col-lg-12">
                                <Input type="textarea" name="description" label="Description" placeholder="Enter Description" onChange={handleChange} error={errors.description} />
                            </div>
                        </div>

                        {/* <div className={styles.details}> */}
                            {/* <h4>Summary</h4>
                            <div className={styles.detail}>
                                <p>Amount to Transfer</p>
                                <span> {payload.amount ? payload.amount : 0.00}</span>
                            </div> */}
                            {/* <div className={styles.detail}>
                                <p>Charge</p>
                                <span>0.00</span>
                            </div> */}
                            {/* <hr />
                            <div className={styles.detail}>
                                <p>Total</p>
                                <span>TZS {payload.amount ? numberWithCommas(payload.amount) : 0.00}</span>
                            </div> */}
                        {/* </div> */}

                        <div className="d-flex justify-content-end">
                            <button className="btn btn-primary btn-lg" disabled={createSettlement.isLoading} onClick={handleSubmit}>Continue {createSettlement.isLoading ? <span className='spinner-border spinner-border-sm'></span> : null}</button>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
