import React, { useEffect, useRef, useState } from 'react'
import Input from '../../../components/form/input';
import { AppLayout } from '../../../components/layout/app/index';
import { IconUser } from '../../../components/icons/user';
import { IconPhone } from '../../../components/icons/phone';
import { IconDisbursment } from '../../../components/icons/disbursement';
import { IconAddUser } from '../../../components/icons/add-user';
import { IconUpload } from '../../../components/icons/upload';
import { IconEdit } from '../../../components/icons/edit';
import { IconTrash } from '../../../components/icons/trash';
import { numberWithCommas } from '../../../../core/helpers';
import readXlsxFile from 'read-excel-file';
import { useDispatch, useSelector } from 'react-redux';
import { disbursementAddBatch } from '../../../../application/features/disbursement';
import { hideModal, showModal } from '../../../../application/features/modal';
import { MODAL_ALERT } from '../../../components/layout/modal';
import { useLocation, useNavigate } from 'react-router-dom';
import * as styles from './index.module.scss';
import { updateLoader } from '../../../../application/features/app';
import { dashboardGetAccountBalance } from '../../../../application/features/dashboard';

export const CreateBatchPage = () => {
    // ref
    const fileInput = useRef();

    // router
    const { state } = useLocation();
    const navigate = useNavigate();

    // state
    const [batchName, setBatchName] = useState();
    const [batchNameError, setBatchNameError] = useState();
    const [payload, setPayload] = useState({
        name: "",
        phone: "",
        amount: "",
    });
    const [index, setIndex] = useState(-1);
    const [errors, setErrors] = useState({});
    const [users, setUsers] = useState([]);
    const [mounted, setMounted] = useState(false);
    const [total, setTotal] = useState(0);

    // redux
    const dispatch = useDispatch();
    const addBatch = useSelector(state => state.disbursement.addBatch);
    const balanceData = useSelector(state => state.dashboard.balance.data);

    // effects
    useEffect(() => {
        setMounted(true);
        if (!balanceData.disburse) {
            dispatch(dashboardGetAccountBalance());
        }
    }, []);

    useEffect(() => {
        if (!state) return;
        if (state.batch) {
            setBatchName(state.batch.name)
            let _users = state.batch.transactions.map(t => {
                return {
                    name: t.name,
                    phone: t.mobile,
                    amount: t.amount
                }
            });
            setUsers(_users);
        }

        if (state.excel) {
            extractDataFromExcel(state.excel);
        }

        if (state.batchName) {
            setBatchName(state.batchName);
        }
    }, [state]);

    useEffect(() => {
        if (!users.length) return;
        users.map((u,i) => {
            let hasErrors = false;
            let errors = {
                name: [],
                phone: [],
                amount: [],
            }
            // validating the array

            // check duplicate number
            if (users.filter(user => user.phone == u.phone).length > 1) {
                errors.phone.push('Duplicate Phone #');
                hasErrors = true;
            }

            if (hasErrors) {
                setUsers(prevState => {
                    let __users = [...prevState];
                    __users[i] = {...u, hasErrors: true, errors};
                    return __users;
                });
            }
        });

        setTotal(users.reduce((carry, user) => {
            return carry + parseFloat(user.amount)
        }, 0));
    }, [users]);

    useEffect(() => {
        console.log(mounted, addBatch.isSuccessfull);
        if (!mounted) return;
        if (!addBatch.isSuccessfull) return;
        console.log(mounted, addBatch.isSuccessfull);
        handleViewBatch();
        // dispatch(showModal(MODAL_ALERT, {title: 'Batch Added', actionLabel: 'Ok', actionCallback: handleViewBatch}));
    }, [addBatch.isSuccessfull]);

    useEffect(() => {
        if (!mounted) return;
        if (!addBatch.error) return;
        // handleViewBatch();
        dispatch(showModal(MODAL_ALERT, {type: 'danger', title: 'Ooops!', message: 'Please check if everything is ok, Adding of the batch was not successful.', actionLabel: 'Ok', actionCallback: handleViewBatch}));
    }, [addBatch.error]);

    useEffect(() => {
        if (addBatch.isLoading) {
            dispatch(updateLoader({
                isLoading: true,
                message: 'Hold on, We are almost there...',
            }));
        } else {
            setTimeout(() => {
                dispatch(updateLoader({
                    isLoading: false
                }));
            }, 1800);
        }
    }, [addBatch.isLoading]);

    useEffect(() => {
        if (index > -1) {
            setPayload(users[index]);
        }
    }, [index]);

    // handlers
    const handleViewBatch = () => {
        dispatch(hideModal());
        if (addBatch.data) {
            console.log("This condition passed")
            navigate(`/send/batch/${addBatch.data[0].batch_id}`);
        }
    }

    const handleChange = (name, value) => {
        setPayload({...payload, [name]: value});
        if (errors[name]) {
            setErrors(prevState => {
                return {
                    ...prevState,
                    [name]: null,
                }
            });
        }
    }

    const addPayload = () => {
        console.debug(validate());
        if (!validate()) return;

        let _users = [...users];
        _users.push(payload);
        setUsers(_users);
        setPayload({
            name: "",
            phone: "",
            amount: "",
        });
    }

    const updatedPayload = () => {
        if (!validate()) return;

        let _users = [...users];
        _users[index] = payload;
        setUsers(_users);
        setIndex(-1);
        setPayload({
            name: "",
            phone: "",
            amount: "",
        });
    }

    const handleFileInput = () => {
        fileInput.current.click();
    }

    const deleteUser = (user) => {
        setUsers(users.filter(u => u.phone !== user.phone));
    }

    const validate = () => {
        let pass = true;
        Object.keys(payload).map(key => {
            if (!payload[key]) {
                setErrors(prevState => {
                    return {
                        ...prevState,
                        [key]: "Required"
                    }
                });
                pass = false;
            }
        });

        if (payload.phone) {
            if (payload.phone.length < 12) {
                setErrors(prevState => {
                    return {
                        ...prevState,
                        phone: 'Invalid Phone Number'
                    }
                })
                pass = false;
            }
            
        }
        return pass;
    }

    const handleFileChange = (e) => {
        console.debug(e.target.files.length);
        if (!e.target.files.length) return;
        extractDataFromExcel(e.target.files[0]);
    }

    const extractDataFromExcel = (file) => {
        readXlsxFile(file).then(rows => {
            let _users = [];
            rows.map((r, i) => {
                if (i > 0) {
                    _users.push({
                        name: r[0],
                        phone: r[1],
                        amount: r[2],
                    });
                }
            })
            setUsers(prevState => prevState.concat(_users));
        });
    }

    const handleProcessBatch = () => {
        if (!batchName) {
            setBatchNameError("Batch Name is Required")
            return;
        }
        // prepare the payload
        const payload = {
            batch_name: batchName,
            customers: users.map(u => {
                return {
                    phone_no: u.phone,
                    first_name: u.name,
                    amount: u.amount,
                }
            })
        }
        
        dispatch(disbursementAddBatch({payload}));
    }

    const handleCancelEdit = () => {
        setIndex(-1);
        setPayload({
            name: "",
            phone: "",
            amount: "",
        });
    }

    return (
        <AppLayout>
            <div className="container" style={{ position: 'relative' }}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="d-flex">
                            <h3 className='flex-grow-1'>New Batch</h3>
                        </div>
                    </div>
                </div>

                <div className="row mt-3">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <Input name="batch" label="Batch Name" placeholder="The name of the Batch" error={batchNameError} value={batchName} onChange={(name, value) => {
                                            setBatchName(value);
                                            if (batchNameError) {
                                                setBatchNameError("");
                                            }
                                        }} />
                                    </div>
                                </div>

                                <hr className='mt-2' />
                                {/* <h3 className="card-title">Add List of Users</h3> */}
                                <div className="row align-items-start">
                                    <div className="col-md-6 col-lg-3">
                                        <Input name="name" label="Name" placeholder="Name" value={payload.name} leadingIcon={IconUser} onChange={handleChange} error={errors.name} />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <Input name="phone" type="phone" label="Phone" value={payload.phone} placeholder="Phone Number" leadingIcon={IconPhone} onChange={handleChange} error={errors.phone} />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <Input name="amount" type="currency" label="Amount" value={payload.amount} placeholder="Amount" leadingIcon={IconDisbursment} onChange={handleChange} error={errors.amount} />
                                    </div>
                                    <div className="col-md-6 col-lg-3">
                                        <div className="py-3"></div>
                                        <div className="d-flex">
                                            {index > -1 ? <button className="btn btn-primary" onClick={updatedPayload}>Update #{index+1}</button> : <button className="btn btn-primary" onClick={addPayload}>+ Add</button>}
                                            <div className="px-1"></div>
                                            {index > -1 &&  <button className="btn btn-outline-primary ml-1" onClick={handleCancelEdit}>Cancel</button>}
                                            {/* <button className="btn btn-outline-primary" onClick={handleFileInput}><IconUpload height="22" width="22" /> &nbsp; Bulk Upload</button> */}
                                            <input ref={fileInput} onChange={handleFileChange} type="file" name="" id="" style={{ display: 'none' }} />
                                        </div>
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="col-lg-12">
                                    {users.length ? (
                    <div className="row mt-2">
                        <div className="col-lg-12">
                            <table className="table">
                                {/* <thead>
                                    <tr>
                                        <th width="10"></th>
                                        <th>Name</th>
                                        <th>Phone</th>
                                        <th>Amount</th>
                                        <th></th>
                                    </tr>
                                </thead> */}

                                <tbody>
                                    {users.map((user, i) => {
                                        return (
                                            <tr>
                                                <td valign='middle'>{i+1}</td>
                                                <td valign='middle'>{user.name}</td>
                                                <td valign='middle' className={user.hasErrors ? 'text-danger' : ''}>{user.phone}</td>
                                                <td valign='middle' style={{ textAlign: 'right' }}>{numberWithCommas(user.amount)}</td>
                                                <td valign='middle' width="200px">
                                                    <div className="d-flex justify-content-end">
                                                        <button className="btn btn-outline-primary btn-sm" onClick={() => setIndex(i)}><IconEdit height="18" width="18" /></button>
                                                        &nbsp;
                                                        <button className="btn btn-outline-danger btn-sm" onClick={() => deleteUser(user)}><IconTrash height="18" width="18" /></button>
                                                    </div>
                                                </td>
                                            </tr>
                                        );
                                    })}
                                </tbody>

                                <tfoot>
                                        <tr>
                                            <th colSpan="3" style={{ textAlign: 'right' }}>TOTAL:</th>
                                            <th style={{ textAlign: 'right' }}>{numberWithCommas(total)}</th>
                                            <th></th>
                                        </tr>
                                        <tr>
                                            <th colSpan="3" style={{ textAlign: 'right' }}>AVAILABLE BALANCE:</th>
                                            <th style={{ textAlign: 'right' }}>{numberWithCommas(balanceData.disburse ?? 0.00)}</th>
                                            <th>{total > balanceData.disburse ? <span className='text-danger'>(insufficient balance)</span> : null}</th>
                                        </tr>
                                </tfoot>
                            </table>
                        </div>
                    </div>
                ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {users.length ? (
                    <div className='d-flex justify-content-end mt-3'>
                        <button onClick={handleProcessBatch} className="btn btn-success btn-lg" disabled={addBatch.isLoading || total > balanceData.disburse}>Process Batch</button>
                    </div>
                ) : null}
            </div>
        </AppLayout>
    );
}
