import React from 'react'
import { PropTypes } from 'prop-types';

const ListPaginationComponent = (props) => {
  const { currentPage, lastPage, onClick } = props;

  const getLinks = () => {
    
  }

  if (lastPage < 10) {
    return (
      <nav aria-label="Page navigation example">
          <ul class="pagination justify-content-center">
              <li class={`page-item ${currentPage > 1 ? '' : 'disabled'}`} onClick={currentPage > 1 ? () => onClick(currentPage - 1) : null}>
              <a class="page-link" href="#" aria-label="Previous">
                  <span aria-hidden="true">&laquo;</span>
              </a>
              </li>
              {Array(lastPage).fill(0).map((_, i) => {
                return <li onClick={() => onClick(i + 1)} class={`page-item ${currentPage == (i+1) ? 'active' : ''}`}><a class="page-link" href="#">{i+1}</a></li>
              })}
              <li class="page-item">
              <a class={`page-link ${currentPage < lastPage ? null : 'disabled'}`} href="#" aria-label="Next" onClick={currentPage < lastPage ? () => onClick(currentPage + 1) : null}>
                  <span aria-hidden="true">&raquo;</span>
              </a>
              </li>
          </ul>
      </nav>
    )
  }

  let count = 0;

  return (
    <nav aria-label="Page navigation example">
        <ul class="pagination justify-content-center">
            <li class={`page-item ${currentPage > 1 ? '' : 'disabled'}`} onClick={currentPage > 1 ? () => onClick(currentPage - 1) : null}>
            <a class="page-link" href="#" aria-label="Previous">
                <span aria-hidden="true">&laquo;</span>
            </a>
            </li>
            {currentPage < (lastPage-2) ? <li onClick={() => onClick(currentPage)} class={`page-item active`}><a class="page-link" href="#">{currentPage}</a></li> :  <li onClick={() => onClick(1)} class={`page-item`}><a class="page-link" href="#">{1}</a></li>}
            {(currentPage+1) < (lastPage-2) ? <li onClick={() => onClick(currentPage+1)} class={`page-item`}><a class="page-link" href="#">{currentPage+1}</a></li> : <li onClick={() => onClick(2)} class={`page-item`}><a class="page-link" href="#">{2}</a></li>}
            {(currentPage+2) < (lastPage-2) ? <li onClick={() => onClick(currentPage+2)} class={`page-item`}><a class="page-link" href="#">{currentPage+2}</a></li> : <li onClick={() => onClick(3)} class={`page-item`}><a class="page-link" href="#">{3}</a></li>}
             
            <li onClick={null} class={`page-item`}><a class="page-link" href="#">...</a></li>
            <li onClick={() => onClick(lastPage-2)} class={`page-item ${currentPage == (lastPage-2) ? 'active' : ''}`}><a class="page-link" href="#">{lastPage-2}</a></li>
            <li onClick={() => onClick(lastPage-1)} class={`page-item ${currentPage == (lastPage-1) ? 'active' : ''}`}><a class="page-link" href="#">{lastPage-1}</a></li>
            <li onClick={() => onClick(lastPage)} class={`page-item ${currentPage == (lastPage) ? 'active' : ''}`}><a class="page-link" href="#">{lastPage}</a></li>
            <li class="page-item">
            <a class={`page-link ${currentPage < lastPage ? null : 'disabled'}`} href="#" aria-label="Next" onClick={currentPage < lastPage ? () => onClick(currentPage + 1) : null}>
                <span aria-hidden="true">&raquo;</span>
            </a>
            </li>
        </ul>
    </nav>
  )
}

ListPaginationComponent.defaultProps = {
  currentPage: 1,
  lastPage: 1,
  onClick: (page) => console.log('Pagination Page: ', page),
}

ListPaginationComponent.propTypes = {
  currentPage: PropTypes.number,
  lastPage: PropTypes.number,
  onClick: PropTypes.func,
}

export default ListPaginationComponent;