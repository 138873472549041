import React from 'react'
import { AppLayout } from '../../../components/layout/app'
import ListHeaderComponent from '../../../components/list/header'
import { ReceiptModal } from '../../../components/modals/receipt';
import ReceiptSettlement from '../../../components/modals/receipt/receipts/settlement';
import { RECEIPT_TYPE_SETTLEMENT } from '../../../components/modals/receipt/index';

export const WithdrawReceiptPage = () => {
  return (
    <AppLayout>
        <div className="container">
            <ListHeaderComponent title="Withdrawal Receipt"  hideSearch={true} />

            <ReceiptModal type={RECEIPT_TYPE_SETTLEMENT} />
        </div>
    </AppLayout>
  )
}
