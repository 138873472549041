@import '$assets/scss/main.scss';

.layout {
    padding: 0;
    margin: 0;
    width: 100vw;
    height: 100vh;

    display: flex;

    .sidemenu {
        width: 250px;
    }

    .content {
        flex: 1;

        display: flex;
        flex-direction: column;

        .header {
            // height: 85px;
            display: block;
        }

        .body {
            position: relative;
            padding-top: 30px;
            flex: 1;
            overflow: scroll;
            padding-bottom: 3rem;
        }
    }
}

.loader {
    position: absolute;
    height: 100%;
    width: 100%;
    background-color: rgba($color: #fff, $alpha: 0.99);
    top: 0;
    left: 0;
    z-index: 5;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.offline {
    background-color: $danger;
    color: white;
}