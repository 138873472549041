import React, { useState } from 'react'
import * as styles from './index.module.scss';
import { IconExpand } from '../../icons/expand';
import { RevenueCountChart } from '../../charts/revenue/count-bar-graph';
import Tabs from '../../modals/receivePayment/tabs/index';
import { RevenueAmountChart } from '../../charts/revenue/amount-bar-graph';

const tabs = [
  {
    label: 'Count',
    value: 'count',
  },
  {
    label: 'Amount',
    value: 'amount',
  }
]

export const MonthlyRevenueWidget = () => {
  const [expanded, setExpanded] = useState(false);
  const [graph, setGraph] = useState('count');

  return (
    <div className="card">
        <div className="card-body">
            <div className="d-flex align-items-start">
              <h3 className="card-title mb-3 flex-grow-1">Statistics</h3>
              <button className="btn btn-sm btn-outline-secondary" onClick={() => setExpanded(true)}><IconExpand height="12px" width="12px" /></button>
            </div>
            <Tabs initialValue='count' tabs={tabs} justifyContent="end" onTabSelected={(tab) => setGraph(tab)} />
            <div className="my-3"></div>
            
            {graph === 'count' && <RevenueCountChart />}
            {graph === 'amount' && <RevenueAmountChart />}

            <div className={styles.expanded} style={{ display: expanded ? 'block' : 'none' }}>
                <div className="container">
                    <div className="row mt-3">
                        <div className="col-lg-12">
                            <div className="d-flex">
                                <div className="flex-grow-1"></div>
                                <button className="btn btn-primary" onClick={() => setExpanded(false)}>X</button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                          {graph === 'count' && <RevenueCountChart />}
                          {graph === 'amount' && <RevenueAmountChart />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
  )
}
