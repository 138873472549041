

.header {
    border-bottom: solid 1px #E0E5EB;
    background-color: white;
    height: 85px;

    .salutation {
        font-size: 24px;
        font-weight: 200;
        flex: 1;
    }

    .avatar {
        position: relative;

        height: 45px;
        width: 45px;
        border-radius: 15px;
        background-color: gray;
        margin-left: auto;
        background-position: center;
        background-size: cover;

        display: flex;
        justify-content: center;
        align-items: center;

        cursor: pointer;

        .initials {
            font-weight: bold;
        }

        .menu {
            all: unset;

            position: absolute;
            display: block;

            top: 55px;
            right: 0;

            background-color: white;
            border-radius: 5px;
            

            border: solid 1px gray;
            z-index: 10;

            li {
                all: unset;

                display: block;
                border-bottom: solid 1px gray;

                padding: 8px 24px;
                cursor: pointer;

                color: black;

                &:hover {
                    background-color: rgba($color: #000000, $alpha: 0.3);
                }
            }

            li:last-child {
                border: none;
            }
        }
    }

    .actions {
        margin-right: 16px;
        color: #8397AF;

        display: flex;
        column-gap: 12px;

        div {
            cursor: pointer;
        }
    }
}