import React from 'react'
import { AppLayout } from '../../components/layout/app/index';
import ListHeaderComponent from '../../components/list/header/index';
import SettingCardComponent from './card';
import { IconBank } from '../../components/icons/bank';
import { IconMarker } from '../../components/icons/marker';
import { IconQrCode } from '../../components/icons/qr-code';
import * as styles from './index.module.scss';
import { IconUser } from '../../components/icons/user';
import { IconMessage } from '../../components/icons/message';
import { IconReport } from '../../components/icons/report';
import { IconDollarBox } from '../../components/icons/dollar-box';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../application/features/modal';
import { MODAL_RECEIVE_PAYMENT } from '../../components/layout/modal/index';
import { useNavigate } from 'react-router-dom';
import { IconPhone } from '../../components/icons/phone';

export const SettingsPage = () => {
    // redux
    const dispatch = useDispatch();
    const user = useSelector(state => state.authentication.user);

    // router
    const navigate = useNavigate();

    // handlers
    const handleMyQrcode = () => {
        dispatch(showModal(MODAL_RECEIVE_PAYMENT));
    }

    const handleName = () => {
        navigate('/documents');
    }

    const handleSettlementAccounts = () => {
        navigate('/banks');
    }

    const handleUsers = () => {
        navigate('/users');
    }

    const handleRoles = () => {
        navigate('/roles');
    }

    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent hideSearch={true} title="Settings" />
                <div className="mt-5">
                    <div className="card">
                        <div className="card-body">
                            <div className="row">
                                <div className="col-lg-11 mx-auto">
                                    <div className="row">
                                        <div className="col-lg-5">
                                            <SettingCardComponent title="Business Name" description={`${user.business_name}, ${user.account}`} icon={IconBank} onClick={null} />
                                        </div>
                                        <div className="col-lg-2"></div>
                                        <div className="col-lg-5">
                                            <SettingCardComponent title="Business Location" description="Lat: 6.7677523, Lng: 39.244146" icon={IconMarker} onClick={null} />
                                        </div>
                                        <div className="col-lg-5 mt-3">
                                            <SettingCardComponent title="My Qr Code" description="Scan and Pay to Toll No 10000128" icon={IconQrCode} onClick={handleMyQrcode} />
                                        </div>
                                    </div>
                                    <hr />

                                    <div className="row mt-5 mb-3">
                                        <div className="col-lg-12">
                                            <h2 className={styles.title}>Business Name</h2>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-5 mb-3">
                                            <SettingCardComponent title="Documents" description="Upload 6 documents Client to view or update" icon={IconReport} onClick={handleName} />
                                        </div>
                                        <div className="col-lg-2 mb-3"></div>
                                        <div className="col-lg-5 mb-3">
                                            <SettingCardComponent title="Withdraw Accounts" description="Added 1 Withdraw acocunts Click to view or update" icon={IconDollarBox} onClick={handleSettlementAccounts} />
                                        </div>

                                        {/* <div className="col-lg-5 mb-3">
                                            <SettingCardComponent title="Users Management" description="Manage your organization users and permissions" icon={IconUser} onClick={handleUsers} />
                                        </div>
                                        <div className="col-lg-2 mb-3"></div>
                                        <div className="col-lg-5 mb-3">
                                            <SettingCardComponent title="Roles Management" description="Manage your organization roles" icon={IconReport} onClick={handleRoles} />
                                        </div> */}
                                        
                                    </div>

                                    <div className="row mt-5 mb-3">
                                        <div className="col-lg-12">
                                            <h2 className={styles.title}>Personal Details</h2>
                                        </div>
                                    </div>

                                    <div className="row">
                                        <div className="col-lg-5 mb-3">
                                            <SettingCardComponent title="Name" description={`${user.firstname} ${user.lastname}`} icon={IconUser} onClick={null} />
                                        </div>
                                        <div className="col-lg-2 mb-3"></div>
                                        <div className="col-lg-5 mb-3">
                                            <SettingCardComponent title="Email" description={user.email} icon={IconMessage} onClick={null} />
                                        </div>

                                        <div className="col-lg-5">
                                            <SettingCardComponent title="Phone" description={user.phone} icon={IconPhone} onClick={null} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
