import React from 'react'
import * as styles from './index.module.scss';
import { PropTypes } from 'prop-types';
import IconCollection from '../../../components/icons/collection';

const InsightCardComponent = (props) => {
    const { title, value, icon: Icon, color } = props;

    return (
        <div className={styles.insight} style={{ backgroundColor: color }}>
            <div className={styles.content}>
                <div className={styles.info}>
                    <h6>{title}</h6>
                    <div className="flex-grow-1"></div>
                    <h2>{value}</h2>
                </div>
                <div className={styles.icon}>
                    <Icon height="75px" width="75px" />
                </div>
            </div>
        </div>
    );
}

InsightCardComponent.defaultProps = {
    title: "insight",
    value: "N/A",
    icon: IconCollection,
    color: "#FFFDC3",
}

InsightCardComponent.propTypes = {
    title: PropTypes.string,
    value: PropTypes.string,
    icon: PropTypes.elementType,
    color: PropTypes.string,
}

export default InsightCardComponent;