import React from 'react'

export const IconTransactions = (props) => {
  return (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M15.437 18.4849V6.00195" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M19.1751 14.7305L15.4372 18.4857L11.6992 14.7305" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M6.33543 3.51367V15.9966" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M2.59766 7.26895L6.33562 3.51367L10.0736 7.26895" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
