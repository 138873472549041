import React, { useRef } from 'react'
import logo from '$assets/images/logo.png';
import * as styles from './index.module.scss';
import qrcode from '$assets/images/qr-code.png';
import ReceiptSettlement from './receipts/settlement';
import html2canvas from 'html2canvas';
import { useNavigate } from 'react-router-dom';

export const RECEIPT_TYPE_SETTLEMENT = 'RECEIPT_TYPE_SETTLEMENT';

export const ReceiptModal = (props) => {
    // props
    const { type, data } = props;

    // refs
    const receiptRef = useRef();

    // dom
    const navigate = useNavigate();

    // handlers
    const handleDownload = () => {
        html2canvas(receiptRef.current).then((canvas) => {
            const uri = canvas.toDataURL();

            let link = document.createElement("a");
            link.download = 'mlipa-receipt';
            link.href = uri;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        });
    }

    return (
        <div className='my-3'>
            <div className="card mx-auto" style={{ maxWidth: "600px" }}>
                <div className="card-body">
                    <div className="p-3 mx-auto" style={{ maxWidth: "500px" }} ref={receiptRef}>
                        <img src={logo} alt="" />

                        {renderReceipt(type, data)}

                        <div className="mt-2">
                            <div className="d-flex justify-content-center mt-4">
                                <img src={qrcode} alt="" height="90px" width="90px" className="mx-auto" />
                            </div>
                            <p className={styles.footer}>
                                Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the  <br />
                                <strong>-Banki ni SimiBank-</strong>
                            </p>
                        </div>
                    </div>
                    <div className="d-flex my-2 mx-auto" style={{ maxWidth: "500px" }}>
                        <button className="btn btn-primary btn-lg" onClick={handleDownload}>Download Receipt</button>
                        <div className="px-2"></div>
                        <button className="btn btn-outline-primary btn-lg" >Share Receipt</button>
                        <div className="px-2"></div>
                        <button className="btn btn-outline-secondary btn-lg" onClick={() => navigate('../../withdrawals')}>Back</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

const renderReceipt = (type, data) => {
    switch (type) {
        case RECEIPT_TYPE_SETTLEMENT:
            return <ReceiptSettlement settlement={data} />    
        default:
            return <div>Sorry, The receipt is not available for now, Try again later.</div>
    }
}
