const { combineReducers, configureStore } = require("@reduxjs/toolkit");
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE } from 'redux-persist';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';
import storage from 'redux-persist/lib/storage';
import reducers from './reducers';
import { networkMiddleware } from './middleware/network-middleware';

const rootReducer = combineReducers({...reducers})

const persistConfig = {
    key: 'root',
    version: 1,
    storage: storage,//blacklisting a store attribute name, will not persist that store attribute.
    whitelist: [],
  };

  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const store = configureStore({
    reducer: persistedReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER]
        },
      }).concat(networkMiddleware)
  });

  export const persistor = persistStore(store);
  export default store;
