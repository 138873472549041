import React, { useEffect } from 'react'
import { AppLayout } from '../../components/layout/app'
import ListHeaderComponent from '../../components/list/header'
import { useDispatch, useSelector } from 'react-redux';
import { documentGetTypes, documentGetDocuments } from '../../../application/features/document';
import { IconAdd } from '../../components/icons/add';
import * as styles from './index.module.scss';
import { showModal } from '../../../application/features/modal';
import { MODAL_DOCUMENT_FORM } from '../../components/layout/modal/index';
import { IconCircledChecked } from '../../components/icons/circled-check';
import { IconReport } from '../../components/icons/report';
import MlipaApi from '../../../api/mlipa/endpoints';

export const DocumentsPage = () => {
    // redux
    const dispatch = useDispatch();
    const getTypes = useSelector(state => state.document.getTypes);
    const types = useSelector(state => state.document.types);
    const documents = useSelector(state => [...state.document.documents].reverse());

    // effects
    useEffect(() => {
      dispatch(documentGetTypes());
      dispatch(documentGetDocuments());
    }, []);

    // handlers
    const handleClick = (keyword) => {
      dispatch(showModal(MODAL_DOCUMENT_FORM, {type: keyword}));
    }

    const handleViewDocument = (d) => {
      // alert(JSON.stringify(d));

      let link = document.createElement('a');
      link.href = MlipaApi.domain + d.file;
      link.target = '_blank';
      link.setAttribute(
        'download',
        d.type,
      );

      // Append to html link element page
      document.body.appendChild(link);

      // Start download
      link.click();

      // Clean up and remove the link
      link.parentNode.removeChild(link);
    }

    return (
      <AppLayout>
          <div className="container">
              <ListHeaderComponent hideSearch={true} title="Documents - KYC" isLoading={getTypes.isLoading} />

              <div className="row mt-5 align-items-stretch">
                  {types.map(type => {
                    const document = documents.find(d => d.type == type.keyword);

                    return (
                      <div className='col-lg-3 text-black-50'>
                        <div className={styles.doc}>
                          {document ? (
                            <div className={`mt-2 ${document.approved ? 'text-success' : 'text-warning'}`} onClick={() => handleClick(type.keyword)}>
                              <IconCircledChecked className="mb-2" height="40px" width="40px" />
                              <span className="px-1"></span>
                              <span>{document.approved ? 'Approved' : 'Not Approved'}</span>
                            </div>
                          ) : (
                            <div className='mt-2' onClick={() => handleClick(type.keyword)}>
                              <IconAdd className="mb-2" height="33px" width="33px" />
                              <span className="px-1"></span>
                              <span>Add Document</span>
                            </div>
                          )}
                          <h5>{type.name}</h5>
                          <p>{type.description}</p>
                          {document ? (
                            <div className="btn-group">
                              <button className="btn btn-sm btn-outline-secondary" style={{ borderColor: '#eee' }} onClick={() => handleViewDocument(document)}>Download</button>
                              <button className="btn btn-sm btn-outline-secondary" style={{ borderColor: '#eee' }} onClick={() => handleClick(type.keyword)}>Change</button>
                          </div>
                          ) : <button className="btn btn-sm btn-outline-secondary" style={{ borderColor: '#eee' }} onClick={() => handleClick(type.keyword)}>Add Document</button>}
                        </div>
                        
                      </div>
                    );
                  })}
              </div>
          </div>
      </AppLayout>
    );
}
