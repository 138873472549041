import { createSlice } from '@reduxjs/toolkit';
import { createAsyncThunk } from '@reduxjs/toolkit';
import MlipaApi from '../../api/mlipa/endpoints';
import { customFetch } from '../../core/request';

// ACTION CONSTANTS
const NOTIFICATION_FETCH = 'notification/FETCH';
const NOTIFICATION_UPDATE = 'notification/UPDATE';

// ACTIONS
export const fetchNotifications = createAsyncThunk(
    NOTIFICATION_FETCH,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('GET', MlipaApi.notifications, null, token);
    }
);

export const updateNotification = createAsyncThunk(
    NOTIFICATION_UPDATE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('PUT', MlipaApi.notifications, null, token);
    }
);

const initialState = {
    notifications: [],
    fetchNotifications: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    },
    updateNotification: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {}
    }
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {},
    extraReducers: {
        [fetchNotifications.pending]: (state, action) => {
            state.fetchNotifications.isLoading = true;
            state.fetchNotifications.isSuccessful = false;
            state.fetchNotifications.error = null;
            state.fetchNotifications.data = {};
        },
        [fetchNotifications.fulfilled]: (state, action) => {
            state.fetchNotifications.isLoading = false;
            state.fetchNotifications.isSuccessful = true;
            state.fetchNotifications.error = null;
            state.fetchNotifications.data = action.payload;
            state.notifications = action.payload.data ?? [];
        },
        [fetchNotifications.rejected]: (state, action) => {
            state.fetchNotifications.isLoading = false;
            state.fetchNotifications.isSuccessful = false;
            state.fetchNotifications.error = action.error;
            state.fetchNotifications.data = {};
        },
        [updateNotification.pending]: (state, action) => {
            state.updateNotification.isLoading = true;
            state.updateNotification.isSuccessful = false;
            state.updateNotification.error = null;
            state.updateNotification.data = {};
        },
        [updateNotification.fulfilled]: (state, action) => {
            state.updateNotification.isLoading = false;
            state.updateNotification.isSuccessful = true;
            state.updateNotification.error = null;
            state.updateNotification.data = action.payload;
        },
        [updateNotification.rejected]: (state, action) => {
            state.updateNotification.isLoading = false;
            state.updateNotification.isSuccessful = false;
            state.updateNotification.error = action.error;
            state.updateNotification.data = {};
        },
    }
});

export default notificationSlice.reducer;