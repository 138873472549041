export function numberWithCommas(x) {
    if (x == null) return 0;
    if (x == 'undifined') return 0;
    if (isNaN(x)) return 0;
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function formatDate(date) {
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return `${date.getDate()} ${months[date.getMonth()]}, ${date.getFullYear()}`;
}

export function getCurrentMonth() {
    const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'Augost', 'September', 'October', 'November', 'December'];
    const date = new Date();
    return months[date.getMonth()];
}

export function getRandomBackgroundColor() {
    const colors = [
        {background: "#1abc9c", text: 'black'}, 
        {background: "#2ecc71", text: 'black'},
        {background: "#3498db", text: 'black'}, 
        {background: "#9b59b6", text: 'white'}, 
        {background: "#34495e", text: 'white'}, 
        {background: "#16a085", text: 'black'}, 
        {background: "#27ae60", text: 'black'}, 
        {background: "#2980b9", text: 'white'}, 
        {background: "#8e44ad", text: 'white'}, 
        {background: "#2c3e50", text: 'white'}, 
        {background: "#f1c40f", text: 'black'}, 
        {background: "#e67e22", text: 'black'}, 
        {background: "#e74c3c", text: 'black'}, 
        {background: "#95a5a6", text: 'black'}, 
        {background: "#f39c12", text: 'black'},
        {background: "#d35400", text: 'black'}, 
        {background: "#c0392b", text: 'white'}, 
        {background: "#bdc3c7", text: 'black'}, 
        {background: "#7f8c8d", text: 'black'}
    ];
    const index = Math.floor(Math.random() * colors.length);
    return colors[index];
}

export function nFormatter(num, digits) {
    const lookup = [
      { value: 1, symbol: "" },
      { value: 1e3, symbol: "k" },
      { value: 1e6, symbol: "M" },
      { value: 1e9, symbol: "B" },
      { value: 1e12, symbol: "T" },
      { value: 1e15, symbol: "P" },
      { value: 1e18, symbol: "E" }
    ];
    const rx = /\.0+$|(\.[0-9]*[1-9])0+$/;
    var item = lookup.slice().reverse().find(function(item) {
      return num >= item.value;
    });
    return item ? (num / item.value).toFixed(digits).replace(rx, "$1") + item.symbol : "0";
  }

//   export function startDownload(endpoint, params = {}, token) {
//     console.log(endpoint, params, token);
//     fetch(
//         endpoint,
//         {
//             method: 'GET',
//             headers: {
//                 'Content-Type': 'application/pdf',
//                 'Authorization': `Bearer ${token}`
//             }
//         })
//         .then((response) => {
//             console.log("Download Response")
//             console.log(response);
//             return response.blob()
//         })
//         .then((blob) => {
//             console.log("Blob Generated");
//             const url = window.URL.createObjectURL(new Blob([blob]));
//             const link = document.createElement('a');
//             link.href= url;
//             link.setAttribute('download', 'file.pdf');
//             document.body.appendChild(link);
//             link.click();
//             link.parentNode.removeChild(link);
//         }).catch(e => {
//             console.error({errorType: 'Download Error'});
//             console.error(e);
//         });
// }

export const CONTENT_TYPES = {
    pdf: 'application/pdf',
    excel: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
}

export const startDownload = async (endpoint, params, token, contentType) => {
    var url = new URL(endpoint);
    url.search = new URLSearchParams(params).toString();
    await fetch(
        url,
        {
            method: 'GET',
            headers: {
                'Content-Type': contentType,
                'Authorization': `Bearer ${token}`
            }
        })
        .then((response) => response.blob())
        .then((blob) => {
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href= url;
            link.setAttribute('download', 'file' + (CONTENT_TYPES.excel == contentType ? '.xlsx' : '.pdf'));
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
        }).catch(e => console.error(e));
}