import React from 'react';
import {render} from 'react-dom';
import App from './src/app';
import './src/assets/scss/main.scss';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import store, { persistor } from './src/application/store';
import { PersistGate } from 'redux-persist/integration/react';
import { createTheme, ThemeProvider } from '@mui/material';

const container = document.getElementById('app');
const root = createRoot(container);

const theme = createTheme({
    palette: {
      primary: {
        // Purple and green play nicely together.
        main: '#04619E',
      },
      secondary: {
        // This is green.A700 as hex.
        main: '#72BE46',
      },
    },
  });

root.render(
    <Provider store={ store }>
        <PersistGate loading={<p>Loading State...</p>} persistor={persistor}>
            <ThemeProvider theme={theme}>
                <App />
            </ThemeProvider>
        </PersistGate>
    </Provider>
);  