import React, { useState } from 'react'
import { useSelector } from 'react-redux';
import { CONTENT_TYPES, startDownload } from '../../../../core/helpers';
import { IconPdf } from '../../icons/pdf';

const ExportPdfButton = ({ endpoint, filters = {}}) => {
    // state
    const [isExportingPdf, setIsExportingPdf] = useState(false);

    // redux
    const token = useSelector(state => state.authentication.token);

    const handleExportExcel = async () => {
        // onDownload();
        // dispatch(transactionsExportExcel({params: filters}));
        setIsExportingPdf(true);
        await startDownload(endpoint, {...filters, format: 'pdf'}, token, CONTENT_TYPES.pdf);
        setIsExportingPdf(false);
    }

    return (
        <button className="btn btn-sm btn-secondary" disabled={isExportingPdf} onClick={handleExportExcel} title="Export PDF">{isExportingPdf ? <span className='spinner-border spinner-border-sm'></span> : <IconPdf height="15px" width="15px" />}</button>
    );
}

export default ExportPdfButton;
