import React from 'react'
import { IconNotification } from '../../icons/notification';
import { IconSettings } from '../../icons/settings';
import * as styles from './index.module.scss';
import { createRandomUser } from '../../../../api/faker/factories';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { logout } from '../../../../application/features/authentication';
import { getRandomBackgroundColor } from '../../../../core/helpers';
import { useState } from 'react';
import { hideModal, showModal } from '../../../../application/features/modal';
import { MODAL_ALERT } from '../modal';

const randomBackground = getRandomBackgroundColor();

export const Header = () => {
  // router
  const navigate = useNavigate();

  // state
  const [showAvatarDropdown, setShowAvatarDropdown] = useState(false);
  
  // redux
  const dispatch = useDispatch();
  const user = useSelector(state => state.authentication.user);

  // handlers
  const handleNotifications = () => {
    navigate("/notifications");
  }

  const handleSettings = () => {
    navigate("/settings");
  }

  const handleProfile = () => {
    setShowAvatarDropdown(false);
    handleSettings();
  }

  const handleLogout = () => {
    setShowAvatarDropdown(false);
    dispatch(showModal(MODAL_ALERT, {type: 'error', title: 'Logout', message: 'Are you sure you want to logout ?', actionLabel: 'Yes, Logout', actionCallback: handleLogoutCallback}))
  }

  const handleLogoutCallback = () => {
    dispatch(logout());
    dispatch(hideModal());
  }


  return (
    <div className={styles.header}>
      <div className="container h-100">
        <div className="d-flex h-100 align-items-center">
          <div className={styles.salutation}>Hi {user.firstname}! <strong>Welcome Back</strong></div>
          <div className={styles.actions}>
            <div onClick={handleNotifications}>
              <IconNotification height="32px" width="32px" />
            </div>
            <div onClick={handleSettings}>
              <IconSettings height="32px" width="32px" />
            </div>
          </div>
          <div className={styles.avatar} style={{ backgroundColor: `${randomBackground.background}`, color: `${randomBackground.text}` }} onClick={() => setShowAvatarDropdown(!showAvatarDropdown)}>
            <div className={styles.initials}>{user.firstname && user.firstname.slice(0,1)}{user.lastname && user.lastname.slice(0,1)}</div>

            <ul className={styles.menu} style={{ display: showAvatarDropdown ? 'block' : 'none' }}>
              <li onClick={handleProfile}>Settings</li>
              <li onClick={handleLogout}>Logout</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}