.inputMoney {
    label {
        display: block;
        font-weight: 400;
        font-size: 16px;
    }

    .input {
        
        display: block;
        width: 100%;

        border: solid 1px #CCD0E1;
        border-radius: 23px;

        padding: 8px 16px;

        font-size: 32px;
        font-weight: 500;
        display: flex;

        input {
            all: unset;
            flex: 1;
        }
    }

    .group {
        display: flex;
        margin-bottom: 15px;
        align-items: center;

        label {
            flex: 1;
        }

        select {
            border-radius: 10px;
            padding: 8px;
            font-size: 10px;
            font-weight: 600;
        }
    }
}