import React from 'react'

export const IconCardFilled = (props) => {
  return (
    <svg viewBox="0 0 16 16" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.4927 11.2142H6.82601C6.55001 11.2142 6.32601 10.991 6.32601 10.716C6.32601 10.4409 6.55001 10.2177 6.82601 10.2177H10.4927C10.7687 10.2177 10.9927 10.4409 10.9927 10.716C10.9927 10.991 10.7687 11.2142 10.4927 11.2142ZM5.66602 6.3571C5.66602 6.54113 5.51602 6.68928 5.33268 6.68928H2.66602C2.48135 6.68928 2.33268 6.54113 2.33268 6.3571V4.6962C2.33268 4.5135 2.48135 4.36402 2.66602 4.36402H5.33268C5.51602 4.36402 5.66602 4.5135 5.66602 4.6962V6.3571ZM4.82601 11.2142H2.82601C2.55001 11.2142 2.32601 10.991 2.32601 10.716C2.32601 10.4409 2.55001 10.2177 2.82601 10.2177H4.82601C5.10201 10.2177 5.32601 10.4409 5.32601 10.716C5.32601 10.991 5.10201 11.2142 4.82601 11.2142ZM14.3327 2.70312H1.66602C1.11402 2.70312 0.666016 3.14957 0.666016 3.69966V12.3363C0.666016 12.8871 1.11402 13.3329 1.66602 13.3329H14.3327C14.8853 13.3329 15.3327 12.8871 15.3327 12.3363V3.69966C15.3327 3.14957 14.8853 2.70312 14.3327 2.70312Z" />
    </svg>

  )
}
