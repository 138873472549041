import React, { useEffect, useState } from 'react'
import { getBanks } from '../../../api/faker/factories';
import { AppLayout } from '../../components/layout/app';
import ListHeaderComponent from '../../components/list/header';
import * as styles from './index.module.scss';
import { IconMoreVertical } from '../../components/icons/more-vertical';
import { IconBank } from '../../components/icons/bank';
import { useDispatch, useSelector } from 'react-redux';
import { bankDeleteBank, bankGetBanks } from '../../../application/features/bank';
import { hideModal, showModal } from '../../../application/features/modal';
import { MODAL_ALERT, MODAL_BANK_FORM } from '../../components/layout/modal';
import DropdownButton from '../../components/dropdown';
import { IconDelete } from '../../components/icons/delete';
import { IconEdit } from '../../components/icons/edit';

// const banks = getBanks();

export const BanksPage = () => {
    // state
    const [activeDropdown, setActiveDropdown] = useState(null);

    // redux
    const dispatch = useDispatch();
    const banks = useSelector(state => state.bank.banks);
    const getBanks = useSelector(state => state.bank.get);
    const createBank = useSelector(state => state.bank.create);
    const deleteBank = useSelector(state => state.bank.delete)

    // effect
    useEffect(() => {
        dispatch(bankGetBanks());
    }, [createBank.isSuccessfull])

    const handleSelectBank = (bank) => {
        if (bank == activeDropdown) {
            setActiveDropdown(null);
        } else {
            setActiveDropdown(bank);
        }
        console.log("Bank Selected");
    }

    const handleOnActionClicked = () => {
        dispatch(showModal(MODAL_BANK_FORM, {large: true}));
    }

    const handleOnOptionSelected = (option) => {
        const data = {
            id: option.id
        }
        // dispatch(bankDeleteBank(data));
        dispatch(showModal(MODAL_ALERT, {type: "", title: 'Delete Settlement Account', message: 'Are you sure you want to delete this settlement account?', actionLabel: "Yes, Delete", actionCallback: () => {
            dispatch(bankDeleteBank(data));
            dispatch(hideModal());
        }}))
    }

    const handleEdit = (bank) => {
        dispatch(showModal(MODAL_BANK_FORM, {large: true, bank: bank}));
    }
    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent hideSearch={true} isLoading={getBanks.isLoading || deleteBank.isLoading} title="Bank Accounts" actionOnClick={handleOnActionClicked} actionIcon={IconBank} />

                <div className="row mt-5">
                    <div className="col-lg-12 mx-auto">
                        <div className="card">
                            <div className="card-body">
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th width="300px">Bank Name</th>
                                            <th>Account Name</th>
                                            <th>Account Number</th>
                                            <th>Swift Code</th>
                                            <th>Phone</th>
                                            <th width="10"></th>
                                        </tr>
                                    </thead>

                                    <tbody>
                                        {banks.map(bank => {
                                            return (
                                                <tr>
                                                    <td style={{ verticalAlign: 'middle' }}>
                                                    {bank.bank_name}
                                                    </td>
                                                    <td style={{ verticalAlign: 'middle' }}>{bank.account_name}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{bank.account_no}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{bank.swift_code}</td>
                                                    <td style={{ verticalAlign: 'middle' }}>{bank.phone}</td>
                                                    <td style={{ verticalAlign: 'middle' }} onClick={() => handleSelectBank(bank)}>
                                                        <div className="btn-group">
                                                            {/* <button className="btn btn-outline-success btn-sm" onClick={() => handleDropdownOptionSelected({label: 'View', batch: batch})}><IconView height="15px" width="20px" /></button> */}
                                                            <button className="btn btn-outline-primary btn-sm" onClick={() => handleEdit(bank)} title="Edit"><IconEdit height="15px" width="15px" /></button>
                                                            <button className="btn btn-outline-danger btn-sm" onClick={() => handleOnOptionSelected({label: 'Delete', value: 'delete', id: bank.id})} title="Delete"><IconDelete height="15px" width="20px" /></button>
                                                        </div>
                                                        {/* <DropdownButton show={bank == activeDropdown} options={[{label: 'Delete', value: 'delete', id: bank.id}]} onOptionSelected={handleOnOptionSelected} /> */}
                                                    </td>
                                                </tr>
                                            )
                                        })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}