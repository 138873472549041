import { now } from 'moment';
import React, { useState } from 'react'
import { formatDate, numberWithCommas } from '../../../../../core/helpers';
import * as styles from '../index.module.scss';
import { useSelector } from 'react-redux';

const ReceiptSettlement = (props) => {
    // props
    // const { settlement } = props;

    // state
    const [today, setToday] = useState(new Date());

    // redux
    const settlement = useSelector(state => state.withdraw.settlement);

    return (
        <div className={styles.grid}>
            <div className={styles.cell}>
                <label htmlFor="">Date</label>
                <p>{formatDate(today)}</p>
            </div>
            <div className={styles.cell}>
                <label htmlFor="">Time</label>
                <p>{today.getHours()}:{today.getMinutes()}</p>
            </div>
            <div className={styles.cell}>
                <label htmlFor="">Oparator</label>
                <p>{settlement.bank.bank_name}</p>
            </div>
            <div className={styles.cell}>
                <label htmlFor="">A/C No</label>
                <p>{settlement.bank.account_no}</p>
            </div>
            <div className={styles.cell}>
                <label htmlFor="">Amount</label>
                <p>{numberWithCommas(settlement.payload.amount)} TZS</p>
            </div>
            <div className={styles.cell}>
                <label htmlFor="">Charge</label>
                <p>{numberWithCommas(settlement.charge)} TZS</p>
            </div>
            {/* <div className={styles.cell}>
                <label htmlFor="">IBAN</label>
                <p>FA12341617264578</p>
            </div> */}
        </div>
    );
}

export default ReceiptSettlement;