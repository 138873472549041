import React from 'react'
import { useDispatch } from 'react-redux';
import { createRandomUser } from '../../../../api/faker/factories';
import { showModal } from '../../../../application/features/modal';
import InputMoney from '../../form/money';
import { InputPaymentMethod } from '../../form/paymentmethod';
import UserSummaryComponent from '../../views/user-summary';
import * as styles from './index.module.scss';
import { MODAL_CONFIRM_PASSWORD, MODAL_SEND_MONEY_MOBILE_INFO } from '../../layout/modal/index';

const user = createRandomUser();

export const SendMoneyMobileInfoModal = () => {
    // redux
    const dispatch = useDispatch();

    // handlers
    const handleOnBackClicked = () => {
        dispatch(showModal(MODAL_SEND_MONEY_MOBILE_INFO));
    }
    const handleSend = () => {
        dispatch(showModal(MODAL_CONFIRM_PASSWORD, {onBackClicked: handleOnBackClicked}))
    }
  return (
    <div>
        <div className="mt-3"></div>
        <UserSummaryComponent user={user} />

        <div className="mt-5">
            <div className={styles.list}>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Network
                    </div>
                    <div className={styles.value}>
                        Tigo
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Amount
                    </div>
                    <div className={styles.value}>
                        $60
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Fee
                    </div>
                    <div className={styles.value}>
                        $10
                    </div>
                </div>
                <div className={styles.item}>
                    <div className={styles.label}>
                        Total
                    </div>
                    <div className={styles.value}>
                        $70
                    </div>
                </div>
            </div>
        </div>

        <div className="d-grid mt-3">
            <button className="btn btn-primary btn-lg" onClick={handleSend}>Send</button>
        </div>
    </div>
  )
}
