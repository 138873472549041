import React, { useEffect, useState } from 'react'
import Input from '../../form/input';
import { useDispatch, useSelector } from 'react-redux';
import { documentAddDocument, documentGetDocuments } from '../../../../application/features/document';
import { showModal } from '../../../../application/features/modal';
import { MODAL_ALERT } from '../../layout/modal';

export const DocumentForm = (props) => {
    // props
    const { type } = props;

    // state
    const [mounted, setMounted] = useState(false);
    const [name, setName] = useState();
    const [file, setFile] = useState()

    // redux
    const dispatch = useDispatch();
    const addDocument = useSelector(state => state.document.addDocument);

    // effects
    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        if (!mounted) return;
        if (addDocument.isSuccessfull) {
            dispatch(showModal(MODAL_ALERT, {title: 'Document Added', description: 'Document Added successfully'}));
            dispatch(documentGetDocuments());
        }
        if (addDocument.error) {
            alert('Sorry, Something went wrong');
        }
    }, [addDocument]);

    // handlers
    const handleFileChange = (files) => {
        var reader = new FileReader();
        reader.readAsDataURL(files[0]);
        reader.onload = () => {
            setFile(reader.result);
        }

        // reader.onerror = () => {
        //     alert('Something went wrong');
        // }
    }

    const handleAddDocument = () => {
        if (!file || !name) return;
        const data = {
            payload: {
                file: file,
                name: name,
                type: type,
            }
        }
        dispatch(documentAddDocument(data));
    }

    return (
        <div>
            <h3 className='text-center mt-4'>Add Document</h3>

            {/* <Input label="Document Name" placeholder='Document Name' name='name' onChange={(name, value) => setName(name)} />
            <div className="my-2"></div> */}
            <Input type="file" label="Attach Document" placeholder='Document Name' name='name' onFileChange={(name, files) => handleFileChange(files)} />
            <div className="my-3"></div>
            <button className="btn btn-primary" onClick={handleAddDocument}>Upload Document {addDocument.isLoading ? <span className='spinner-border spinner-border-sm'></span> : null}</button>
        </div>
    )
}
