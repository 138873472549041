import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import { DashboardPage } from './dashboard'
import { CustomerPage } from './customer/index';
import { NotFoundPage } from './notfound/index';
import { ComponentsPage } from './Components/index';
import { RootModal } from '../components/layout/modal/index';
import { LoginPage } from './Login';
import { SendPage } from './send/index';
import { SendMoneyMobilePage } from './send/mobile/index';
import { SendMoneyBankPage } from './send/bank/index';
import { WithdrawPage } from './withdraw/index';
import { TransactionsPage } from './transactions';
import { NotificationsPage } from './notifications/index';
import { SettingsPage } from './settings/index';
import { BanksPage } from './banks/index';
import { DocumentsPage } from './documents/index';
import { CreateBatchPage } from './send/batch/index';
import { ViewBatchPage } from './send/viewbatch/index';
import { RegisterPage } from './register';
import { RecieveMoneyPage } from './receivemoney';
import { WithdrawListPage } from './withdraw/List/index';
import { WithdrawReceiptPage } from './withdraw/Receipt/index';
import { MessagesPage } from './messages/index';
import { UserManagementPage } from './users/index';
import { RolesPage } from './roles/index';

export const PageRoutes = () => {
  return (
    <BrowserRouter>
        <Routes>
            <Route index element={<LoginPage />} />
            <Route path="register" element={<RegisterPage />} />

            <Route path="dashboard" element={<DashboardPage />} />
            <Route path="customers" element={<CustomerPage />} />
            <Route path="components" element={<ComponentsPage />} />
            
            <Route path="send" element={<SendPage />} />
            <Route path="send/mobile" element={<SendMoneyMobilePage />} />
            <Route path="send/bank" element={<SendMoneyBankPage />} />
            <Route path="send/batch" element={<CreateBatchPage />} />
            <Route path="send/batch/:batch_id" element={<ViewBatchPage />} />
            

            <Route path="withdraw" element={<WithdrawPage />} />
            <Route path="withdraw/receipt" element={<WithdrawReceiptPage />} />
            <Route path="withdrawals" element={<WithdrawListPage />} />
            <Route path="transactions" element={<TransactionsPage />} />

            <Route path="notifications" element={<NotificationsPage />} />
            <Route path="settings" element={<SettingsPage />} />

            <Route path="banks" element={<BanksPage />} />
            <Route path="documents" element={<DocumentsPage />} />

            <Route path="receive/money" element={<RecieveMoneyPage />} />
            <Route path="message" element={<MessagesPage />} />

            <Route path="users" element={<UserManagementPage />} />
            <Route path="roles" element={<RolesPage />} />

            <Route path="*" element={<NotFoundPage />} />
        </Routes>
        <RootModal />
    </BrowserRouter>
  )
}
