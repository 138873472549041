import React, { useEffect, useRef, useState } from 'react'
import { AppLayout } from '../../../components/layout/app/index';
import { Header } from '../../../components/layout/header';
import ListHeaderComponent from '../../../components/list/header/index';
import { useDispatch, useSelector } from 'react-redux';
import { withdrawGetSettlements } from '../../../../application/features/withdraw';
import { useNavigate } from 'react-router-dom';
import { IconWallet } from '../../../components/icons/wallet';
import ListPaginationComponent from '../../../components/list/pagination';
import { numberWithCommas } from '../../../../core/helpers';
import Input from '../../../components/form/input/index';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { IconExcel } from '../../../components/icons/excel';
import ExportExcelButton from '../../../components/widgets/export/excel';
import MlipaApi from '../../../../api/mlipa/endpoints';
import ExportPdfButton from '../../../components/widgets/export/pdf';
import { useCan } from '../../../hooks/can';
import { PERMISSION_SETTLEMENT_CREATE } from '../../../../core/permissions';

export const WithdrawListPage = () => {
    const tableRef = useRef();
    const maxDate = new Date();
    const minDate = new Date();
    minDate.setFullYear(minDate.getFullYear() - 1);
    const maxDateString = `${maxDate.getFullYear()}-${maxDate.getMonth() + 1}-${maxDate.getDate()}`;
    const minDateString = `${minDate.getFullYear()}-${minDate.getMonth() + 1}-${minDate.getDate()}`;

    // react router dom
    const navigate = useNavigate();

    // authorization
    const canCreateSettlement = useCan(PERMISSION_SETTLEMENT_CREATE);

    // hooks
    const { onDownload } = useDownloadExcel({
        currentTableRef: tableRef.current,
        filename: 'mlipa-withdrawals',
        sheet: 'Withdrawals'
    });

    // state
    const [filters, setFilters] = useState({});

    // redux
    const dispatch = useDispatch();
    const getSettlements = useSelector(state => state.withdraw.get);
    const settlements = useSelector(state => state.withdraw.settlements);

    // effects
    useEffect(() => {
        const data = {
            payload: filters,
        }
        dispatch(withdrawGetSettlements(data));
    }, [filters]);

    // handlers
    const handleClick = () => {
        navigate('/withdraw');
    }

    const handlePaginate = (page) => {
        setFilters(prevFilters => ({...prevFilters, page}));
    }

    const handleSearch = (search) => {
        setFilters(prevFilters => ({...prevFilters, search}));
    }

    const handleChange = (name, value) => {
        setFilters(prevFilters => ({...prevFilters, [name]: value}));
    }

    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent title="Withdrawals" actionOnClick={canCreateSettlement ? handleClick : null} actionIcon={IconWallet} actionTitle="Withdraw" isLoading={getSettlements.isLoading} searchOnChange={handleSearch}  />

                <div className="row">
                    <div className="col">
                    <div className="mt-3 d-flex align-items-end">
                            <div className="flex-grow-1"></div>
                            <div className="d-flex">
                                <Input type="date" name="date1" label="From" placeholder="From" min={minDateString} max={maxDateString} onChange={handleChange} />
                                <div className="px-2"></div>
                                <Input type="date" name="date2" label="To" placeholder="To" min={minDateString} max={maxDateString} onChange={handleChange} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col">
                    <div className="card">
                        <div className="card-body">
                            <div className="d-flex">
                                <div className="flex-grow-1"></div>
                                <ExportExcelButton endpoint={MlipaApi.exportSettlements} filters={filters} />
                                <div className="px-1"></div>
                                <ExportPdfButton endpoint={MlipaApi.exportSettlements} filters={filters} />
                            </div>
                            <table className="table" ref={tableRef}>
                                <thead>
                                    <tr>
                                        <th>Date</th>
                                        <th>Receipt</th>
                                        {/* <th>Account</th> */}
                                        <th>Initiator</th>
                                        <th>Status</th>
                                        <th className='text-right'>
                                            <div className="d-flex">
                                                <div className="flex-grow-1"></div>
                                                <span className='text-right'>Charge (TZS)</span>
                                            </div>
                                        </th>
                                        <th className='text-right'>
                                            <div className="d-flex">
                                                <div className="flex-grow-1"></div>
                                                <span className='text-right'>Amount (TZS)</span>
                                            </div>
                                        </th>
                                    </tr>
                                </thead>

                                <tbody>
                                    {settlements.map(settlement => {
                                        return (
                                            <tr>
                                                <td class="nowrap">{settlement.createdon}</td>
                                                <td>{settlement.settlement_id}</td>
                                                {/* <td>{settlement.account}</td> */}
                                                <td>{settlement.initiator_name}</td>
                                                <td>{settlement.status == 'SETTLED' ? 'COMPLETED' : settlement.status}</td>
                                                <td>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1"></div>
                                                        <span className='text-right'>{numberWithCommas(settlement.charge)}</span>
                                                    </div>

                                                </td>
                                                <td>
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1"></div>
                                                        <span className='text-right'>{numberWithCommas(settlement.amount)}</span>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                    <div className="my-2"></div>
                    <ListPaginationComponent currentPage={getSettlements.data.current_page} lastPage={getSettlements.data.last_page} onClick={handlePaginate} />
                </div>
            </div>
        </AppLayout>
    )
}
