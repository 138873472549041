import React from 'react'

export const IconTrash = (props) => {
  return (
        <svg xmlns="http://www.w3.org/2000/svg" version="1.1" viewBox="0 0 56.693 56.693" fill='currentColor' {...props}>
            <g>
                <path d="M20.535,45.52c0.69,0,1.248-0.558,1.248-1.248V22.32c0-0.689-0.559-1.248-1.248-1.248   c-0.688,0-1.244,0.559-1.244,1.248v21.952C19.291,44.962,19.847,45.52,20.535,45.52z"></path>
                <path d="M28.293,45.52c0.687,0,1.248-0.558,1.248-1.248V22.32c0-0.689-0.561-1.248-1.248-1.248   c-0.688,0-1.246,0.559-1.246,1.248v21.952C27.047,44.962,27.605,45.52,28.293,45.52z"></path>
                <path d="M36.05,45.52c0.688,0,1.246-0.558,1.246-1.248V22.32c0-0.689-0.559-1.248-1.246-1.248   c-0.69,0-1.246,0.559-1.246,1.248v21.952C34.804,44.962,35.361,45.52,36.05,45.52z"></path>
                <path d="M46.814,10.837h-8.982V8.174c0-2.034-1.654-3.687-3.688-3.687H22.441c-2.035,0-3.687,1.653-3.687,3.687   v2.663H9.769c-1.377,0-2.492,1.114-2.492,2.492c0,1.377,1.115,2.493,2.492,2.493h1.566v36.335c0,1.146,0.93,2.076,2.076,2.076   h29.762c1.146,0,2.076-0.929,2.076-2.076V15.822h1.564c1.377,0,2.492-1.116,2.492-2.493C49.306,11.951,48.191,10.837,46.814,10.837   z M22.078,8.174c0-0.202,0.16-0.366,0.363-0.366h11.703c0.201,0,0.365,0.164,0.365,0.366v2.663H22.078V8.174z M41.095,50.079H15.49   V15.822h25.605V50.079z"></path>
            </g>
        </svg>
  )
}
