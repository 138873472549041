import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { login } from "./authentication";

const initialState = {
    network: {
        unauthorized: false,
        offline: false,
    },
    loader: {
        isLoading: false,
        message: "Please Wait..."
    },
    session: {
        warningTime: 1000,
        timeout: 2000,
    },
};

const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateNetworkUnauthorized(state, action) {
            state.network.unauthorized = action.payload;
        },
        updateNetworkOffline(state, action) {
            state.network.offline = action.payload;
        },
        updateLoader(state, action) {
            state.loader.isLoading = action.payload.isLoading ?? false;
            state.loader.message = action.payload.isLoading && action.payload.message ? action.payload.message : 'Please Wait...';
        }
    },
    extraReducers: {
        [login.fulfilled]: (state, action) => {
            state.network.unauthorized = false;
        }
    },
});

export default appSlice.reducer;
export const { updateNetworkUnauthorized, updateLoader, updateNetworkOffline } = appSlice.actions

// selectors
export const selectorAppNetworkIsUnauthorized = (state) => state.app.network.unauthorized;