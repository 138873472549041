import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { useEffect } from 'react';
import { dashboardGetStatisticsDisbursement } from '../../../application/features/dashboard';
import Colors from '../../../assets/contants/colors';

const _dataset = {
    label: 'Disbursement',
    data: [],
    backgroundColor: Colors.PRIMARY_COLOR,
};

export const useDisbursementData = () => {
    // state
    const [dataSet, setDataSet] = useState(_dataset);

    // redux
    const dispatch = useDispatch();
    const disbursementGraph = useSelector(state => state.dashboard.disbursementGraph);

    // effects
    useEffect(() => {
        dispatch(dashboardGetStatisticsDisbursement());
    }, []);

    useEffect(() => {
        setDataSet(prevState => {
            return {
                ...prevState,
                data: Object.keys(disbursementGraph.data).map(key => disbursementGraph.data[key]),
            };
        });
    }, [disbursementGraph.data]);

    return {
        dataset: dataSet,
        isLoading: disbursementGraph.isFetching,
    };
}