import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { documentGetDocuments, documentGetTypes } from '../../../../application/features/document';
import { useNavigate } from 'react-router-dom';

const KycCompletion = (props) => {
    // props
    const { onChange } = props;

    // state
    const [completion, setCompletion] = useState({
        complete: false,
        steps: 0,
        completed: 0,
        comparator: [],
    });

    // router
    const navigate = useNavigate();

    //redux
    const dispatch = useDispatch();
    const getDocuments = useSelector(state => state.document.getDocuments);
    const getTypes = useSelector(state => state.document.getTypes);
    const documents = useSelector(state => state.document.documents);
    const types = useSelector(state => state.document.types);

    //effects
    useEffect(() => {
        dispatch(documentGetDocuments());
        dispatch(documentGetTypes());
    }, []);

    useEffect(() => {
        if (onChange) {
            onChange(completion);
        }
    }, [completion])

    useEffect(() => {
        if (!documents) return;
        if (!types) return;

        let _steps = types.length;
        let _comparator = types.map(type => {
            const document = documents.find(d => d.type == type.keyword);
            return {
                type_id: type.id,
                active: document ? true : false,
                type: type,
                document: document,
            }
        });
        let _completed = _comparator.filter(c => c.active).length;
        let _complete = _completed == _steps;

        setCompletion({
            complete: _complete,
            steps: _steps,
            completed: _completed,
            comparator: _comparator,
        })
    }, [documents, types])


  return (
    <div className={`alert ${completion.completed == 0 && 'alert-danger'} ${completion.completed > 0 && 'alert-warning'}`} >
        <p>The KYC is not completed, Click the button below to complete your KYC</p>
        <span>{completion.completed}/{completion.steps} documents</span> <br />
        <span>{completion.comparator.filter(c => c.document && c.document.approved).length}/{completion.steps} documents approved</span> <br />
        <button className="btn btn-primary" onClick={() => navigate('/documents')}>Complete KYC</button>
    </div>
  )
}

export default KycCompletion;