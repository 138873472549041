import React, { useEffect, useRef, useState } from 'react'
import { IconChevronDown } from '../../icons/chevron-down';
import * as styles from './index.module.scss';
import visa from '$assets/images/visa.png';
import online from '$assets/images/online.png';
import paypal from '$assets/images/paypal.png';
import Input from '../input';
import { IconCardFilled } from '../../icons/card';
import { IconCalenderFilled } from '../../icons/calender';
import { IconLockFilled } from '../../icons/lock-filled';

export const InputPaymentMethod = (props) => {
    // state
    const [show, setShow] = useState(false);
    const [top, setTop] = useState(102);

    // refs
    const selectRef = useRef();
    const dropdownRef = useRef();

    // effect
    useEffect(() => {
        if (!dropdownRef.current) return;
        const dropdownHeight = dropdownRef.current.clientHeight;
        const offsetBottom = dropdownRef.current.getBoundingClientRect().bottom;
        const excess = offsetBottom - window.innerHeight
        const selectHeight = selectRef.current.clientHeight;
        console.debug('view port height', window.innerHeight);
        console.debug('dropdown height', dropdownHeight);
        console.debug('excess', excess);
        console.debug('select height', selectHeight);

        if (excess > 0) {
            setTop(-(dropdownHeight - selectHeight + 24));
        }
    }, [dropdownRef, show]);
    


    return (
        <div className={styles.paymentmethods}>
            <label htmlFor="" className="mb-3">Payment Method</label>
            <div ref={selectRef} className={styles.choose} onClick={() => setShow(s => !s)}>
                <span>Choose Method</span>
                <IconChevronDown height="24px" width="24px" />
            </div>

            <div ref={dropdownRef} style={{ display: show ? 'block' : 'none', top: `${top}px` }} className={styles.popup}>
                <div className={styles.content}>
                    <div className={styles.selection}>
                        <div className={styles.header}>
                            <div class="form-check">
                                <input type="radio" className="form-check-input" id="radio1" name="optradio" value="option1" checked />Bank Cards
                                <label class="form-check-label" for="radio1"></label>
                            </div>
                            <img src={visa} alt="" srcset="" height="24px" />
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-12">
                                <Input placeholder="Card Number" trailingIcon={IconCardFilled} trailingIconProps={{ color: '#50647C' }} />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-lg-6">
                                <Input placeholder="MM/YY"  trailingIcon={IconCalenderFilled} trailingIconProps={{ color: '#50647C' }} />
                            </div>
                            <div className="col-lg-6">
                                <Input placeholder="CVV"  trailingIcon={IconLockFilled} trailingIconProps={{ color: '#50647C' }} />
                            </div>
                        </div>
                        <hr />
                    </div>

                    <div className={styles.selection}>
                        <div className={styles.header}>
                            <div class="form-check">
                                <input type="radio" className="form-check-input" id="radio1" name="optradio" value="option1" checked />Online Payment System
                                <label class="form-check-label" for="radio1"></label>
                            </div>
                            <img src={online} alt="" srcset="" height="24px" />
                        </div>

                        <p>Convinient method of payment using <br />YouMoney and Perfect Money Payment Systems</p>

                        <hr />
                    </div>

                    <div className={styles.selection}>
                        <div className={styles.header}>
                            <div class="form-check">
                                <input type="radio" className="form-check-input" id="radio1" name="optradio" value="option1" checked />Paypal
                                <label class="form-check-label" for="radio1"></label>
                            </div>
                            <img src={paypal} alt="" srcset="" height="16px" />
                        </div>

                        <Input placeholder="Enter your email" />
                    </div>
                </div>
            </div>
        </div>
    )
}
