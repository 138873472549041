import React from 'react'

export const IconDollarBox = (props) => {
  return (
    <svg viewBox="0 0 30 30" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M10.8398 17.9133C10.8398 19.5258 12.0773 20.8258 13.6148 20.8258H16.7523C18.0898 20.8258 19.1773 19.6883 19.1773 18.2883C19.1773 16.7633 18.5148 16.2258 17.5273 15.8758L12.4898 14.1258C11.5023 13.7758 10.8398 13.2383 10.8398 11.7133C10.8398 10.3133 11.9273 9.17578 13.2648 9.17578H16.4023C17.9398 9.17578 19.1773 10.4758 19.1773 12.0883"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15 7.5V22.5"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M18.75 27.5H11.25C5 27.5 2.5 25 2.5 18.75V11.25C2.5 5 5 2.5 11.25 2.5H18.75C25 2.5 27.5 5 27.5 11.25V18.75C27.5 25 25 27.5 18.75 27.5Z"  strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  )
}
