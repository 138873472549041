
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import MlipaApi from '../../api/mlipa/endpoints';
import { requestInitialState } from '../../core/data-structures';
import { customFetch } from '../../core/request';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { createAvatar } from '@beyonk/initials-avatar';
const { createWriteStream } = require('fs');

// ACTION CONSTANTS
const AUTH_LOGIN_ACTION = 'auth/LOGIN_ACTION';
const AUTH_GENERATE_AVATAR = `auth/GENERATE_AVATAR`;
const AUTH_CONFIRM_PASSWORD = `auth/CONFIRM_PASSWORD`;
const AUTH_GET_USERS = `auth/GET_USERS`;
const AUTH_CREATE_USER = `auth/CREATE_USER`;
const AUTH_UPDATE_USER = `auth/UPDATE_USER`;
const AUTH_RETRIEVE_USER = `auth/RETRIEVE_USER`;
const AUTH_DELETE_USER = `auth/DELETE_USER`;
const AUTH_GET_ROLES = `auth/GET_ROLES`;
const AUTH_ASSIGN_ROLE = `auth/ASSIGN_ROLE`;
const AUTH_REVOKE_ROLE = `auth/REVOKE_ROLE`;


// ACTIONS
export const login = createAsyncThunk(
    AUTH_LOGIN_ACTION,
    async (payload, store) => {
        return await customFetch('POST', MlipaApi.login, payload);
    }
)

export const generateUserAvatar = createAsyncThunk(
    AUTH_GENERATE_AVATAR,
    async (data, store) => {
        const output = createWriteStream('$assets/avatars/user.jpg');
        await createAvatar({ firstName: 'Antony', lastName: 'MacKenzie-Jones' }, output);
        return output;
    }
)

export const authConfirmPassword = createAsyncThunk(
    AUTH_CONFIRM_PASSWORD, 
    async (payload, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', MlipaApi.passwordConfirm, payload, token, store);
    }
);

export const getUsers = createAsyncThunk(
    AUTH_GET_USERS,
    async (payload, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', MlipaApi.users, payload, token, store);
    }
);

export const getRoles = createAsyncThunk(
    AUTH_GET_ROLES,
    async (payload, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', MlipaApi.roles, payload, token, store);
    }
);

export const assignRole = createAsyncThunk(
    AUTH_ASSIGN_ROLE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', MlipaApi.assignRole(data.id), data.payload, token, store);
    }
);

export const revokeRole = createAsyncThunk(
    AUTH_REVOKE_ROLE,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', MlipaApi.revokeRole(data.id), data.payload, token, store);
    }
);

export const createUser = createAsyncThunk(
    AUTH_CREATE_USER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('POST', MlipaApi.users, data.payload, token, store);
    }
)

export const updateUser = createAsyncThunk(
    AUTH_UPDATE_USER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('PUT', MlipaApi.user(data.id), data.payload, token, store);
    }
)

export const retrieveUser = createAsyncThunk(
    AUTH_RETRIEVE_USER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('GET', MlipaApi.user(data.id), null, token, store);
    }
);

export const deleteUser = createAsyncThunk(
    AUTH_DELETE_USER,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return await customFetch('DELETE', MlipaApi.user(data.id), null, token, store);
    }
)

// export const getUsers = createAsyncThunk(AUTH

const initialState = {
    token: null,
    user: {},
    permissions: {},
    userAvatar: null,
    users: [],
    roles: [],
    login: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    confirmPassword: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    getUsers: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    retrieveUser: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    createUser: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    updateUser: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    deleteUser: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    getRoles: {
        isLoading: false,
        isSuccessful: false,
        error: null,
        data: {},
    },
    assignRole: {
        isLoading: [],
        isSuccessful: [],
        errors: [],
        data: [],
    },
    revokeRole: {
        isLoading: [],
        isSuccessful: [],
        errors: [],
        data: [],
    }
    // otpRequest: requestInitialState,
    // otpValidate: requestInitialState,
    // changePassword: requestInitialState,
    // resetPassword: requestInitialState,
}

const authenticationSlice = createSlice({
    name: 'authentication',
    initialState,
    reducers: {
        logout(state, action) {
            state.token = null;
            state.user = {};
            state.permissions = {};
        }
    },
    extraReducers: {
        [login.pending]: (state, action) =>  {
            state.login.isLoading = true;
            state.login.isSuccessful = false;
            state.login.error = null;
            state.login.data = {}
        },
        [login.fulfilled]: (state, action) =>  {
            state.login.isLoading = false;
            state.login.isSuccessful = true;
            state.login.error = null;
            state.login.data = action.payload;
            state.token = action.payload.data.token;
            state.user = action.payload.data.name;
            state.permissions = action.payload.data.permissions;
        },
        [login.rejected]: (state, action) =>  {
            state.login.isLoading = false;
            state.login.isSuccessful = false;
            state.login.error = "Login Failed";
            state.login.data = {}
        },
        [generateUserAvatar.fulfilled]: (state, action) => {
            state.userAvatar = action.payload;
        },
        [authConfirmPassword.pending]: (state, action) => {
            state.confirmPassword.isLoading = true;
            state.confirmPassword.isSuccessful = false;
            state.confirmPassword.error = null;
            state.confirmPassword.data = {}
        },
        [authConfirmPassword.fulfilled]: (state, action) => {
            state.confirmPassword.isLoading = false;
            state.confirmPassword.isSuccessful = true;
            // state.confirmPassword.error = null;
            state.confirmPassword.data = action.payload;
        },
        [authConfirmPassword.rejected]: (state, action) => {
            state.confirmPassword.isLoading = false;
            state.confirmPassword.isSuccessful = false;
            state.confirmPassword.error = action.payload;
            // state.confirmPassword.data = {}
        },
        [getUsers.pending]: (state, action) => {
            state.getUsers.isLoading = true;
            state.getUsers.isSuccessful = false;
            state.getUsers.error = null;
            state.getUsers.data = {}
        },
        [getUsers.fulfilled]: (state, action) => {
            state.getUsers.isLoading = false;
            state.getUsers.isSuccessful = true;
            state.getUsers.error = null;
            state.getUsers.data = action.payload;
            state.users = action.payload.data;
        },
        [getUsers.rejected]: (state, action) => {
            state.getUsers.isLoading = false;
            state.getUsers.isSuccessful = false;
            state.getUsers.error = action.payload;
            state.getUsers.data = {}
        },
        [retrieveUser.pending]: (state, action) => {
            state.retrieveUser.isLoading = true;
            state.retrieveUser.isSuccessful = false;
            state.retrieveUser.error = null;
            state.retrieveUser.data = {}
        },
        [retrieveUser.fulfilled]: (state, action) => {
            state.retrieveUser.isLoading = false;
            state.retrieveUser.isSuccessful = true;
            state.retrieveUser.error = null;
            state.retrieveUser.data = action.payload;
            state.users = state.users.map(user => user.id == action.meta.arg.id ? action.payload.data : user);
        },
        [retrieveUser.rejected]: (state, action) => {
            state.retrieveUser.isLoading = false;
            state.retrieveUser.isSuccessful = false;
            state.retrieveUser.error = action.payload;
            state.retrieveUser.data = {}
        },
        [createUser.pending]: (state, action) => {
            state.createUser.isLoading = true;
            state.createUser.isSuccessful = false;
            state.createUser.error = null;
            state.createUser.data = {}
        },
        [createUser.fulfilled]: (state, action) => {
            state.createUser.isLoading = false;
            state.createUser.isSuccessful = true;
            state.createUser.error = null;
            state.createUser.data = action.payload;
            state.users.unshift(action.payload.data);
        },
        [createUser.rejected]: (state, action) => {
            state.createUser.isLoading = false;
            state.createUser.isSuccessful = false;
            state.createUser.error = action.payload;
            state.createUser.data = {}
        },
        [updateUser.pending]: (state, action) => {
            state.updateUser.isLoading = true;
            state.updateUser.isSuccessful = false;
            state.updateUser.error = null;
            state.updateUser.data = {}
        },
        [updateUser.fulfilled]: (state, action) => {
            state.updateUser.isLoading = false;
            state.updateUser.isSuccessful = true;
            state.updateUser.error = null;
            state.updateUser.data = action.payload;
            state.users.map(user => user.id == action.meta.arg.id ? action.payload.data : user);
        },
        [updateUser.rejected]: (state, action) => {
            state.updateUser.isLoading = false;
            state.updateUser.isSuccessful = false;
            state.updateUser.error = action.payload;
            state.updateUser.data = {}
        },
        [deleteUser.pending]: (state, action) => {
            state.deleteUser.isLoading = true;
            state.deleteUser.isSuccessful = false;
            state.deleteUser.error = null;
            state.deleteUser.data = {}
        },
        [deleteUser.fulfilled]: (state, action) => {
            state.deleteUser.isLoading = false;
            state.deleteUser.isSuccessful = true;
            state.deleteUser.error = null;
            state.deleteUser.data = action.payload;
            state.users = state.users.filter(user => user.id != action.meta.arg.id);
        },
        [deleteUser.rejected]: (state, action) => {
            state.deleteUser.isLoading = false;
            state.deleteUser.isSuccessful = false;
            state.deleteUser.error = action.payload;
            state.deleteUser.data = {}
        },
        [getRoles.pending]: (state, action) => {
            state.getRoles.isLoading = true;
            state.getRoles.isSuccessful = false;
            state.getRoles.error = null;
            state.getRoles.data = {}
        },
        [getRoles.fulfilled]: (state, action) => {
            state.getRoles.isLoading = false;
            state.getRoles.isSuccessful = true;
            state.getRoles.error = null;
            state.getRoles.data = action.payload;
            state.roles = action.payload.data;
        },
        [getRoles.rejected]: (state, action) => {
            state.getRoles.isLoading = false;
            state.getRoles.isSuccessful = false;
            state.getRoles.error = action.payload;
            state.getRoles.data = {}
        },
        [assignRole.pending]: (state, action) => {
            state.assignRole.isLoading.push(action.meta.arg.reqID);
            state.assignRole.isSuccessful = state.assignRole.isSuccessful.filter(id => id !== action.meta.arg.reqID);
            state.assignRole.errors = state.assignRole.errors.filter(error => error.id !== action.meta.arg.reqID);
            state.assignRole.data = state.assignRole.data.filter(data => data.id !== action.meta.arg.reqID);
        },
        [assignRole.fulfilled]: (state, action) => {
            state.assignRole.isLoading =  state.assignRole.isLoading.filter(id => id !== action.meta.arg.reqID);
            state.assignRole.isSuccessful.push(action.meta.arg.reqID);
            state.assignRole.data.push({id: action.meta.arg.reqID, data: action.payload});

            state.users = state.users.map(user => {
                if (user.id === action.payload.data.id) {
                    return action.payload.data;
                }
                return user;
            });

            if (state.user.id === action.payload.data.id) {
                state.user = action.payload.data;
            }
        },
        [assignRole.rejected]: (state, action) => {
            state.assignRole.isLoading =  state.assignRole.isLoading.filter(id => id !== action.meta.arg.reqID);
            state.assignRole.isSuccessful.push(action.meta.arg.reqID);
            state.assignRole.errors.push({id: action.meta.arg.reqID, error: action.payload});
        },
        [revokeRole.pending]: (state, action) => {
            state.revokeRole.isLoading.push(action.meta.arg.reqID);
            state.revokeRole.isSuccessful = state.revokeRole.isSuccessful.filter(id => id !== action.meta.arg.reqID);
            state.revokeRole.errors = state.revokeRole.errors.filter(error => error.id !== action.meta.arg.reqID);
            state.revokeRole.data = state.revokeRole.data.filter(data => data.id !== action.meta.arg.reqID);
        },
        [revokeRole.fulfilled]: (state, action) => {
            state.revokeRole.isLoading = state.revokeRole.isLoading.filter(id => id !== action.meta.arg.reqID);
            state.revokeRole.isSuccessful.push(action.meta.arg.reqID);
            state.revokeRole.data.push({id: action.meta.arg.reqID, data: action.payload});

            state.users = state.users.map(user => {
                if (user.id === action.payload.data.id) {
                    return action.payload.data;
                }
                return user;
            });

            if (state.user.id === action.payload.data.id) {
                state.user = action.payload.data;
            }
        },
        [revokeRole.rejected]: (state, action) => {
            state.revokeRole.isLoading = state.revokeRole.isLoading.filter(id => id !== action.meta.arg.reqID);
            state.revokeRole.isSuccessful.push(action.meta.arg.reqID);
            state.revokeRole.errors.push({id: action.meta.arg.reqID, error: action.payload});
        },
    }
})

const authenticationPersistConfig = {
    key: 'authentication',
    storage: storage,
    whitelist: ['token', 'user', 'permissions', 'userAvatar', 'roles'],
  }

export default persistReducer(authenticationPersistConfig, authenticationSlice.reducer);
export const { logout } = authenticationSlice.actions;