import app from "./features/app"
import modal from "./features/modal";
import authentication from "./features/authentication";
import dashboard from "./features/dashboard";
import customer from "./features/customer";
import bank from "./features/bank";
import disbursement from "./features/disbursement";
import transaction from "./features/transaction";
import document from "./features/document";
import withdraw from "./features/withdraw";
import notification from "./features/notification";

export default {
    app,
    authentication,
    dashboard,
    customer,
    bank,
    document,
    disbursement,
    transaction,
    withdraw,
    modal,
    notification,
}