import React, { useEffect, useState } from 'react'
import { AppLayout } from '../../components/layout/app'
import ListHeaderComponent from '../../components/list/header'
import { createRandomNotification } from '../../../api/faker/factories';
import { formatDate } from '../../../core/helpers';
import { IconMoreVertical } from '../../components/icons/more-vertical';
import { IconSendMoney } from '../../components/icons/send-money';
import { IconReceiveMoney } from '../../components/icons/receive-money';
import * as styles from './index.module.scss';
import moment from 'moment';
import { IconCheck } from '../../components/icons/check';
import { IconCheckV2 } from '../../components/icons/check-v2';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { fetchNotifications } from '../../../application/features/notification';
import art from '$assets/images/no_notifications.svg';

export const NotificationsPage = () => {
    // redux
    const dispatch = useDispatch();
    const notifications = useSelector(state => state.notification.notifications);

    // effects
    useEffect(() => {
        dispatch(fetchNotifications());
    }, []);

    return (
        <AppLayout>
            <div className="container">
                <ListHeaderComponent title="Notifications" />

                {!notifications.lenght && (
                    <div className="d-flex flex-column justify-content-center align-items-center bg-light h-100 mt-5">
                        <img src={art} alt="" srcset="" height="200px" />
                        <h3 className='mt-3'>All Clear!</h3>
                        <p className='text-black-50'>You do not have any unread notifications currently.</p>
                    </div>
                )}

                <div className="mt-4">
                    <table className="table">
                        <tbody>
                            {notifications.map(notification => {
                                return (
                                    <tr>
                                        <td width="100px">
                                            <div className="px-4">
                                                {['PAY_OUT'].includes(notification.data.type) ? (
                                                    <div className={styles.sendIcon}>
                                                        <IconSendMoney height="28px" width="28px" />
                                                    </div>
                                                ) : (
                                                    <div className={styles.receiveIcon}>
                                                        <IconReceiveMoney height="28px" width="28px" />
                                                    </div>
                                                )}
                                            </div>
                                        </td>
                                        <td>
                                            {['PAY_OUT'].includes(notification.data.type) ? (
                                                <div className={`${styles.badge} ${styles.send}`}>
                                                {notification.data.type.split('_').join(' ')}
                                                </div>
                                            ) : (
                                                <div className={`${styles.badge} ${styles.receive}`}>
                                                    {notification.data.type.split('_').join(' ')}
                                                </div>
                                            )}
                                        </td>
                                        <td valign="middle">
                                            <div className={styles.description}>
                                                {['PAY_OUT'].includes(notification.data.type) ? (
                                                    <p>{notification.data.content}</p>
                                                    // <p>You sent <span>{notification.data.amount}</span> to <span className={styles.blue}>{notification.data.to}</span> via <br /> <span>{notification.data.from}</span></p>
                                                ) : (
                                                    <p>{notification.data.content}</p>
                                                    // <p>You received {notification.amount} from <br />  <span className={styles.green}>{notification.username}</span> via {notification.paymentmethod}</p>
                                                )}
                                            </div>
                                        </td>
                                        <td valign="middle">{formatDate(new Date(notification.created_at))} • {(new Date(notification.created_at)).getUTCHours()}:{(new Date(notification.created_at)).getMinutes()}</td>
                                        <td valign="middle" width="50" className='text-success'><IconCheckV2 height="22px" width="22px" /></td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </AppLayout>
    );
}
