.wrapper {
    border-right: solid 1px #E0E5EB;
    height: 100vh;
    overflow: scroll;
    background-color: white;

    display: flex;
    flex-direction: column;
    align-items: center;

    .logo {
        max-height: 50px;
        margin: 30px 0;
    }
}