export const customFetch = async (method, endpoint, data, token, thunkApi, headers = {}) => {
    var url = new URL(endpoint);

    if (method == 'GET' && data) {
        url.search = new URLSearchParams(data).toString();
    }

    if ((method == 'POST' || method == 'PUT') && data) {
        var formData = jsonToFormData(data);
    } else {
        var formData = new FormData();
    }

    let response;
    try {
        response = await fetch(url, {
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Accept': headers.Accept ? headers.Accept : 'application/json',
                'Authorization': token ? ('Bearer ' + token) : null,
                'Access-Control-Allow-Origin': "*",
                ...headers,
                // 'Content-Type': 'application/json',
            },
            redirect: 'error', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
            body: ((method == 'POST' || method == 'PUT') && data) ? formData : null, // body data type must match "Content-Type" header
        }).catch((error) => {
            console.log("Fetch Error");
            console.debug(error);
        });
    } catch (error) {
        console.log("Fetch Error From Try and Catch");
        console.debug(error);
    }
    
    console.log("Network Response");
    console.debug(response);
    if (!response) {
        if(thunkApi != null) {
            return thunkApi.rejectWithValue({message: 'Network Error', network_status: 'NO_INTERNET'});
        } else {
            throw JSON.stringify({message: 'Network Error', network_status: 'NO_INTERNET'});
        }
    }



    if (response.status == 204) { // Special Case
        return {body: response.body, network_status: 'OK'};
    }

    if (headers['Content-Type'] == 'application/json') {
        console.log('Downloading file');
        try {
            let _blob = response.blob();
            const url = window.URL.createObjectURL(new Blob([blob]));
            const link = document.createElement('a');
            link.href= url;
            link.setAttribute('download', 'file.pdf');
            document.body.appendChild(link);
            link.click();
            link.parentNode.removeChild(link);
            return url;
        } catch (error) {
            throw error;
        }
    }

    const results = await response.json();
    // console.log(results);
    if (response.status >= 200 && response.status < 300) {
        console.log("HTTP RESPONSE: ", results);
        
        if (results.errors) {
            if(thunkApi != null) {
                return thunkApi.rejectWithValue({...results, network_status: 'OK'})
            } else {
                throw JSON.stringify({...results.errors, network_status: 'OK'});
            }
        }
        
        return {...results, network_status: 'OK'};
    }

    /**
     * If the Response is Unauthenticated then refresh token or redirect to Login
     */
    if(response.status == 401) {
        throw 'UNAUTHORIZED';
    }


    if (response.status == 422) {
        if(thunkApi != null) {
            return thunkApi.rejectWithValue({...results, network_status: 'OK'})
        } else {
            throw JSON.stringify({...results, network_status: 'OK'});
        }
    }

    //error handling
    if (response.status == 500) {
        throw "Server Error";
    }

    if(thunkApi != null) {
        return thunkApi.rejectWithValue({...results, network_status: 'OK'})
    } else {
        throw JSON.stringify({...results, network_status: 'OK'});
    }
}

// Converting json to FormData
function jsonToFormData(data) {
    const formData = new FormData();
    buildFormData(formData, data);
    return formData;
}
function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File)) {
        Object.keys(data).forEach(key => {
            buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
        });
    } else {
        const value = data == null ? '' : data;
        formData.append(parentKey, value);
    }
}
