import React from 'react'

export const IconAddUser = (props) => {
  return (
    <svg viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path fillRule="evenodd" clipRule="evenodd" d="M2.86264 11.5639C4.04767 11.1666 5.61724 11.0469 7.23214 11.0469C8.85688 11.0469 10.4271 11.1713 11.6108 11.5738C12.2058 11.7762 12.7488 12.0637 13.1484 12.4825C13.5624 12.9163 13.7963 13.4651 13.7963 14.1135C13.7963 14.7634 13.5603 15.3118 13.1439 15.744C12.7423 16.1609 12.1974 16.4456 11.6017 16.6454C10.4167 17.0428 8.8471 17.1627 7.23214 17.1627C5.60739 17.1627 4.03713 17.0383 2.85351 16.6358C2.25843 16.4334 1.71548 16.1459 1.31587 15.7271C0.90185 15.2932 0.667969 14.7445 0.667969 14.096C0.667969 13.4461 0.903924 12.8975 1.32038 12.4652C1.722 12.0483 2.26695 11.7636 2.86264 11.5639ZM2.22061 13.3324C2.02399 13.5365 1.91797 13.776 1.91797 14.096C1.91797 14.4171 2.02388 14.6584 2.2202 14.8642C2.43093 15.085 2.76886 15.2867 3.25597 15.4523C4.23672 15.7859 5.63605 15.9127 7.23214 15.9127C8.8205 15.9127 10.2205 15.7901 11.2043 15.4602C11.693 15.2964 12.0322 15.0963 12.2437 14.8768C12.4403 14.6727 12.5463 14.4333 12.5463 14.1135C12.5463 13.7924 12.4404 13.5512 12.2441 13.3454C12.0333 13.1246 11.6954 12.9229 11.2083 12.7572C10.2275 12.4237 8.82822 12.2969 7.23214 12.2969C5.6437 12.2969 4.24369 12.4192 3.25997 12.749C2.77128 12.9129 2.43206 13.1129 2.22061 13.3324Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M2.80078 5.09768C2.80078 2.63908 4.77486 0.666016 7.23245 0.666016C9.69096 0.666016 11.6641 2.63917 11.6641 5.09768C11.6641 7.55527 9.69105 9.52935 7.23245 9.52935C4.77477 9.52935 2.80078 7.55536 2.80078 5.09768ZM7.23245 1.91602C5.46504 1.91602 4.05078 3.32962 4.05078 5.09768C4.05078 6.865 5.46513 8.27935 7.23245 8.27935C9.00051 8.27935 10.4141 6.86509 10.4141 5.09768C10.4141 3.32953 9.0006 1.91602 7.23245 1.91602Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M15.0039 5.59961C15.3491 5.59961 15.6289 5.87943 15.6289 6.22461V9.56628C15.6289 9.91145 15.3491 10.1913 15.0039 10.1913C14.6587 10.1913 14.3789 9.91145 14.3789 9.56628V6.22461C14.3789 5.87943 14.6587 5.59961 15.0039 5.59961Z" />
        <path fillRule="evenodd" clipRule="evenodd" d="M12.6758 7.89453C12.6758 7.54935 12.9556 7.26953 13.3008 7.26953H16.7091C17.0543 7.26953 17.3341 7.54935 17.3341 7.89453C17.3341 8.23971 17.0543 8.51953 16.7091 8.51953H13.3008C12.9556 8.51953 12.6758 8.23971 12.6758 7.89453Z" />
    </svg>
  )
}
