import React from 'react'

export const IconGroup = (props) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M16.1289 10.0201C17.5977 10.0201 18.7892 8.82946 18.7892 7.36065C18.7892 5.89185 17.5977 4.70117 16.1289 4.70117" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M17.3516 12.9102C17.7902 12.9404 18.2263 13.0026 18.6557 13.0992C19.2523 13.216 19.9699 13.4605 20.2253 13.9958C20.3883 14.3386 20.3883 14.7378 20.2253 15.0814C19.9707 15.6167 19.2523 15.8604 18.6557 15.9831" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M5.76579 10.0201C4.29698 10.0201 3.10547 8.82946 3.10547 7.36065C3.10547 5.89185 4.29698 4.70117 5.76579 4.70117" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M4.54268 12.9102C4.10405 12.9404 3.66795 13.0026 3.23856 13.0992C2.64197 13.216 1.92437 13.4605 1.66977 13.9958C1.50591 14.3386 1.50591 14.7378 1.66977 15.0814C1.92353 15.6167 2.64197 15.8604 3.23856 15.9831" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.9428 13.4824C13.919 13.4824 16.4617 13.9328 16.4617 15.7352C16.4617 17.5368 13.9358 18.004 10.9428 18.004C7.96567 18.004 5.42383 17.5536 5.42383 15.7512C5.42383 13.9488 7.9497 13.4824 10.9428 13.4824Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path fillRule="evenodd" clipRule="evenodd" d="M10.943 10.9122C8.98009 10.9122 7.40625 9.33835 7.40625 7.37462C7.40625 5.41173 8.98009 3.83789 10.943 3.83789C12.9059 3.83789 14.4797 5.41173 14.4797 7.37462C14.4797 9.33835 12.9059 10.9122 10.943 10.9122Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>

  )
}
