import React, { useEffect, useState } from 'react'
import { PropTypes } from 'prop-types';
import * as styles from './index.module.scss';
import PinInput from 'react-pin-input';
import CurrencyInput from 'react-currency-input-field';
import ReactDatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { moment } from 'moment/moment';

const Input = (props) => {
    // props
    const { type, name, label, placeholder, value, min, max, onChange, onFileChange, onKeyDown,  error, leadingIcon: LeadingIcon, leadingIconProps, trailingIcon: TrailingIcon, trailingIconProps } = props;

    // state
    const [inputValue, setInputValue] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [mounted, setMounted] = useState(false);

    // effects
    useEffect(() => {
        setMounted(true);
    }, []);

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    useEffect(() => {
        if (!mounted) return;
        handleChange(null, name, `${startDate.getFullYear()}-${startDate.getMonth() + 1}-${startDate.getDate()}`);
    }, [startDate]);

    // handlers
    const handleChange = (e, name, value) => {
        let _value = value;
        if (type == 'phone') {
            _value = phoneFieldPipe(value);
        }


        if (onChange) {
            onChange(name, _value);
        }

        if (e && onFileChange) {
            onFileChange(name, e.target.files);
        }
        
        setInputValue(_value);
    }

    const phoneFieldPipe = (input) => {
        if (isNaN(input)) {
            return value;
        }
        if (input.length > 12) {
            return value;
        }
        let _input = input;
        if (_input.charAt(0) == 0) {
            _input = '255' + _input.slice(1);
        }
        return _input;
    }

    // return <span>{type}</span>

    if (type == 'pin') {
        return (
            <PinInput
                length={4}
                inputStyle={pinInputStype}
            />
        )
    }

    if (type == 'textarea') {
        return (
            <div className={`${styles.input} form-group`}>
                {label && <label className='label-control'>{label}</label>}
                <textarea min={min} max={max} className={`form-control flex-grow-1 ${error ? 'is-invalid' : ''}`} placeholder={placeholder} onChange={(e) => handleChange(e, name, e.target.value)}>{inputValue}</textarea>  
                {error && <p className={styles.isInvalid}>{error}</p>}
            </div>
        )
    }
    
    if (type == 'date') {
        // return null
        return (
            <div className={`${styles.input} form-group`}>
                {label && <label className='label-control'>{label}</label>}
                <div className={`${styles.group}  ${error ? styles.isInvalid : ''}`}>
                    {LeadingIcon && <LeadingIcon height="22" width="22" {...leadingIconProps} />}
                    <ReactDatePicker showYearDropdown={true} minDate={new Date(min)} maxDate={new Date(max)} selected={startDate} onChange={(date) => setStartDate(date)} />
                    {/* <input type={type} min={min} max={max} className={`form-control flex-grow-1`} value={inputValue} placeholder={placeholder} onChange={(e) => handleChange(e, name, e.target.value)} onKeyDown={onKeyDown} /> */}
                    {TrailingIcon && <TrailingIcon height="22" width="22" {...trailingIconProps} />}
                </div>
                {error && <p className={styles.isInvalid}>{error}</p>}
            </div>
        )
    }

    return (
        <div className={`${styles.input} form-group`}>
            {label && <label className='label-control'>{label}</label>}
            <div className={`${styles.group}  ${error ? styles.isInvalid : ''}`}>
                {LeadingIcon && <LeadingIcon height="22" width="22" {...leadingIconProps} />}
                {type == 'currency' ? <CurrencyInput prefix="TZS " style={{ textAlign: 'right' }} name={name} value={inputValue} placeholder={placeholder} onValueChange={(value, name) => handleChange(null, name, value)} /> : <input size="1" type={type} min={min} max={max} className={`form-control flex-grow-1`} value={inputValue} placeholder={placeholder} onChange={(e) => handleChange(e, name, e.target.value)} onKeyDown={onKeyDown} />}
                {TrailingIcon && <TrailingIcon height="22" width="22" {...trailingIconProps} />}
            </div>
            {error && <p className={styles.isInvalid}>{error}</p>}
        </div>
    );
}

Input.defaultProps = {
    type: 'text',
    name: 'input',
    label: null,
    placeholder: '',
    leadingIcon: null,
    leadingIconProps: {},
    trailingIcon: null,
    trailingIconProps: {},
    value: null,
    error: null,
    max: null,
    min: null,
    onChange: (name, value) => console.log('Input:onChange(name, value)', name, value),
    onFileChange: (name, files) => console.log('Input:onFileChange(name, files)', name, files),
    onKeyDown: null,
}

Input.propTypes = {
    type: PropTypes.string,
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    leadingIcon: PropTypes.elementType,
    leadingIconProps: PropTypes.object,
    trailingIcon: PropTypes.elementType,
    trailingIconProps: PropTypes.object,
    value: PropTypes.string,
    error: PropTypes.string,
    max: PropTypes.string,
    min: PropTypes.string,
    onChange: PropTypes.func,
    onKeyDown: PropTypes.func,
}

// stylies
const pinInputStype = {
    border: "solid 1px #A2B1C3",
    borderRadius: "8px",
    height: "81px",
    width: "60px",
};

export default Input;