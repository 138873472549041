import React from 'react'
import { createRandomUser } from '../../../../api/faker/factories';
import InputMoney from '../../form/money';
import { InputPaymentMethod } from '../../form/paymentmethod';
import UserSummaryComponent from '../../views/user-summary';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_SEND_MONEY_MOBILE_INFO } from '../../layout/modal';
import { MODAL_SEND_MONEY_MOBILE } from '../../layout/modal/index';

const user = createRandomUser();

export const SendMoneyMobileModal = () => {
  // redux
  const dispatch = useDispatch();

  // handlers
  const handleOnBackClicked = () => {
    dispatch(showModal(MODAL_SEND_MONEY_MOBILE))
  }

  const handleSend = () => {
    dispatch(showModal(MODAL_SEND_MONEY_MOBILE_INFO, {onBackClicked: handleOnBackClicked}));
  }
  return (
    <div>
        <div className="mt-3"></div>
        <UserSummaryComponent user={user} />

        <div className="mt-5">
            <InputMoney />
            <div className="mt-4"></div>
            <InputPaymentMethod />
        </div>

        <div className="d-grid mt-3">
            <button className="btn btn-primary btn-lg" onClick={handleSend}>Send</button>
        </div>
    </div>
  )
}
