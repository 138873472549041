import React from 'react'
import { AppLayout } from '../../components/layout/app'
import { useDispatch } from 'react-redux';
import { hideModal, showModal } from '../../../application/features/modal';
import { CUSTOMER_FORM_MODAL } from '../../components/layout/modal/index';
import Input from '../../components/form/input';
import InsightCardComponent from '../dashboard/insight';
import { RecentTransactionsWidget } from '../../components/widgets/recent-transactions';
import { MonthlyRevenueWidget } from '../../components/widgets/monthly-revenue';
import { IconTransactions } from '../../components/icons/transactions';

export const ComponentsPage = () => {
    // store
    const dispatch = useDispatch();

    //handlers
    const handleShowModal = () => {
        dispatch(showModal(CUSTOMER_FORM_MODAL));
    }

    const handleHideModal = () => {
        dispatch(hideModal());
    }

    return (
        <AppLayout>
            <div className="container">
                <div className="row">
                    <div className="col-lg-4">
                        <button className="btn btn-primary btn-lg" onClick={handleShowModal}>Show Modal</button>
                    </div>

                    <div className="col-lg-4">
                        <Input label="Input Component" placeholder="Sample input component"  leadingIcon={IconTransactions} />
                    </div>

                    <div className="col-lg-4">
                        <InsightCardComponent />
                    </div>
                </div>

                <div className="row">
                    <div className="col-lg-4">
                        <MonthlyRevenueWidget />
                    </div>
                    <div className="col-lg-4">
                        <RecentTransactionsWidget />
                    </div>
                </div>
            </div>
        </AppLayout>
    );
}
