import React from 'react'
import { getBank } from '../../../../api/faker/factories';
import BankSummaryComponent from '../../views/bank-summary/index';
import InputMoney from '../../form/money/index';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_SEND_MONEY_BANK_INFO } from '../../layout/modal';

const bank = getBank();

export const SendMoneyBankFormModal = (props) => {
    // redux
    const dispatch = useDispatch();

    // handlers
    const handleSend = () => {
        dispatch(showModal(MODAL_SEND_MONEY_BANK_INFO));
    }
  return (
    <div>
        <div className="mt-3"></div>
        <BankSummaryComponent bank={bank} />

        <div className="mt-5">
            <InputMoney />
        </div>

        <div className="d-grid mt-3">
            <button className="btn btn-primary btn-lg" onClick={handleSend}>Send</button>
        </div>
    </div>
  )
}
