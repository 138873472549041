import React from 'react'
import logo from '../../../../assets/images/logo.png';
import { IconMessage } from '../../icons/message';
import * as styles from './index.module.scss';
import SideMenuItem from './item/index';
import { IconGroup } from '../../icons/group';
import { IconWallet } from '../../icons/wallet';
import { IconMoneySend } from '../../icons/money-send';
import { IconTransactions } from '../../icons/transactions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

export const SideMenu = () => {
  const navigate = useNavigate();
  
  return (
    <div className={styles.wrapper}>
        <img className={styles.logo} src={logo} alt="" onClick={() => navigate('/')} />
        <div >
            <SideMenuItem title="Home" href="/dashboard" />
            <SideMenuItem title="Transactions" icon={IconTransactions} href="/transactions" />
            <SideMenuItem title="Withdraw" icon={IconWallet} href="/withdrawals" />
            <SideMenuItem title="Send" icon={IconMoneySend} href="/send" />
            <SideMenuItem title="Customer" icon={IconGroup} href="/customers" />
            <SideMenuItem title="Messages" icon={IconMessage} href="/message" />
            {/* <SideMenuItem title="Components" icon={IconTransactions} href="/components" /> */}
        </div>
    </div>
  )
}
