import React from 'react'

export const IconDelete = (props) => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57" fill="currentColor" {...props}>
        <g transform="scale(1.00541)">
            <g>
                <path d="M20.535 45.52c.69 0 1.24-.56 1.24-1.25V22.31c0-.69-.56-1.25-1.25-1.25s-1.25.55-1.25 1.24v21.95c0 .69.55 1.24 1.24 1.24Z"></path>
                <path d="M28.293 45.52c.68 0 1.24-.56 1.24-1.25V22.31c0-.69-.57-1.25-1.25-1.25 -.69 0-1.25.55-1.25 1.24v21.95c0 .69.55 1.24 1.24 1.24Z"></path>
                <path d="M36.05 45.52c.68 0 1.24-.56 1.24-1.25V22.31c0-.69-.56-1.25-1.25-1.25s-1.25.55-1.25 1.24v21.95c0 .69.55 1.24 1.24 1.24Z"></path>
                <path d="M46.81 10.837h-8.982v-2.67c0-2.034-1.66-3.69-3.69-3.69H22.42c-2.035 0-3.69 1.65-3.69 3.68v2.66H9.74c-1.38 0-2.5 1.11-2.5 2.49 0 1.37 1.11 2.49 2.49 2.49h1.56v36.33c0 1.14.93 2.07 2.07 2.07H43.13c1.14 0 2.07-.93 2.07-2.08V15.77h1.564c1.37-.001 2.49-1.12 2.49-2.5s-1.12-2.5-2.5-2.5Zm-24.74-2.67c0-.21.16-.37.36-.37h11.703c.2 0 .36.16.36.36v2.66H22.05v-2.67Zm19.01 41.905H15.47V15.81h25.6v34.257Z"></path>
            </g>
        </g>
    </svg>
  )
}
