
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import MlipaApi from '../../api/mlipa/endpoints';
import { customFetch } from '../../core/request';

// ACTION CONSTANTS
const WITHDRAW_GET_SETTLEMENT_TYPES = `withdraw/GET_SETTLEMENT_TYPES`;
const WITHDRAW_CREATE_SETTLEMENT = `withdraw/CREATE_SETTLEMENT`;
const WITHDRAW_GET_SETTLEMENTS = `withdraw/GET_SETTLEMENTS`;
const WITHDRAW_CALCULATE_CHARGE = `withdraw/CALCULATE_CHARGE`;

// ACTIONS
export const withdrawGetSettlementTypes = createAsyncThunk(
    WITHDRAW_GET_SETTLEMENT_TYPES,
    async (data, store) => {
        const { token } = store.getState().authentication;
        return customFetch('GET', MlipaApi.settlementTypes, null, token, store);
    }
);

export const withdrawCreateSettlement = createAsyncThunk(
    WITHDRAW_CREATE_SETTLEMENT,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.settlementCreate, data.payload, token, store);
    }
);

export const withdrawGetSettlements = createAsyncThunk(
    WITHDRAW_GET_SETTLEMENTS,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.settlements, data.payload, token, store);
    }
);

export const withdrawCalculateCharge = createAsyncThunk(
    WITHDRAW_CALCULATE_CHARGE,
    async (data = {}, store) => {
        const { token } = store.getState().authentication;
        return customFetch('POST', MlipaApi.settlementCalculateCharge, data.payload, token, store);
    }
)

const initialState = {
    settlements: [],
    types: [],
    settlement: {},
    create: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    get: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    getTypes: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
    calculateCharge: {
        isLoading: false,
        isSuccessfull: false,
        error: null,
        data: {},
    },
};

const withdrawSlice = createSlice({
    name: 'withdraw',
    initialState,
    reducers: {
        setSettlement(state, action) {
            state.settlement = action.payload;
        }
    },
    extraReducers: {
        [withdrawGetSettlementTypes.pending]: (state, action) => {
            state.getTypes.isLoading = true;
            state.getTypes.isSuccessfull = false;
            state.getTypes.error = null;
            state.getTypes.data = {};
        },
        [withdrawGetSettlementTypes.fulfilled]: (state, action) => {
            state.getTypes.isLoading = false;
            state.getTypes.isSuccessfull = true;
            state.getTypes.error = null;
            state.getTypes.data = action.payload;
            state.types = action.payload.data;
        },
        [withdrawGetSettlementTypes.rejected]: (state, action) => {
            state.getTypes.isLoading = false;
            state.getTypes.isSuccessfull = false;
            state.getTypes.error = action.payload;
            state.getTypes.data = {};
        },
        [withdrawCreateSettlement.pending]: (state, action) => {
            state.create.isLoading = true;
            state.create.isSuccessfull = false;
            state.create.error = null;
            state.create.data = {};
        },
        [withdrawCreateSettlement.fulfilled]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessfull = true;
            state.create.error = null;
            state.create.data = action.payload;
        },
        [withdrawCreateSettlement.rejected]: (state, action) => {
            state.create.isLoading = false;
            state.create.isSuccessfull = false;
            state.create.error = action.payload;
            state.create.data = {};
        },
        [withdrawGetSettlements.pending]: (state, action) => {
            state.get.isLoading = true;
            state.get.isSuccessfull = false;
            state.get.error = null;
            // state.get.data = {}
        },
        [withdrawGetSettlements.fulfilled]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessfull = true;
            // state.get.error = null;
            state.get.data = action.payload;
            state.settlements = action.payload.data;
        },
        [withdrawGetSettlements.rejected]: (state, action) => {
            state.get.isLoading = false;
            state.get.isSuccessfull = false;
            state.get.error = action.payload;
            // state.get.data = {}
        },
        [withdrawCalculateCharge.pending]: (state, action) => {
            state.calculateCharge.isLoading = true;
            state.calculateCharge.isSuccessfull = false;
            state.calculateCharge.error = null;
            // state.calculateCharge.data = {}
        },
        [withdrawCalculateCharge.fulfilled]: (state, action) => {
            state.calculateCharge.isLoading = false;
            state.calculateCharge.isSuccessfull = true;
            // state.calculateCharge.error = null;
            state.calculateCharge.data = action.payload;
        },
        [withdrawCalculateCharge.rejected]: (state, action) => {
            state.calculateCharge.isLoading = false;
            state.calculateCharge.isSuccessfull = false;
            state.calculateCharge.error = action.payload;
            // state.calculateCharge.data = action.payload;
        }
    }
});

export const { setSettlement } = withdrawSlice.actions;
export default withdrawSlice.reducer;