import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hideModal } from '../../../../application/features/modal';
import AlertModal from '../../modals/alert/index';
import * as styles from './index.module.scss';
import CustomerFormModal from '../../modals/customerForm/index';
import CustomerProfileModal from '../../modals/customerProfile/index';
import { SendMoneyMobileModal } from '../../modals/sendMoneyMobileForm/index';
import { SendMoneyMobileInfoModal } from '../../modals/sendMoneyMobileInfo';
import { IconBackArrow } from '../../icons/back-arrow';
import { ConfirmPasswordModal } from '../../modals/confirmPassword';
import { ReceiptModal } from '../../modals/receipt/index';
import { SendMoneyBankFormModal } from '../../modals/sendMoneyBankForm/index';
import { SendMoneyBankInfoModal } from '../../modals/sendMoneyBankInfo/index';
import { ReceivePaymentModal } from '../../modals/receivePayment/index';
import { SendMoneyBatchBulkUploadModal } from '../../modals/sendMoneyBatchBulkUpload/index';
import { DocumentForm } from '../../modals/documentForm';
import { BankFormModal } from '../../modals/bankForm/index';
import UserFormModal from '../../modals/userForm/index';

export const CUSTOMER_FORM_MODAL = 'CUSTOMER_FORM';

export const MODAL_ALERT = 'ALERT_MODAL';
export const MODAL_CUSTOMER_ADD = CUSTOMER_FORM_MODAL;
export const MODAL_CUSTOMER_PROFILE = 'MODAL_CUSTOMER_PROFILE'
export const MODAL_SEND_MONEY_MOBILE = 'MODAL_SEND_MONEY_MOBILE';
export const MODAL_SEND_MONEY_MOBILE_INFO = 'MODAL_SEND_MONEY_MOBILE_INFO';
export const MODAL_CONFIRM_PASSWORD = 'MODAL_CONFIRM_PASSWORD';
export const MODAL_RECEIPT = 'MODAL_RECEIPT';
export const MODAL_SEND_MONEY_BANK = 'MODAL_SEND_MONEY_BANK';
export const MODAL_SEND_MONEY_BANK_INFO = 'MODAL_SEND_MONEY_BANK_INFO';
export const MODAL_RECEIVE_PAYMENT = 'MODAL_RECEIVE_PAYMENT';
export const MODAL_SEND_MONEY_UPLOAD_EXCEL = 'MODAL_SEND_MONEY_UPLOAD_EXCEL';
export const MODAL_DOCUMENT_FORM = 'MODAL_DOCUMENT_FORM';
export const MODAL_BANK_FORM = 'MODAL_BANK_MODAL';
export const MODAL_USER_FORM = 'MODAL_USER_FORM';

export const MODAL_COMPONENTS = {
    'ALERT_MODAL': AlertModal,
    [CUSTOMER_FORM_MODAL]: CustomerFormModal,
    [MODAL_CUSTOMER_PROFILE]: CustomerProfileModal,
    [MODAL_SEND_MONEY_MOBILE]: SendMoneyMobileModal,
    [MODAL_SEND_MONEY_MOBILE_INFO]: SendMoneyMobileInfoModal,
    [MODAL_CONFIRM_PASSWORD]: ConfirmPasswordModal,
    [MODAL_RECEIPT]: ReceiptModal,
    [MODAL_SEND_MONEY_BANK]: SendMoneyBankFormModal,
    [MODAL_SEND_MONEY_BANK_INFO]: SendMoneyBankInfoModal,
    [MODAL_RECEIVE_PAYMENT]: ReceivePaymentModal,
    [MODAL_SEND_MONEY_UPLOAD_EXCEL]: SendMoneyBatchBulkUploadModal,
    [MODAL_DOCUMENT_FORM]: DocumentForm,
    [MODAL_BANK_FORM]: BankFormModal,
    [MODAL_USER_FORM]: UserFormModal,
}

export const RootModal = () => {
    // store
    const dispatch = useDispatch();
    const modalType = useSelector(state => state.modal.type);
    const modalProps = useSelector(state => state.modal.modalProps);

    // effects
    useEffect(() => {
        // do some actins here
    }, [modalType]);

    // handlers
    const closeModal = () => {
        dispatch(hideModal());
    }

    if (!modalType) {
        return null;
    }

    const ModalContent = MODAL_COMPONENTS[modalType];

    return (
        <div className={styles.modal}>
            <div className={`${styles.dialog} ${modalProps.large ? styles.large : null}`}>
                <div className={styles.header}>
                    {modalProps.onBackClicked && (
                        <button className={styles.backBtn} onClick={modalProps.onBackClicked}>
                            <IconBackArrow height="24px" width="24px" />
                        </button>
                    )}
                    <button className={styles.closeBtn} onClick={closeModal}>
                        <span>X</span>
                    </button>
                </div>
                <ModalContent 
                    {...modalProps}
                    closeModal={closeModal}
                />
            </div>
        </div>
    );
}
