.card {
    display: flex;
    border: solid 1px #E0E5EB;
    border-radius: 20px;
    align-items: center;

    .icon {
        height: 60px;
        width: 60px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #F5F5F5;
        border-radius: 15px;
        margin: 10px;
    }

    .details {
        padding: 10px;
        flex: 1;

        h6 {
            all: unset;
            font-size: 16px;
            font-weight: 500;
            color: #303030;
            display: block;
        }

        p {
            all: unset;
            font-size: 14px;
            font-weight: 400;
            color: #636778;
            display: block;
        }
    }

    .actions {
        margin-right: 16px;
    }
}