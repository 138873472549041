import React, { useEffect, useState } from 'react';
import * as styles from './index.module.scss';
import { PropTypes } from 'prop-types';

const Tabs = (props) => {
    // props
    const { initialValue, tabs, justifyContent, onTabSelected } = props;

    // state
    const [active, setActive] = useState(initialValue);

    // effects
    useEffect(() => {
        if (onTabSelected) {
            onTabSelected(active);
        }
    }, []);

    // handlers
    const setActiveTab = (tab) => {
        setActive(tab);
        if (onTabSelected) {
            onTabSelected(tab);
        }
    }

    return (
        <div style={{ justifyContent: justifyContent }} className={styles.tabs}>
            {tabs.map(obj => <div onClick={() => setActiveTab(obj.value)} className={active == obj.value ? styles.active : null}>{obj.label}</div>)}
        </div>
    );
}

Tabs.defaultProps = {
    initialValue: 'qr',
    tabs: [
        {label: 'QR Code', value: 'qr'},
        {label: 'Phone', value: 'phone'},
        // {label: 'Card', value: 'card'}
    ],
    justifyContent: 'space-between',
    onTabSelected: (tab) => console.log("Tab changed ", tab),
}

Tabs.propTypes = {
    tabs: PropTypes.array,
    onTabSelected: PropTypes.func,
}

export default Tabs;