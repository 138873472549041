import React from 'react'

export const IconMoneySend = (props) => {
  return (
    <svg viewBox="0 0 22 22" fill="none" stroke="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
        <path d="M8.70898 12.6029C8.70898 13.4921 9.3965 14.2071 10.2398 14.2071H11.9631C12.6965 14.2071 13.2923 13.5837 13.2923 12.8046C13.2923 11.9704 12.9257 11.6679 12.3848 11.4754L9.62565 10.5129C9.08482 10.3204 8.71816 10.0271 8.71816 9.18375C8.71816 8.41375 9.31398 7.78125 10.0473 7.78125H11.7706C12.614 7.78125 13.3015 8.49625 13.3015 9.38542" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M11 6.875V15.125" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.1673 10.9987C20.1673 16.0587 16.0607 20.1654 11.0007 20.1654C5.94065 20.1654 1.83398 16.0587 1.83398 10.9987C1.83398 5.9387 5.94065 1.83203 11.0007 1.83203" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M20.1667 5.4987V1.83203H16.5" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
        <path d="M15.584 6.41536L20.1673 1.83203" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>    
  )
}
