import React, { useEffect, useState, useRef } from 'react'
import Input from '../../components/form/input';
import { AppLayout } from '../../components/layout/app'
import * as styles from './index.module.scss';
import { IconSearch } from '../../components/icons/search';
import Colors from '../../../assets/contants/colors';
import { IconAddUser } from '../../components/icons/add-user';
import { createRandomUser } from '../../../api/faker/factories';
import { useDispatch, useSelector } from 'react-redux';
import { showModal } from '../../../application/features/modal';
import { MODAL_CUSTOMER_ADD } from '../../components/layout/modal';
import { MODAL_CUSTOMER_PROFILE } from '../../components/layout/modal/index';
import { customerGetCustomers, selectorCustomerCustomers } from '../../../application/features/customer';
import { faker } from '@faker-js/faker';
import { getRandomBackgroundColor } from '../../../core/helpers';
import ListPaginationComponent from '../../components/list/pagination';
import ListHeaderComponent from '../../components/list/header';
import { useDownloadExcel } from 'react-export-table-to-excel';
import { IconExcel } from '../../components/icons/excel';
import { IconPdf } from '../../components/icons/pdf';
import ExportExcelButton from '../../components/widgets/export/excel';
import ExportPdfButton from '../../components/widgets/export/pdf';
import MlipaApi from '../../../api/mlipa/endpoints';

export const CustomerPage = () => {
  // state
  // const [customers, setCustomers] = useState([]);

  // ref
  const tableRef = useRef();

  // redux
  const dispatch = useDispatch();
  const customers = useSelector(state => selectorCustomerCustomers(state));
  const customerGet = useSelector(state => state.customer.get);
  const customerGetResponse = useSelector(state => state.customer.get.data);

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: 'mlipa-customers',
    sheet: 'Customers'
});

  // effects
  useEffect(() => {
    dispatch(customerGetCustomers());
  }, []);

  // handlers
  const handleAddCustomer = () => {
    dispatch(showModal(MODAL_CUSTOMER_ADD));
  }

  const handleViewCustomer = (customer) => {
    dispatch(showModal(MODAL_CUSTOMER_PROFILE,  {customer: customer}));
  }

  const handlePagination = (page) => {
    const data = {
      payload: {
        page: page,
      }
    }

    dispatch(customerGetCustomers(data));
  }

  const handleSearch = (keyword) => {
    if (keyword) {
      dispatch(customerGetCustomers({payload: {'search': keyword}}));
      return;
    }
    dispatch(customerGetCustomers());
  }

  const exportPDF = () => {
    const unit = "pt";
    const size = "A4"; // Use A1, A2, A3 or A4
    const orientation = "portrait"; // portrait or landscape

    const marginLeft = 40;
    const doc = new jsPDf(orientation, unit, size);

    doc.setFontSize(15);

    const title = "My Awesome Report";
    const headers = [["NAME", "PROFESSION"]];

    const data = this.state.people.map(elt=> [elt.name, elt.profession]);

    let content = {
      startY: 50,
      head: headers,
      body: data
    };

    doc.text(title, marginLeft, 40);
    doc.autoTable(content);
    doc.save("report.pdf")
  }

  return (
    <AppLayout>
        <div className={`container ${styles.customer}`}>
          <ListHeaderComponent title="Customers" actionOnClick={handleAddCustomer} actionIcon={IconAddUser} actionTitle="Add Customer" isLoading={customerGet.isLoading} searchOnChange={handleSearch}  />

          <div className="row mt-4">
            <div className="col-lg-12">
              <div className="card mb-3">
                <div className="card-body">
                <div className="d-flex">
                                <div className="flex-grow-1"></div>
                                <ExportExcelButton endpoint={MlipaApi.exportSettlements} />
                                <div className="px-1"></div>
                                <ExportPdfButton endpoint={MlipaApi.exportSettlements} />
                  </div>
                <table className="table" ref={tableRef}>
                <thead>
                  <tr>
                    <th>Name</th>
                    <th>Id</th>
                    <th>Number</th>
                    <th>Email</th>
                  </tr>
                </thead>

                <tbody>
                  {customers.map(cust => {
                    const rand = getRandomBackgroundColor();
                    return (
                      <tr key={cust.userId} onClick={() => handleViewCustomer(cust)}>
                        <td>
                          <div className="d-flex align-items-center">
                            <div className={styles.avatar} style={{ backgroundColor: rand.background, color: rand.text }} alt="">{cust.first_name ? cust.first_name.slice(0,1) : ''}{cust.last_name ? cust.last_name.slice(0,1) : ''}</div>
                            <div className="px-1"></div>
                            <span>{cust.first_name} {cust.last_name}</span>
                          </div>
                        </td>
                        <td>{cust.registration_number}</td>
                        <td>{cust.phone_no}</td>
                        <td>{cust.email ?? 'NIL'}</td>
                      </tr>
                    )
                  })}
                </tbody>
              </table>
                </div>
              </div>

              <ListPaginationComponent currentPage={customerGetResponse.current_page} lastPage={customerGetResponse.last_page} onClick={handlePagination} />
            </div>
          </div>
        </div>
    </AppLayout>
  )
}
