@import '$assets/scss/main.scss';

.select {
    label {
        font-weight: 500;
        font-size: 16px;
        color: #303030;
        margin-bottom: 0px;
    }

    select {
        all: unset;

        &::placeholder {
            font-size: 16px;
            font-weight: 500;
            color: #8B8E97;
        }

        &:focus {
           all: unset;
        }

        z-index: 2;
    }

    .group {
        position: relative;
        display: flex;
        border: solid 1px #CCD0E1;
        border-radius: 10px;
        padding: 12px;
        gap: 8px;

        &.isInvalid {
            border-color: $danger;
        }

        .icon {
            position: absolute;
            z-index: 1;
            right: 10px;
        }
    }

    p.isInvalid {
        margin-top: 10px;
        color: $danger;
    }
}