import React, { useState } from 'react'
import { PropTypes } from 'prop-types';
import * as styles from './index.module.scss';

const InputMoney = (props) => {
    const [currency, setCurrency] = useState("TZS ");

    const handleCurrencyChange = (e) => {
        setCurrency(e.target.value);
    }
    return (
        <div className={styles.inputMoney}>
            <div className={styles.group}>
                <label htmlFor="">Amount</label>
                <select onChange={handleCurrencyChange}>
                    <option value="TZS&nbsp;">TZS</option>
                    <option value="$">USD</option>
                </select>
            </div>
            <div className={styles.input}>
                <span>{currency}</span><input type="text" />
            </div>
            
        </div>
    );
}

InputMoney.defaultProps = {
    name: 'amount',
    label: "Amount",
    placeholder: '',
    leadingIcon: null,
    leadingIconProps: {},
    value: null,
    error: null,
    onChange: (name, value) => console.log('InputMoney:onChange(name, value)', name, value),
}

InputMoney.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    leadingIcon: PropTypes.elementType,
    leadingIconProps: PropTypes.object,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func
}

export default InputMoney;