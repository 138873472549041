.customer {
    .header {
        padding-bottom: 24px;
        border-bottom: solid 1px #C1CBD7;
    }

    tr {
        cursor: pointer;
    }

    .avatar {
        height: 45px;
        width: 45px;
        border-radius: 15px;

        display: flex;
        justify-content: center;
        align-items: center;
    }
}