import React, { useRef } from 'react'
import * as styles from './index.module.scss';
import { PropTypes } from 'prop-types';
import { IconChevronDown } from '../../icons/chevron-down';

const Select = (props) => {
    // props
    const { label, name, onChange, children, error } = props;

    return (
        <div className={`form-group ${styles.select}`}>
            {label && <label className='label-control'>{label}</label>}
                <div className={`${styles.group}  ${error ? styles.isInvalid : ''}`}>
                    {/* {LeadingIcon && <LeadingIcon height="22" width="22" {...leadingIconProps} />} */}
                    <select className={`form-control flex-grow-1`} onChange={(e) => onChange(name, e.target.value)}>
                        {children}
                    </select>
                    <div className={styles.icon}>
                        <IconChevronDown height="22" width="22" />
                    </div>
                    {/* {TrailingIcon && <TrailingIcon height="22" width="22" {...trailingIconProps} />} */}
                </div>
                {error && <p className={styles.isInvalid}>{error}</p>}
        </div>
    )
}

Select.defaultProps = {
    name: 'input',
    label: null,
    placeholder: '',
    leadingIcon: null,
    leadingIconProps: {},
    trailingIcon: null,
    trailingIconProps: {},
    value: null,
    error: null,
    onChange: (name, value) => console.log('Input:onChange(name, value)', name, value),
}

Select.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    leadingIcon: PropTypes.elementType,
    leadingIconProps: PropTypes.object,
    trailingIcon: PropTypes.elementType,
    trailingIconProps: PropTypes.object,
    value: PropTypes.string,
    error: PropTypes.string,
    onChange: PropTypes.func
}

export default Select;