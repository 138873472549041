import React from 'react'
import { AppLayout } from '../../../components/layout/app/index';
import ListHeaderComponent from '../../../components/list/header';
import { getBanks } from '../../../../api/faker/factories';
import { IconMoreVertical } from '../../../components/icons/more-vertical';
import * as styles from './index.module.scss';
import { useDispatch } from 'react-redux';
import { showModal } from '../../../../application/features/modal';
import { MODAL_SEND_MONEY_BANK } from '../../../components/layout/modal';

const banks = getBanks();

export const SendMoneyBankPage = () => {
  // redux
  const dispatch = useDispatch();

  // handlers
  const handleSelectBank = () => {
    dispatch(showModal(MODAL_SEND_MONEY_BANK));
  }
  
  return (
    <AppLayout>
        <div className="container">
          <ListHeaderComponent title="Send Money Bank" searchPlaceholder="Search by Bank Name." />

          <div className="row mt-5">
                <div className="col-lg-10 mx-auto">
                    <table className="table">
                        <thead>
                            <tr>
                                <th width="300px">Bank Name</th>
                                <th>Account Number</th>
                                <th width="10"></th>
                            </tr>
                        </thead>

                        <tbody>
                            {banks.map(bank => {
                                return (
                                    <tr>
                                        <td className='px-4 d-flex align-items-center'>
                                          <div className={styles.logo}>
                                            <img src={bank.logo} alt="" />
                                          </div> &nbsp; {bank.name}
                                        </td>
                                        <td style={{ verticalAlign: 'middle' }}>{bank.cardno}</td>
                                        <td style={{ verticalAlign: 'middle' }} onClick={handleSelectBank}>
                                            <IconMoreVertical height="26px" width="26px" />
                                        </td>
                                    </tr>
                                )
                            })}
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </AppLayout>
  )
}
