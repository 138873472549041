import React from 'react'
import { AppLayout } from '../../components/layout/app'
import art from '$assets/images/comming-soon.svg';

export const MessagesPage = () => {
  return (
    <AppLayout>
        <div className="d-flex flex-column justify-content-center align-items-center bg-light h-100">
            <img src={art} alt="" srcset="" height="200px" />
            <h3 className='mt-3'>Coming Soon</h3>
            <p className='text-black-50'>This feature is still under development</p>
        </div>
    </AppLayout>
  )
}
