.paymentmethods {
    position: relative;
    display: block;

    label {
        color: #303030;
        font-size: 16px;
        font-weight: 500;
    }

    .choose {
        display: flex;
        border: solid 1px #C1CBD7;
        border-radius: 10px;
        padding: 16px;
        color: #8397AF;

        span {
            flex: 1;
        }
    }

    .popup {
        position: absolute;
        width: 100%;
        // top: 102px;
        left: 0;
        background-color: #ffffff;
        border-radius: 15px;

        padding-top: 36px;
        padding-left: 16px;
        padding-right: 16px;
        padding-bottom: 24px;

        border: solid 1px #E0E5EB;

        overflow: scroll;

        transition: all 0.3s ease-in-out;

        

        .content {
            .selection {
                .header {
                    display: flex;

                    div {
                        flex: 1;
                    }
                }
            }
        }
    }
}