import React, { useEffect, useState } from 'react'
import Input from '../../form/input';
import { useDispatch, useSelector } from 'react-redux';
import { bankCreateBank } from '../../../../application/features/bank';
import { showModal } from '../../../../application/features/modal';
import { MODAL_ALERT } from '../../layout/modal';
import Tabs from '../receivePayment/tabs';

const tabs = [
    {label: 'Bank', value: 'bank'},
    {label: 'Mobile', value: 'mobile'}
]

export const BankFormModal = (props) => {
    // props
    const { bank } = props;

    // state
    const [mounted, setMounted] = useState(false);
    const [payload, setPayload] = useState({});
    const [errors, setErrors] = useState({});
    const [currentBank, setCurrentBank] = useState(bank);
    const [tab, setTab] = useState({label: 'Bank', value: 'bank'});
    const [errorMessage, setErrorMessage] = useState();


    // redux
    const dispatch = useDispatch();
    const createBank = useSelector(state => state.bank.create);

    // effects
    useEffect(() => setMounted(true), []);

    useEffect(() => {
        if (bank) {
            setPayload(bank);
            setCurrentBank(bank);
        }
    }, [bank]);

    useEffect(() => {
        if (!mounted) return;
        if (createBank.isSuccessfull) {
            dispatch(showModal(MODAL_ALERT, {title: 'Bank Added'}));
        }

        if (createBank.error) {
            if (createBank.error.errors) {
                Object.keys(createBank.error.errors).map(key => {
                    
                    setErrors(prevState => {
                        return {...prevState, [key]: createBank.error.errors[key][0]}
                    });
                    
                })
            }

            
            if (createBank.error.message) {
                // alert(createBank.error.message);
                if (createBank.error.data && createBank.error.data.bank_accounts) {
                    handleSetErrorMessage(createBank.error.data.bank_accounts)
                    return;
                }
                handleSetErrorMessage(createBank.error.message);
            }
        }
    }, [createBank])

    // handlers
    const handlechange = (name, value) => {
        setPayload({...payload, [name]: value});
        if (errors[name]) {
            setErrors({...errors, [name]: ''});
        }
    }

    const handleSave = () => {
        const data = {
            payload
        };
        dispatch(bankCreateBank(data));
    }

    const validate = () => {
        let pass = true;
        let _errors = {};
        
        // Required
        Object.keys(payload).map(key => {
            console.log(key);
            if (!payload[key]) {
                _errors[key] = `Required Field`;
                pass = false;
            }
        });

        setErrors(_errors);
        return pass;
    }

    const handleSetErrorMessage = (message) => {
        const errorMessageTimeout = 5000;
        setErrorMessage(message);
        setTimeout(() => {
            setErrorMessage("")
        }, errorMessageTimeout);
    }

    return (
        <div>
            <h3 className="text-center mt-4">{bank ? 'Update' : 'Add'} Withdrawal Account</h3>
            <Tabs initialValue='bank' tabs={tabs} justifyContent='end' onTabSelected={(tab) => setTab(tab)} />
            
            <div className="row mt-5">
            {errorMessage && <div className="alert alert-danger">{errorMessage}</div>}
                {tab == 'bank' && (
                    <div className="col-lg-6">
                        <Input label="Bank Name" name="bank_name" placeholder="Bank Name" value={payload.bank_name} error={errors.bank_name} onChange={handlechange} />
                    </div>
                )}

                {(tab == 'bank' || tab == 'mobile') && (
                    <div className="col-lg-6">
                        <Input label="Account Name" name="account_name" placeholder="Account Name" value={payload.account_name} error={errors.account_name} onChange={handlechange} />
                    </div>
                )}
                
                {tab == 'bank' && (
                    <div className="col-lg-12">
                        <Input label="Account Number" name="account_no" placeholder="Account Number" value={payload.account_no} error={errors.account_no} onChange={handlechange}   />
                    </div>
                )}   

                {tab == 'bank' && (
                    <div className="col-lg-6">
                        <Input label="Account Type" name="account_type"  placeholder="Account Type" value={payload.account_type} error={errors.account_type} onChange={handlechange}  />
                    </div>
                )}  

                {(tab =='bank' || tab == 'mobile') && (
                   <div className="col-lg-6">
                        <Input label="Phone" name="phone"  placeholder="Phone Number" value={payload.phone} error={errors.phone} onChange={handlechange}  />
                    </div>
                )}  

                {tab == 'bank' && (
                   <div className="col-lg-6">
                        <Input label="Swift Code" name="swift_code"  placeholder="Swift Code" value={payload.swift_code} error={errors.swift_code} onChange={handlechange}  />
                    </div>
                )}  

                {tab == 'bank' && (
                   <div className="col-lg-6">
                        <Input label="Branch Code" name="branch_code"  placeholder="Branch Code" value={payload.branch_code} error={errors.branch_code} onChange={handlechange}  />
                    </div>
                )}  
            </div>

            <div className="my-2"></div>

            {bank ? <button className="btn btn-primary">Update {createBank.isLoading && <span className='spinner-border spinner-border-sm'></span>}</button>  : <button className="btn btn-primary" onClick={handleSave}>Save {createBank.isLoading && <span className='spinner-border spinner-border-sm'></span>}</button>}
        </div>
    );
}
